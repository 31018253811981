import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { educationInfo } from "../../../../common/helper/getAuthFields";
import { validate } from "../../../../common/helper/validate";
import {
  getcountryCode,
  ScoreType,
  getcityCode, AmericanScoreType, SaudiScoreType, BritishScoreType,
} from "../../../../common/helper/selecteLists";
import MuiSelectField from "../../../../common/components/muiSelectField/MuiSelectField";
import { getAllLookUps } from "../../../state/actions";
import ScoreUnavailableConfirm from "../../../../app/applicant/components/uploadForm/steps/ScoreUnavailableConfirm";
import { MenuItem, Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import MuiAutoCompleteRegistration from "../../../../common/components/muiAutoCompleteRegistration/MuiAutoCompleteRegistration";

const EducationInfo = ({handleSubmit, submitRef, next, form, phoneValidate}) => {
  // const [certified, setCertified] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isArabicLang = i18n.language === "ar";
  const [applicantEductionState, setApplicantEducationState] = useState(+form.register.values?.applicantEducationState);
  const [applicantScoreType, setApplicantScoreType] = useState(+form.register.values?.scoreType);
  const [open, setOpen] = useState(false);
  const handleCancel = () => setOpen(false);

  const confirmScoreUnavailabiltiy = () => {
    setOpen(false);
    next();
  };
  // const [transfer, setTransfer] = useState(form.register.values?.previous === "yes");
  // const [tagsir, setTagsir] = useState(form.register.values?.tagsir === "yes");
  // let secondaryType = form.register?.values?.secondary_type === "ادبي";
  let code = getcountryCode(form.register?.values?.certificate_country, phoneValidate.countries)?.country_no;
  let cityCode = getcityCode(form.register?.values?.high_school_city, phoneValidate.cities)?.city_no;
  // let zone = getcityCode(form.register?.values?.high_school_city, phoneValidate.cities)?.zone_code;
  // let cityCode = getcityCode(form.register?.values?.high_school_city)?.city_code
  // if sat provided then qudurat & tahsili is not required

  const submit = (values) => {
    // if (applicantScoreType === 3 && !confirmScore) setOpen(true);
    // else if ((applicantScoreType === 3 && confirmScore) || applicantScoreType !== 3) next();
    next();
  };

  useEffect(() => {
    dispatch(getAllLookUps("countries"));
    dispatch(getAllLookUps("cities"));
    dispatch(getAllLookUps("zones"));
    dispatch(getAllLookUps("schools"));
  }, []);

  let secondary_region = form.register?.values['secondary_region'];

  const isFieldRequired = (name) => {
    if(['BRI', 'GF', 'AM'].includes(secondary_region)) {
      return name !== 'high_school_year';
    }
    else return true;
  }

  const onChangeSecondaryRegion = (val) => {
    setApplicantScoreType(undefined);
    // delete form.register?.values['scoreType'];
  }
  const renderFields = (arr) => {
    return arr.map((fieldData, index) => {
      return (
          <Grid item xs={10} sm={4} key={index}>
            {fieldData.type === "select" ? (
                <Field
                    name={fieldData.name}
                    type={fieldData.type}
                    label={t(`register.labels.${fieldData.name}`)}
                    required={isFieldRequired(fieldData.name)}
                    val={{ label: form.register?.values[fieldData.name] || "" }}
                    options={fieldData.options && fieldData.options}
                    component={fieldData.component}
                    onChange={(e, val) => fieldData.name === 'secondary_region' && onChangeSecondaryRegion(val)}
                >
                  {fieldData.items &&
                      fieldData.items.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {i18n.language === "en" ? item.label : item.arabic_label}
                          </MenuItem>
                      ))}
                </Field>
            ) : (
                <Field
                    name={fieldData.name}
                    type={fieldData.type}
                    label={t(`register.labels.${fieldData.name}`)}
                    required={isFieldRequired(fieldData.name)}
                    component={fieldData.component}
                    max={["tagseer_GPA"].includes(fieldData?.name) && 5}
                />
            )}
          </Grid>
      );
    });
  };

  return (
      <div>
        {form.register?.values?.degree === "UG" && (
            <form onSubmit={handleSubmit((values) => submit(values))}>
              <Grid
                  direction={isArabicLang ? "row-reverse" : "row"}
                  container
                  justifyContent="center"
                  spacing={2}
              >
                <Typography variant="subtitle1" style={{ display: "block", width: "100%", color: "red" }}>
                  {t("validation.sorry")}
                </Typography>

                {renderFields([educationInfo.secondary_region])}

                {/* School information */}
                <Grid item xs={10} sm={4}>
                  <Field
                      name={educationInfo.hs_country.name}
                      type={educationInfo.hs_country.type}
                      label={t(`register.labels.${educationInfo.hs_country.name}`)}
                      required
                      typename="countries"
                      eng_type="country_name_english"
                      arabic_type="country_name_arabic"
                      val={{label: form.register?.values[educationInfo.hs_country.name] || ""}}
                      options={phoneValidate.countries && phoneValidate.countries}
                      component={MuiAutoCompleteRegistration}
                  >
                    {phoneValidate?.countries?.map((item, index) => {
                      return (
                          <MenuItem key={index} value={item.country_no}>
                            {i18n.language === "en"
                                ? item.country_name_english
                                : (item.country_name_arabic ? item.country_name_arabic : item.country_name_english)}
                          </MenuItem>
                      );
                    })}
                  </Field>
                </Grid>

                <Grid item xs={10} sm={4}>
                  <Field
                      name={educationInfo.hs_city.name}
                      type={educationInfo.hs_city.type}
                      label={t(`register.labels.${educationInfo.hs_city.name}`)}
                      required
                      typename="cities"
                      eng_type="city_name_english"
                      arabic_type="city_name_arabic"
                      recall={(e) => dispatch(getAllLookUps(e))}
                      val={{label: form.register?.values[educationInfo.hs_city.name] || ""}}
                      options={
                          phoneValidate.cities &&
                          phoneValidate.cities?.filter((city) => city.country_no === code)
                      }
                      component={MuiAutoCompleteRegistration}
                  >
                    {phoneValidate?.cities?.map((item, index) => {
                      return (
                          <MenuItem key={index} value={item.city_no}>
                            {i18n.language === "en"
                                ? item.city_name_english
                                : (item.city_name_arabic ? item.city_name_arabic : item.city_name_english )}
                          </MenuItem>
                      );
                    })}
                  </Field>
                </Grid>

                {/* <Grid item xs={10} sm={4}>
            <Field
              name={educationInfo.hs_zone.name}
              type={educationInfo.hs_zone.type}
              label={t(`register.labels.${educationInfo.hs_zone.name}`)}
              required
              typename="zones"
              eng_type='zone_name_english'
              arabic_type = 'zone_name_arabic'
              recall= {(e) => {
                dispatch(getAllLookUps(e))
              }}
              val={{ label: form.register?.values[educationInfo.hs_zone.name] || "" }}
              options={
                phoneValidate.zones?.filter((zone) => zone.country_no === code && zone.city_no === code)
                // phoneValidate.zones && phoneValidate.zones && [
                //   ...phoneValidate.zones.filter((zone) => zone.code === code),
                //   // { code: code, arabic_label: "غير محدد" },
                // ]
              }
              component={MuiAutoCompleteRegistration}
            >
              {phoneValidate.zones?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.zone_no}>
                    {i18n.language === "en" ? (item.zone_name_english ) : (item.zone_name_arabic)}
                  </MenuItem>
                );
              })}
            </Field>
          </Grid> */}

                <Grid item xs={10} sm={4}>
                  <Field
                      name={educationInfo.school.name}
                      type={educationInfo.school.type}
                      label={t(`register.labels.${educationInfo.school.name}`)}
                      required
                      typename="schools"
                      eng_type="school_name_english"
                      arabic_type="school_name_arabic"
                      recall={(e) => dispatch(getAllLookUps(e))}
                      val={{label: form.register?.values[educationInfo.school.name] || ""}}
                      options={
                          phoneValidate.schools &&
                          phoneValidate.schools?.filter(
                              (school) =>
                                  school.country_no === code && school.city_no === cityCode
                          )
                      }
                      component={MuiAutoCompleteRegistration}
                  >
                    {phoneValidate?.schools?.map((item, index) => {
                      return (
                          <MenuItem key={index} value={item.school_no}>
                            {i18n.language === "en"
                                ? item.school_name_english
                                : (item.school_name_arabic ? item.school_name_arabic : item.school_name_english )}
                          </MenuItem>
                      );
                    })}
                  </Field>
                </Grid>

                {renderFields(educationInfo.text)}

                <Grid item xs={10} sm={4}>
                  <Field
                      onChange={(e, val) => setApplicantScoreType(val)}
                      name="scoreType"
                      type="number"
                      label="Please select a score type"
                      required
                      val={{ label: form.register?.values["scoreType"] || "" }}
                      options={(secondary_region === 'BRI' ? BritishScoreType : (secondary_region === 'SAU' ? SaudiScoreType : (secondary_region === 'AM' ? AmericanScoreType : ScoreType)))}
                      component={MuiSelectField}
                  >
                    {(secondary_region === 'BRI' ? BritishScoreType : (secondary_region === 'SAU' ? SaudiScoreType : (secondary_region === 'AM' ? AmericanScoreType : ScoreType)) || [])?.map((item, index) => {
                      return (
                          <MenuItem key={index} value={item.id}>
                            {t(`applicant.info.${item.name}`)}
                          </MenuItem>
                      );
                    })}
                  </Field>
                </Grid>

                <ScoreUnavailableConfirm open={open} cancel={handleCancel} confirm={confirmScoreUnavailabiltiy} form={form}/>

                {applicantScoreType === 3 &&
                    educationInfo.test.act_score.map((fieldData, index) => {
                      return (
                          <Grid item xs={10} sm={4} key={index}>
                            <Field
                                name={fieldData.name}
                                type={fieldData.type}
                                label={t(`register.labels.${fieldData.name}`)}
                                required
                                component={fieldData.component}
                            />
                          </Grid>
                      );
                    })
                }

                {applicantScoreType === 2 &&
                    educationInfo.test.sat.map((fieldData, index) => {
                      return (
                          <Grid item xs={10} sm={4} key={index}>
                            <Field
                                name={fieldData.name}
                                type={fieldData.type}
                                label={t(`register.labels.${fieldData.name}`)}
                                // required
                                component={fieldData.component}
                            />
                          </Grid>
                      );
                    })
                }

                {applicantScoreType === 1 &&
                    educationInfo.test.qt.map((fieldData, index) => {
                      return (
                          <Grid item xs={10} sm={4} key={index}>
                            <Field
                                name={fieldData.name}
                                type={fieldData.type}
                                label={t(`register.labels.${fieldData.name}`)}
                                // required
                                component={fieldData.component}
                            />
                          </Grid>
                      );
                    })}

                {/* English information */}
                {/* <Grid container justify="center">
              <Grid item xs={10} sm={4}>
              <Field
                  onChange={(e, val) => setCertified(val === "yes" && true)}
                  name={educationInfo.english.q.name}
                  type={educationInfo.english.q.type}
                  label={educationInfo.english.q.label}
                  required
                  component={educationInfo.english.q.component}
              >
                  {educationInfo.english.q.items.map((item, index) => {
                  return (
                      <MenuItem key={index} value={item.value}>
                      {item.label}
                      </MenuItem>
                  );
                  })}
              </Field>
              </Grid>
            </Grid>     */}

                {/* {certified && renderFields(educationInfo.english.a)} */}

                {/* Previous institute information */}

                <Grid container justify="center">
                  <Grid item xs={10} sm={4}>
                    <Field
                        onChange={(e, val) => setApplicantEducationState(val)}
                        name={educationInfo.applicantEducationState.name}
                        type={educationInfo.applicantEducationState.type}
                        label={t(
                            `register.labels.${educationInfo.applicantEducationState.name}`
                        )}
                        validate={educationInfo.applicantEducationState.validate}
                        required
                        component={educationInfo.applicantEducationState.component}
                        val={{label: form.register?.values?.applicantEducationState?.name || ""}}
                    >
                      {educationInfo.applicantEducationState.items.map(
                          (item, index) => {
                            return (
                                <MenuItem key={index} value={item.value}>
                                  {i18n.language === "en" ? item.label : item.arabic_label}
                                </MenuItem>
                            );
                          }
                      )}
                    </Field>
                  </Grid>
                </Grid>

                {/*  <Grid container justify="center">
            <Grid item xs={10} sm={4}>
              <Field
                // onChange={(e, val) => setTransfer(val === "yes" && true)}
                name={educationInfo.previous.q.name}
                type={educationInfo.previous.q.type}
                label={t(`register.labels.${educationInfo.previous.q.name}`)}
                validate={educationInfo.previous.q.validate}
                required
                component={educationInfo.previous.q.component}
              >
                {educationInfo.previous.q.items.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {i18n.language === "en" ? item.label : item.arabic_label}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
          </Grid> */}

                {+applicantEductionState === 2 && renderFields(educationInfo.previous.a)}

                {/* bridging program information */}
                {/* <Grid container justify="center">
            <Grid item xs={10} sm={4}>
              <Field
                onChange={(e, val) => setTagsir(val === "yes" && true)}
                name={educationInfo.tagsir.q.name}
                type={educationInfo.tagsir.q.type}
                label={t(`register.labels.${educationInfo.tagsir.q.name}`)}
                validate={educationInfo.tagsir.q.validate}
                required
                component={educationInfo.tagsir.q.component}
              >
                {educationInfo.tagsir.q.items.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {i18n.language === "en" ? item.label : item.arabic_label}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
          </Grid> */}

                {+applicantEductionState === 3 && renderFields(educationInfo.tagsir.a)}

                <button type="submit" ref={submitRef} style={{ display: "none" }}></button>
              </Grid>
            </form>
        )}
        {form.register?.values?.degree === "PG" && (
            <form onSubmit={handleSubmit((values) => submit(values))}>
              <Grid
                  direction={isArabicLang ? "row-reverse" : "row"}
                  container
                  justifyContent="center"
                  spacing={2}
              >
                {/* <Typography
                    variant="subtitle1"
                    style={{ display: "block", width: "100%", color: "red" }}
                  >
                    {t("validation.sorry")}
                  </Typography> */}

                {/* School information */}
                <Grid item xs={10} sm={4}>
                  <Field
                      name={educationInfo.hs_country.name}
                      type={educationInfo.hs_country.type}
                      label={t(`register.labels.${educationInfo.hs_country.name}`)}
                      required
                      typename="countries"
                      eng_type="country_name_english"
                      arabic_type="country_name_arabic"
                      val={{
                        label: form.register?.values[educationInfo.hs_country.name] || "",
                      }}
                      options={phoneValidate.countries && phoneValidate.countries}
                      component={MuiAutoCompleteRegistration}
                  >
                    {phoneValidate?.countries?.map((item, index) => {
                      return (
                          <MenuItem key={index} value={item.country_no}>
                            {i18n.language === "en"
                                ? item.country_name_english
                                : (item.country_name_arabic ? item.country_name_arabic : item.country_name_english)}
                          </MenuItem>
                      );
                    })}
                  </Field>
                </Grid>

                <Grid item xs={10} sm={4}>
                  <Field
                      name={educationInfo.hs_city.name}
                      type={educationInfo.hs_city.type}
                      label={t(`register.labels.${educationInfo.hs_city.name}`)}
                      required
                      typename="cities"
                      eng_type="city_name_english"
                      arabic_type="city_name_arabic"
                      recall={(e) => {
                        dispatch(getAllLookUps(e));
                      }}
                      val={{
                        label: form.register?.values[educationInfo.hs_city.name] || "",
                      }}
                      options={
                          phoneValidate.cities &&
                          phoneValidate.cities?.filter((city) => city.country_no === code)
                      }
                      component={MuiAutoCompleteRegistration}
                  >
                    {phoneValidate?.cities?.map((item, index) => {
                      return (
                          <MenuItem key={index} value={item.city_no}>
                            {i18n.language === "en"
                                ? item.city_name_english
                                : (item.city_name_arabic ? item.city_name_arabic : item.city_name_english )}
                          </MenuItem>
                      );
                    })}
                  </Field>
                </Grid>
                {renderFields(educationInfo.previous_masters.a)}
                <Grid item xs={24} sm={12}>
                  {(form.register?.values.max_gpa === '4' ? form.register?.values.previous_GPA <= 2 : form.register?.values.previous_GPA <= 3 ) && (
                      <>
                        {isArabicLang  ?
                            ((form.register.values.gender === 'F'  )
                                ?  <div style={{color:'red'}}> (عزيزتي المتقدمة نأمل الإطلاع على شروط قبول الماجستر -المعدل التراكمي ) </div> : <div style={{color:'red'}}>  (عزيزي المتقدم نأمل الإطلاع على شروط قبول الماجستر -المعدل التراكمي ) </div>)  :
                            <div style={{color:'red'}}>(Dear applicant, we hope to see the Master’s admission conditions - cumulative GPA)</div>}
                      </>
                  )}

                </Grid>
                <button
                    type="submit"
                    ref={submitRef}
                    style={{ display: "none" }}
                ></button>
              </Grid>
            </form>
        )}
      </div>

  );
};

// const mapStateToProps = ({ auth, form, phoneValidate }) => ({ auth, form , phoneValidate});
const mapStateToProps = ({ form, phoneValidate }) => ({ form, phoneValidate });

export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
  validate,
})(connect(mapStateToProps, { getAllLookUps })(EducationInfo));

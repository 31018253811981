export const genderItems = [
  { value: "M", arabic_label: "ذكر", label: "Male" },
  { value: "F", arabic_label: "انثى", label: "Female" },
];
export const healthConditionItems = [
  { value: "ok", arabic_label: "سليم", label: "Ok" },
  {
    value: "dis",
    arabic_label: "ذوي احتياجات خاصة",
    label: "With Special Needs",
  },
];
export const relation = [
  { value: "1", arabic_label: "اب / ام", label: "Parent" },
  { value: "2", arabic_label: "اخ / اخت", label: "Brother/Sister" },
  { value: "3", arabic_label: "زوج / زوجة", label: "Husband/Wife" },
  { value: "4", arabic_label: "عم / عمه", label: "Uncle/Aunt (Father)" },
  { value: "5", arabic_label: "خال/ خاله", label: "Uncle/Aunt (Mother)" },
  { value: "6", arabic_label: "والد بالاحتضان", label: "Step Father" },
];
export const gpaSystem = [
  { value: "4", label: "4", arabic_label: "4" },
  { value: "5", label: "5", arabic_label: "5" },
];
export const employeeApprover= [
  { value: "true", arabic_label: "نعم", label: "Yes" },
  { value: "false", arabic_label: "لا", label: "No" },
]
export const tagsirMajor = [
  { value: "3", label: "Nursing", arabic_label: "تمريض" },
  {
    value: "5",
    label: "Emergency Medical Services",
    arabic_label: "الخدمات الطبية الطارئة",
  },
  { value: "4", label: "Respiratory Care", arabic_label: "رعاية تنفسية" },
  { value: "17", label: "Anaesthesia Technique", arabic_label: "تقنية تخدير" },
];
export const stateUniversity = [
  { value: "GR", label: "Graduated", arabic_label: "متخرج" },
  { value: "RE", label: "Regular", arabic_label: "منتظم" },
  { value: "OU", label: "Outgoing", arabic_label: "منسحب" },
  { value: "AP", label: "Apologize", arabic_label: "معتذر" },
  { value: "PP", label: "Postponed", arabic_label: "مؤجل" },
  {
    value: "AS",
    label: "Academically separated",
    arabic_label: "مفصول اكاديمي",
  },
  {
    value: "DD",
    label: "Disciplinary disconnected",
    arabic_label: "مفصول تأديبي",
  },
  { value: "FR", label: "Folded registration", arabic_label: "مطوي قيده" },
];
export const schoolType = [
  { value: "علمي", label: "Scientific", arabic_label: "علمي" },
];
export const secondaryRegionTypes = [
  { value: "SAU", label: "Saudi", arabic_label: "سعودي" },
  { value: "BRI", label: "British", arabic_label: "بريطانية" },
  { value: "GF", label: "Gulf", arabic_label: "خليجية" },
  { value: "AM", label: "American", arabic_label: "امريكية" },
];
export const applicantEducationStateOptions = [
  { value: 1, label: "New Student", arabic_label: "طالب مستجد" },
  { value: 2, label: "Transfer Student", arabic_label: "طالب محول " },
  { value: 3, label: "tagser Student", arabic_label: "طالب تجسير " },
];

export const degree = [
  { value: "UG", label: "Bachelor's", arabic_label: "بكالوريوس" },
  { value: "PG", label: "Master's", arabic_label: "ماجستير" },
]
export const qualifications_degree = [
  { value: "UE", label: "Uneducated", arabic_label: "غير متعلم" },
  { value: "EL", label: "Elementary", arabic_label: "ابتدائي" },
  { value: "INT", label: "Intermediate", arabic_label: "متوسطة" },
  { value: "HS", label: "High school", arabic_label: "ثانوية عامة" },
  { value: "DP", label: "Diploma", arabic_label: "دبلوم" },
  { value: "UG", label: "Bachelors", arabic_label:"بكالوريوس" },
  { value: "PG", label: "Masters", arabic_label: "ماجستر" },
  { value: "DR", label: "Doctors", arabic_label: "دكتوراه" },
]
export const martialItems = [
  { value: 1, label: "Single", arabic_label: "أعزب" },
  { value: 2, label: "Married", arabic_label: "متزوج" },
  { value: 3, label: "Divorced", arabic_label: "مطلق" },
  { value: 4, label: "Widower", arabic_label: "أرمل" },
];
export const defaultItems = [
  { value: "default", label: "default", arabic_label: "غير مفعل" },
];
export const ConditionTypesItems = [
  { value: "visual", arabic_label: "اعاقة بصريه", label: "Visual impairment" },
  {
    value: "hearing",
    arabic_label: "اعاقة سمعية",
    label: "Hearing impairment",
  },
  { value: "body", arabic_label: "اعاقة حركية", label: "Physical disability" },
  {
    value: "mental",
    arabic_label: "اعاقة عقلية",
    label: "Intellectual disability",
  },
  {
    value: "learning",
    arabic_label: "صعوبات التعلم",
    label: "Learning disability",
  },
  {
    value: "speaking",
    arabic_label: "صعوبات النطق",
    label: "Speech impairment",
  },
  { value: "other", arabic_label: "اخري", label: "Other" },
];
export const jobConditionItems = [
  { value: "work", arabic_label: "اعمل", label: "Employed" },
  { value: "no_work", arabic_label: "لا اعمل", label: "Unemployed" },
];
export const jobSectionItems = [
  { value: "1", arabic_label: "قطاع حكومي", label: "Government sector" },
  { value: "2", arabic_label: "قطاع خاص", label: "Private sector" },
];
export const questionItems = [
  { value: "hobby", label: "هوايتك المفضلة" },
  { value: "book", label: "كتابك المفضل" },
  { value: "school", label: "اسم المدرسة الابتدائية" },
];
export const certified = [
  { value: "yes", arabic_label: "نعم", label: "Yes" },
  { value: "no", arabic_label: "لا", label: "No" },
];
export const spokenLanguage = [
  { value: "1", arabic_label: "نعم", label: "Yes" },
  { value: "0", arabic_label: "لا", label: "No" },
];
export const survey = [
  { value: "yes", arabic_label: "نعم", label: "Yes" },
  { value: "no", arabic_label: "لا", label: "No" },
];
export const englishTests = [
  { value: "I", arabic_label: "IELTS", label: "IELTS" },
  { value: "T", arabic_label: "TOEFL", label: "TOEFL" },
  { value: "OX", label: "Oxford Test", arabic_label: "Oxford Test" },
  { value: "S", label: "STEP", arabic_label: "STEP" },
];
export const universities = [
  { arabic_label: "جامعة المعرفة", label: "Almaarefa University" },
  { arabic_label: "كلية الأمير سلطان العسكرية للعلوم الصحية" },
  { arabic_label: "الكلية التقنية" },
  { arabic_label: "المعهد الوطني للتعليم الصحي المتخصص" },
  { arabic_label: "معهد العلوم الصحية" },
  { arabic_label: "جامعة امدرمان الإسلامية" },
  { arabic_label: "معهد العالمية للحاسب والتقنية" },
  { arabic_label: "جامعة كراتشي", label: "University Of Karachi" },
  { arabic_label: "المعهد الدولي للعلوم الصحية" },
  { arabic_label: "جامعة البلقاء التطبيقية -الأردن" },
  { arabic_label: "كلية الاتصالات والمعلومات" },
  { arabic_label: "جامعة الملك سعود بن عبدالعزيز للعلوم الصحية" },
  { arabic_label: "جامعة اليمامة الأهلية" },
  { arabic_label: "جامعة لوما ليندا" },
  { arabic_label: "بيبت", label: "Pibt" },
  { arabic_label: "جامعة حفر الباطن" },
  { arabic_label: "جامعة بيشة" },
  { arabic_label: "جامعة أبردين", label: "unuversity of aberdeen" },
  { arabic_label: "معهد الدراسات الصحية بالقوات المسلحة" },
  { arabic_label: "Gannon university" },
  { arabic_label: "الجامعة السعودية اللإلكترونية" },
  { arabic_label: "جامعة السلطان قابوس" },
  { arabic_label: "جامعة النيلين" },
  { arabic_label: "WEST CHESTER UNIVERSITY", label: "WEST CHESTER UNIVERSITY" },
  { arabic_label: "كلية العلوم الصحية المتوسطة للبنين - ابها" },
  { arabic_label: "جامعة والش", label: "Walsh University" },
  { arabic_label: "جامعة سرايفو", label: "University of Sarajevo" },
  { arabic_label: "جامعة تكساس الجنوبيه" },
  { arabic_label: "جامعة الفيصل الأهلية " },
  { arabic_label: "جامعة طيبة" },
  { arabic_label: "كلية الفارابي الأهلية" },
  { arabic_label: "الكليات العالمية الأهلية" },
  { arabic_label: "جامعة تبوك" },
  { arabic_label: "جامعة الأمير سلمان - الخرج" },
  { arabic_label: "جامعة الطائف" },
  { arabic_label: "جامعة نجران" },
  { arabic_label: "جامعة الحدود الشمالية" },
  { arabic_label: "جامعة الباحة" },
  { arabic_label: "جامعة المجمعة" },
  { arabic_label: "جامعة الدمام" },
  { arabic_label: "جامعة الملك فيصل - الأحساء" },
  { arabic_label: "جامعة الملك خالد" },
  { arabic_label: "أكاديمية الفيصل" },
  { arabic_label: "كليات البترجي" },
  { arabic_label: "كليات الغد الدولية" },
  { arabic_label: "كليات سليمان الحبيب" },
  { arabic_label: "كلية ابن سينا" },
  { arabic_label: "كلية عفت" },
  { arabic_label: "كليات الرياض لطب الأسنان والصيدلة" },
  { arabic_label: "كلية العناية - الرياض" },
  { arabic_label: "كليات سليمان الراجحي - القصيم" },
  { arabic_label: "جامعة أم القرى" },
  { arabic_label: "جامعة الإمام محمد بن سعود الإسلامية" },
  { arabic_label: "معهد الإدارة العامة بالرياض" },
  { arabic_label: "الأكاديمية الدولية للعلوم الصحية" },
  { arabic_label: "كلية المجتمع" },
  { arabic_label: "الأكاديمية التخصصية للتدريب الطبي" },
  { arabic_label: "معهد اشبيليا" },
  { arabic_label: "جامعة الأمير سلطان الأهلية" },
  { arabic_label: "المعهد الفني للتدريب الصحي" },
  { arabic_label: "جامعة الخليج العربي" },
  { arabic_label: "جامعة الجوف" },
  { arabic_label: "الجامعة الأمريكية - بيروت" },
  { arabic_label: "الكلية الصحية المتوسطة" },
  { arabic_label: "جامعة البحرين" },
  { arabic_label: "جامعة انديانا", label: "Indiana University" },
  { arabic_label: "جامعة شقراء" },
  { arabic_label: "جامعة دار العلوم" },
  { arabic_label: "كلية العلوم الصحية بحائل" },
  { arabic_label: "كلية العلوم الصحية" },
  { arabic_label: "الاكاديمية الوطنية للتخصصات الصحية" },
  { arabic_label: "كلية العلوم الصحية للبنين بالباحة" },
  { arabic_label: "كلية الجبيل الجامعية" },
  {
    arabic_label: "كلية يوك - بنسلفينيا",
    label: "York College of Pennsylvania",
  },
  { arabic_label: "أكاديمية ابن حيان للعلوم الصحية" },
  { arabic_label: "اس تي امبروس", label: "St Ambrose University" },
  { arabic_label: "Midwestern State University" },
  { arabic_label: "University of The Incarnate Word" },
  { arabic_label: "المعهد الصحي للبنات بالدرعية" },
  { arabic_label: "Southeast University" },
  { arabic_label: "مدينة الملك فهد الطبية" },
  { arabic_label: "مدينة الأمير سلطان الطبية العسكرية" },
  {
    arabic_label: "UNIVERSITY OF MOUNT UNION",
    label: "UNIVERSITY OF MOUNT UNION",
  },
  { arabic_label: "معهد هجر للعلوم الصحية" },
  { arabic_label: "جامعة الملك سعود" },
  { arabic_label: "جامعة الملك عبد العزيز" },
  { arabic_label: "جامعة الملك فهد للبترول والمعادن" },
  { arabic_label: "الجامعه الاردنيه" },
  { arabic_label: "جامعة القاهرة", label: "Cairo University" },
  { arabic_label: "الجامعة الأردنية للعلوم والتكنولوجيا" },
  { arabic_label: "أكاديمية العلوم الصحية والتكنولوجيا" },
  { arabic_label: "جامعة الأزهر", label: "Alazhar University" },
  { arabic_label: "جامعة الخرطوم" },
  { arabic_label: "جامعة عين شمس", label: "Ain Shams University" },
  { arabic_label: "جامعة بيتس بيرج" },
  { arabic_label: "جامعة سنسناتي" },
  { arabic_label: "جامعة قريفث" },
  { arabic_label: "جامعة كاليفورنيا الحكومية-لوس أنجلس" },
  { arabic_label: "جامعة لندن" },
  { arabic_label: "جامعة مون ماوث" },
  { arabic_label: "معهد الينويز التقني" },
  { arabic_label: "جامعة الأميرة نورة بنت عبد الرحمن" },
  { arabic_label: "جامعة الحواش الخاصة لصيدلة والتجميل" },
  { arabic_label: "معهد السباعي" },
  { arabic_label: "جامعة ثامبسون ريفرز", label: "Thomtpson Rivers University" },
  { arabic_label: "أكاديمية التعلم العالي" },
  { arabic_label: "المعهد العالي التقني" },
  { arabic_label: "معهد البرمجيات للسيدات" },
  { arabic_label: "مركز الدراسات الصحية-مستشفى القوات المسلحة" },
  { arabic_label: "معهد التنمية العربي" },
  { arabic_label: "جامعة دمشق - سوريا" },
  { arabic_label: "جامعة حلب - سوريا" },
  { arabic_label: "معهد بوابة المستقببل للتعليم الطبي" },
  { arabic_label: "جامعة الاسكندرية", label: "Alexandria University" },
  { arabic_label: "جامعة حلوان", label: "Helwan University" },
  { arabic_label: "جامعة أسيوط", label: "Assiut University" },
  { arabic_label: "جامعة الزقازيق", label: "Zagazeeq University" },
  { arabic_label: "جامعة طنطا", label: "Tanta University" },
  { arabic_label: "جامعة قناة السويس", label: "Suez Canal University" },
  { arabic_label: "جامعة المنصورة", label: "Almansourah University" },
  { arabic_label: "جامعة المنوفية", label: "Almonufiaya University" },
  { arabic_label: "جامعة المنيا", label: "Alminia University" },
  { arabic_label: "جامعة جنوب الوادي", label: "South Valley University" },
  {
    arabic_label: "الجامعة الأمريكية بالقاهرة",
    label: "American University at Cairo",
  },
  { arabic_label: "جامعة اليرموك", label: "Yarmouk University" },
  { arabic_label: "الجامعة الهاشمية", label: "Hashimate University" },
  {
    arabic_label: "جامعة الحسين بن طلال",
    label: "Alhussain Bin Talal University",
  },
  { arabic_label: "جامعة مؤته", label: "Mutah University" },
  { arabic_label: "جامعة آل البيت", label: "Al-Albait University" },
  {
    arabic_label: "جامعة الجاره الإسلامية",
    label: "Aligarh Muslim University",
  },
  {
    arabic_label: "جامعة أميتي للهندسة والتكنولوجيا",
    label: "Amity School of Engineering and Technolog",
  },
  {
    arabic_label: "جامعة بهاراتي فيدابيث ديمد",
    label: "Bharati Vidyapeeth Deemed University",
  },
  {
    arabic_label: "معهد بيرلا للتكنولوجيا والعلوم",
    label: "Birla Institute of Technology and Science",
  },
  {
    arabic_label: "جامعة ديلهي التقنية",
    label: "Delhi Technological University",
  },
  {
    arabic_label: "المعهد الهندي للعلوم بجيوهاتي",
    label: "Indian Institute of Sciences (Guwahati)",
  },
  {
    arabic_label: "المعهد الهندي لعلوم الفضاء",
    label: "Indian Institute of Aeronautical Engineering",
  },
  {
    arabic_label: "المعهد الهندي للعلوم والتكنولوجيا",
    label: "Indian Institute of Technology",
  },
  { arabic_label: "جامعة همدرد", label: "Jamia Hamdard" },
  { arabic_label: "الجامعة الملية الإسلامية", label: "Jamia Millia Islamia" },
  {
    arabic_label: "جامعة نهرو جواهرلال للتقنية",
    label: "Jawaharlal Nehru Technological University",
  },
  {
    arabic_label: "المعهد الوطني للتكنولوجيا بكاليكت",
    label: "National Institute of Technology Calicut",
  },
  { arabic_label: "جامعة اوسمانيا", label: "Osmania University" },
  { arabic_label: "جامعة كلكاتا", label: "University of Calcutta" },
  { arabic_label: "جامعة حيدر اباد", label: "University of Hyderabad" },
  { arabic_label: "جامعة مومباي", label: "University of Mumbai" },
  { arabic_label: "جامعة ميسور", label: "University Of Mysore" },
  { arabic_label: "جامعة بونا", label: "University Of Pune" },
  { arabic_label: "جامعة أغا خان", label: "Aga Khan University" },
  {
    arabic_label: "كلية العلامة إقبال الطبية",
    label: "Allama Iqbal Medical College",
  },
  {
    arabic_label: "الكلية الطبية العسكرية في روالبندي",
    label: "Army Medical College",
  },
  {
    arabic_label: "جامعة العلوم الصحية في لاهور",
    label: "University of Health Sciences",
  },
  { arabic_label: "جامعة البنجاب في لاهور", label: "Punjab University" },
  {
    arabic_label: "معهد إدارة الإعمال بكراتشي",
    label: "Institute of Business Administration",
  },
  {
    arabic_label: "الجامعة الإسلامية العالمية بإسلام آباد",
    label: "International Islamic University",
  },
  {
    arabic_label: "جامعة لياقت للعلوم الصحية بحيدرأباد",
    label: "Liaquat University of Medical & Health Science",
  },
  {
    arabic_label: "الجامعة الوطنية للحاسب والعلوم المستجدة",
    label: "National University of Computer & Emerging Sciences",
  },
  {
    arabic_label: "الجامعة الوطنية للعلوم والتقنية",
    label: "National University of Sciences and Technology",
  },
  {
    arabic_label: "جامعة الهندسة والتكنولوجيا بلاهور",
    label: "The University of Engineering and Technology Lahore",
  },
  { arabic_label: "جامعة همدارد بكراتشي", label: "University of Hmadard" },
  {
    arabic_label: "جامعة لاهور للعلوم الإدارية",
    label: "University of Management Sciences",
  },
  { arabic_label: "معهد ولنجتون", label: "Wellington Institute of Technology" },
  {
    arabic_label: "جامعة اوكلاند للتكنولوجيا",
    label: "Auckland University of Technology",
  },
  {
    arabic_label: "المعهد التقني في بي بيلنتي",
    label: "Bay of Plenty Polytechnic",
  },
  { arabic_label: "جامعة لنكون", label: "Lincoln University" },
  { arabic_label: "جامعة ماسي", label: "Massey University" },
  {
    arabic_label: "معهد كرايست سبرش",
    label: "The Christchurch Polytechnic Institute of Technology",
  },
  { arabic_label: "جامعة اوكلاند", label: "The University of Auckland" },
  {
    arabic_label: "معهد يوني تك التقني",
    label: "Unitec Institute of Technology",
  },
  { arabic_label: "جامعة كانتيربوري", label: "University of Canterbury" },
  { arabic_label: "جامعة اتاجو", label: "University of Otago" },
  { arabic_label: "جامعة ويكاتو", label: "University of Waikato" },
  {
    arabic_label: "جامعة فكتوريا في ولنجتون",
    label: "Victoria University of Wellington",
  },
  { arabic_label: "معهد وايكاتو", label: "Waikato Institute of Technology" },
  { arabic_label: "جامعة بوند", label: "Bond University" },
  { arabic_label: "جامعة كيرتن", label: "Curtin University" },
  { arabic_label: "جامعة دياكين", label: "Deakin University" },
  { arabic_label: "كلية العلوم الصحية للبنين بحائل" },
  { arabic_label: "جامعة العلوم التطبيقية الخاصة" },
  { arabic_label: "الجامعة العربية المفتوحة" },
  {
    arabic_label: "جامعة بويزي الحكومية - أمريكا",
    label: "Boise State University",
  },
  { arabic_label: "جامعة تشرين" },
  { arabic_label: "جامعة ميوري الحكومية", label: "Murray State Univ" },
  { arabic_label: "جامعة مصر للعلوم والتكنولوجيا" },
  {
    arabic_label: "جامعة واشنطن الحكومية",
    label: "Washington State University",
  },
  { arabic_label: "معهد أركان للتدريب الصحي" },
  { arabic_label: "كلية العلوم الصحية بالرياض-وزارة الصحة" },
  { arabic_label: "المعهد الوطني للتدريب الصحي" },
  { arabic_label: "جامعة هل" },
  { arabic_label: "معهد طب الطوارئ" },
  { arabic_label: "المعهد العربي للعلوم الصحية" },
  { arabic_label: "جامعة الأمير سطام بن عبدالعزيز" },
  { arabic_label: "المعهد السعودي الألماني للتمريض والعلوم الصحية" },
  { arabic_label: "كلية أوتيكا", label: "Utica College" },
  { arabic_label: "جامعة جانون", label: "Gannon University" },
  { arabic_label: "كلية العلوم الصحية المتوسطة للبنين - الرياض" },
  { arabic_label: "جامعة آغا خان" },
  { arabic_label: "TROY UNIVERSITY" },
  { arabic_label: "كلية العلوم الصحية للبنين بالرس" },
  { arabic_label: "جامعة حائل" },
  { arabic_label: "جامعة الإمام عبدالرحمن بن فيصل" },
  { arabic_label: "كلية الغونكوين", label: "Algonquin College" },
  { arabic_label: "جامعة ulm الالمانية", label: "ulm university" },
  { arabic_label: "جامعة UTICA الامريكية", label: "UTICA College" },
  { arabic_label: "جامعة تشجيانغ", label: "Zhejiang University" },
  { arabic_label: "كليات الريان الأهلية" },
  { arabic_label: "Youngstown State University" },
  { arabic_label: "معهد سلامة المشاريع العالي للتدريب" },
  { arabic_label: "معهد الوسام للدراسات الطبية" },
  {
    arabic_label: "جامعة داكوتا الشمالية",
    label: "University of North Dakota",
  },
  { arabic_label: "مركز الأمير سلطان للقلب" },
  { arabic_label: "أكاديمية التميز للتدريب الصحي - بنين" },
  { arabic_label: "جامعة جدة" },
  { arabic_label: "معهد الآفاق للتدريب الصحي" },
  { arabic_label: "جامعة انديانا بوليس", label: "Indianapolis" },
  { arabic_label: "ويسترن كنتاكي", label: "Western kentucku university" },
  { arabic_label: "جامعة فيليبس اوف ماربيج" },
  { arabic_label: "University of Groningen", label: "University of Groningen" },
  {
    arabic_label: "University of South Florida",
    label: "University of South Florida",
  },
  { arabic_label: "معهد الامداد العالي للتدريب" },
  {
    arabic_label: "كلية ينبع الجامعية",
    label: "Royal Commission Yanbu Colleges & Institutes",
  },
  { arabic_label: "جامعة القلمون" },
  { arabic_label: "جامعة القصيم" },
  { arabic_label: "جامعة جازان" },
  { arabic_label: "المعهد العالي التقني للبنات بالرياض" },
  { arabic_label: "معهد العلوم الصحية أهلي" },
  { arabic_label: "الهيئة العامة للتعليم التطبيقي والتدريب" },
  { arabic_label: "معهد شرق اشبيليا الصحي" },
  { arabic_label: "جامعة بويسي ستيت", label: "Boise ٍState University" },
  { arabic_label: "جامعة ريجينيا" },
  { arabic_label: "SUNY AT STONY BROOK" },
  { arabic_label: "Wright State University", label: "Wright State University" },
  { arabic_label: "كليات بريدة الأهلية" },
  { arabic_label: "كلية محمد المانع للعلوم الطبية" },
  { arabic_label: "المعهد الصحي المطور للبنات بتبوك" },
  { arabic_label: "جامعة جنوب ولاية اركنساس", label: "University of Arkansas" },
  { arabic_label: "جامعة الأعمال والتكنولوجيا" },
  { arabic_label: "جامعة قطر" },
  { arabic_label: "South East University" },
  { arabic_label: "شركة الأكاديمية العلمية الطبية المحدودة" },
  { arabic_label: "ORANGE COAST COLLEGE", label: "ORANGE COAST COLLEGE" },
  {
    arabic_label: "The University of Texas San Antonio",
    label: "The University of Texas San Antonio",
  },
  {
    arabic_label: "NOVA SOUTHEASTERN UNIVERSITY",
    label: "NOVA SOUTHEASTERN UNIVERSITY",
  },
  { arabic_label: "جامعة إنديانا بولس", label: "University of Indianapolis" },
  { arabic_label: "جامعة الشارقة" },
];
export const cities = [
  {
    code: "1",
    city_code: "63",
    arabic_label: "الحلوة بالحوطة",
    zone_code: "60",
  },
  { code: "1", city_code: "64", arabic_label: "الحناكية", zone_code: "14" },
  { code: "1", city_code: "65", arabic_label: "الحوطة", zone_code: "60" },
  { code: "1", city_code: "66", arabic_label: "الخرمة", zone_code: "29" },
  { code: "1", city_code: "67", arabic_label: "الدخانية" },
  { code: "1", city_code: "68", arabic_label: "الرويضة", zone_code: "40" },
  { code: "1", city_code: "69", arabic_label: "السليل", zone_code: "37" },
  { code: "1", city_code: "70", arabic_label: "السنابلي" },
  { code: "1", city_code: "71", arabic_label: "الشرقية" },
  { code: "1", city_code: "72", arabic_label: "الشقيري" },
  { code: "1", city_code: "73", arabic_label: "الطايف" },
  { code: "1", city_code: "74", arabic_label: "الظهران", zone_code: "45" },
  { code: "1", city_code: "75", arabic_label: "الفويلق" },
  { code: "1", city_code: "76", arabic_label: "القاهرة" },
  { code: "1", city_code: "77", arabic_label: "القصيم", zone_code: "12" },
  { code: "1", city_code: "78", arabic_label: "المذنب", zone_code: "61" },
  { code: "1", city_code: "79", arabic_label: "المسكية" },
  { code: "1", city_code: "80", arabic_label: "المضه" },
  { code: "1", city_code: "81", arabic_label: "ام الدوم", zone_code: "29" },
  { code: "1", city_code: "82", arabic_label: "بالحصين" },
  { code: "1", city_code: "83", arabic_label: "بدر", zone_code: "14" },
  {
    code: "1",
    city_code: "84",
    arabic_label: "حوطة بني تميم",
    zone_code: "60",
  },
  { code: "1", city_code: "85", arabic_label: "خيبر", zone_code: "14" },
  { code: "1", city_code: "86", arabic_label: "درعا" },
  { code: "1", city_code: "87", arabic_label: "دوما" },
  { code: "1", city_code: "88", arabic_label: "رجال المع", zone_code: "64" },
  { code: "1", city_code: "89", arabic_label: "روسيا" },
  { code: "1", city_code: "90", arabic_label: "ريم رجال المع" },
  { code: "1", city_code: "91", arabic_label: "سنام" },
  { code: "1", city_code: "92", arabic_label: "ضرية", zone_code: "12" },
  { code: "1", city_code: "93", arabic_label: "عنيزة", zone_code: "28" },
  { code: "1", city_code: "94", arabic_label: " النماص", zone_code: "54" },
  { code: "1", city_code: "95", arabic_label: "محايل", zone_code: "65" },
  { code: "1", city_code: "96", arabic_label: "نخال" },
  { code: "1", city_code: "97", arabic_label: "ينبع", zone_code: "62" },
  { code: "16", city_code: "3", arabic_label: "oregon" },
  { code: "16", city_code: "4", arabic_label: "الولايات المتحدة" },
  { code: "16", city_code: "5", arabic_label: "مينابولس" },
  { code: "18", city_code: "1", arabic_label: "الأبيض" },
  { code: "2", city_code: "2", arabic_label: "أربد", zone_code: "3" },
  { code: "2", city_code: "3", arabic_label: "عمان" },
  { code: "22", city_code: "1", arabic_label: "سان كانتان" },
  { code: "3", city_code: "4", arabic_label: "حلب" },
  { code: "3", city_code: "5", arabic_label: "سوريا", zone_code: "2" },
  { code: "6", city_code: "1", arabic_label: "الكويت", zone_code: "1" },
  { code: "8", city_code: "4", arabic_label: "بلبيس" },
  { code: "9", city_code: "2", arabic_label: "المغرب", zone_code: "1" },
  { code: "5", city_code: "3", arabic_label: "عراق", zone_code: "2" },
  { code: "4", city_code: "7", arabic_label: "غزة", zone_code: "3" },
  { code: "7", city_code: "2", arabic_label: "اليمن", zone_code: "1" },
  {
    code: "10",
    city_code: "2",
    arabic_label: "المملكة المتحدة",
    zone_code: "3",
  },
  { code: "13", city_code: "1", arabic_label: "الهند", zone_code: "1" },
  { code: "14", city_code: "2", arabic_label: "لبنان", zone_code: "1" },
  {
    code: "16",
    city_code: "6",
    arabic_label: "الولايات المتحدة الأمريكية",
    zone_code: "1",
  },
  { code: "17", city_code: "2", arabic_label: "الصومال", zone_code: "2" },
  { code: "18", city_code: "2", arabic_label: "السودان", zone_code: "1" },
  { code: "21", city_code: "1", arabic_label: "باكستان", zone_code: "1" },
  { code: "23", city_code: "1", arabic_label: "استراليا", zone_code: "1" },
  { code: "26", city_code: "1", arabic_label: "افغانستان", zone_code: "1" },
  { code: "22", city_code: "2", arabic_label: "باريس", zone_code: "1" },
  { code: "21", city_code: "3", arabic_label: "كراتشي" },
  { code: "1", city_code: "113", arabic_label: "المزاحمية", zone_code: "10" },
  { code: "32", city_code: "2", arabic_label: "مدينة عيسى", zone_code: "2" },
  { code: "16", city_code: "11", arabic_label: "واشنطن", zone_code: "11" },
  { code: "1", city_code: "2", arabic_label: "أبها", zone_code: "27" },
  { code: "1", city_code: "3", arabic_label: "ابوحجر", zone_code: "22" },
  { code: "1", city_code: "4", arabic_label: "الاحساء", zone_code: "2" },
  { code: "1", city_code: "5", arabic_label: "الأفلاج", zone_code: "3" },
  { code: "1", city_code: "6", arabic_label: "الباحة", zone_code: "32" },
  { code: "1", city_code: "7", arabic_label: "البادية" },
  { code: "1", city_code: "8", arabic_label: "البديعة", zone_code: "10" },
  { code: "1", city_code: "9", arabic_label: "البصرة" },
  { code: "1", city_code: "10", arabic_label: "البكيرية", zone_code: "49" },
  { code: "1", city_code: "11", arabic_label: "الجبيل", zone_code: "45" },
  { code: "1", city_code: "12", arabic_label: "الخبر", zone_code: "45" },
  { code: "1", city_code: "13", arabic_label: "الخرج", zone_code: "7" },
  { code: "1", city_code: "14", arabic_label: "الدمام", zone_code: "45" },
  { code: "1", city_code: "15", arabic_label: "الدوادمي", zone_code: "50" },
  { code: "1", city_code: "16", arabic_label: "الرس", zone_code: "9" },
  { code: "1", city_code: "17", arabic_label: "الرياض", zone_code: "10" },
  { code: "1", city_code: "18", arabic_label: "الزلفي", zone_code: "51" },
  { code: "1", city_code: "19", arabic_label: "الطائف", zone_code: "35" },
  { code: "1", city_code: "20", arabic_label: "الظهران", zone_code: "45" },
  { code: "1", city_code: "21", arabic_label: "العالية" },
  { code: "1", city_code: "22", arabic_label: "القديح", zone_code: "45" },
  { code: "1", city_code: "23", arabic_label: "القريات", zone_code: "11" },
  { code: "1", city_code: "24", arabic_label: "القطيف", zone_code: "45" },
  { code: "1", city_code: "25", arabic_label: "القنفذه", zone_code: "52" },
  { code: "1", city_code: "26", arabic_label: "القويعية", zone_code: "40" },
  { code: "1", city_code: "27", arabic_label: "الكويت" },
  { code: "1", city_code: "28", arabic_label: "المجمعة", zone_code: "39" },
  {
    code: "1",
    city_code: "29",
    arabic_label: "المدينة المنورة",
    zone_code: "14",
  },
  { code: "1", city_code: "30", arabic_label: "المهد", zone_code: "53" },
  { code: "1", city_code: "31", arabic_label: "النماص", zone_code: "54" },
  { code: "1", city_code: "32", arabic_label: "الهفوف", zone_code: "45" },
  { code: "1", city_code: "33", arabic_label: "ام سريحه" },
  { code: "1", city_code: "34", arabic_label: "بريدة", zone_code: "12" },
  { code: "1", city_code: "35", arabic_label: "بني مالك" },
  { code: "1", city_code: "36", arabic_label: "بيشة", zone_code: "55" },
  { code: "1", city_code: "37", arabic_label: "تبوك", zone_code: "36" },
  { code: "1", city_code: "38", arabic_label: "ثادق", zone_code: "10" },
  { code: "1", city_code: "39", arabic_label: "جدة", zone_code: "56" },
  { code: "1", city_code: "40", arabic_label: "جيزان" },
  { code: "1", city_code: "41", arabic_label: "حائل", zone_code: "59" },
  { code: "1", city_code: "42", arabic_label: "حريملاء", zone_code: "10" },
  { code: "1", city_code: "43", arabic_label: "حفر الباطن", zone_code: "24" },
  { code: "1", city_code: "44", arabic_label: "حوطة سدير", zone_code: "10" },
  { code: "1", city_code: "45", arabic_label: "خميس مشيط", zone_code: "27" },
  { code: "1", city_code: "46", arabic_label: "رأس تنورة", zone_code: "45" },
  { code: "1", city_code: "47", arabic_label: "رفحاء", zone_code: "5" },
  { code: "1", city_code: "48", arabic_label: "سكاكا", zone_code: "34" },
  { code: "1", city_code: "49", arabic_label: "سيهات", zone_code: "45" },
  { code: "1", city_code: "50", arabic_label: "شقراء", zone_code: "58" },
  { code: "1", city_code: "51", arabic_label: "صامطه", zone_code: "22" },
  { code: "1", city_code: "52", arabic_label: "صبياء", zone_code: "57" },
  { code: "1", city_code: "53", arabic_label: "طريف", zone_code: "5" },
  { code: "1", city_code: "54", arabic_label: "عرعر", zone_code: "5" },
  { code: "1", city_code: "55", arabic_label: "عفيف", zone_code: "33" },
  { code: "1", city_code: "56", arabic_label: "فرجينيا" },
  { code: "1", city_code: "57", arabic_label: "ابي عريش", zone_code: "22" },
  { code: "1", city_code: "58", arabic_label: "مكة", zone_code: "29" },
  { code: "1", city_code: "59", arabic_label: "مهد الذهب" },
  { code: "1", city_code: "60", arabic_label: "نجران", zone_code: "30" },
  { code: "1", city_code: "61", arabic_label: "وادي الدواسر", zone_code: "37" },
  { code: "1", city_code: "62", arabic_label: "ينبع البحر", zone_code: "62" },
  { code: "10", city_code: "1", arabic_label: "لندن", zone_code: "1" },
  { code: "11", city_code: "1", arabic_label: "مدريد", zone_code: "1" },
  { code: "12", city_code: "1", arabic_label: "كندا", zone_code: "1" },
  { code: "14", city_code: "1", arabic_label: "بيروت" },
  { code: "15", city_code: "1", arabic_label: "الدوحه", zone_code: "1" },
  { code: "15", city_code: "2", arabic_label: "قطر", zone_code: "1" },
  { code: "16", city_code: "1", arabic_label: "أطلانطا" },
  { code: "16", city_code: "2", arabic_label: "بلمان واشنطن" },
  { code: "17", city_code: "1", arabic_label: "هرجيسا" },
  { code: "2", city_code: "1", arabic_label: "الأردن", zone_code: "2" },
  { code: "3", city_code: "1", arabic_label: "الرياض" },
  { code: "3", city_code: "2", arabic_label: "حمص" },
  { code: "3", city_code: "3", arabic_label: "دمشق" },
  { code: "5", city_code: "1", arabic_label: "الزبير" },
  { code: "5", city_code: "2", arabic_label: "بغداد", zone_code: "3" },
  { code: "7", city_code: "1", arabic_label: "حضرموت" },
  { code: "8", city_code: "1", arabic_label: "السويس" },
  { code: "8", city_code: "2", arabic_label: "القاهره" },
  { code: "8", city_code: "3", arabic_label: "مصر", zone_code: "1" },
  { code: "9", city_code: "1", arabic_label: "الدار البيضاء" },
  { code: "4", city_code: "6", arabic_label: "فلسطين", zone_code: "2" },
  { code: "32", city_code: "1", arabic_label: "الرفاع", zone_code: "1" },
  { code: "3", city_code: "7", arabic_label: "قطنا", zone_code: "3" },
  { code: "1", city_code: "130", arabic_label: "العيص" },
  { code: "1", city_code: "131", arabic_label: "وادي الفرع" },
  { code: "1", city_code: "132", arabic_label: "البدائع" },
  { code: "1", city_code: "133", arabic_label: "الاسياح" },
  { code: "1", city_code: "134", arabic_label: "النبهانية" },
  { code: "1", city_code: "135", arabic_label: "عيون الجواء" },
  { code: "1", city_code: "136", arabic_label: "رياض الخبراء" },
  { code: "1", city_code: "137", arabic_label: "الشماسية" },
  { code: "1", city_code: "138", arabic_label: "النعيرية", zone_code: "45" },
  { code: "1", city_code: "139", arabic_label: "العديد" },
  { code: "1", city_code: "140", arabic_label: "أحد رفيدة", zone_code: "27" },
  { code: "1", city_code: "141", arabic_label: "ظهران الجنوب" },
  { code: "1", city_code: "142", arabic_label: "بلقرن" },
  { code: "1", city_code: "143", arabic_label: "المجاردة" },
  { code: "1", city_code: "144", arabic_label: "تنومة" },
  { code: "1", city_code: "145", arabic_label: "طريب" },
  { code: "1", city_code: "146", arabic_label: "بارق" },
  { code: "1", city_code: "147", arabic_label: "البرك" },
  { code: "1", city_code: "148", arabic_label: "الوجه", zone_code: "36" },
  { code: "1", city_code: "149", arabic_label: "ضباء" },
  { code: "1", city_code: "150", arabic_label: "تيماء" },
  { code: "1", city_code: "151", arabic_label: "أملج" },
  { code: "1", city_code: "152", arabic_label: "حقل" },
  { code: "1", city_code: "153", arabic_label: "البدع" },
  { code: "1", city_code: "154", arabic_label: "بقعاء" },
  { code: "1", city_code: "155", arabic_label: "الغزالة" },
  { code: "1", city_code: "156", arabic_label: "الشنان" },
  { code: "1", city_code: "157", arabic_label: "السليمي" },
  { code: "1", city_code: "158", arabic_label: "الحائط" },
  { code: "1", city_code: "159", arabic_label: "سميراء" },
  { code: "1", city_code: "160", arabic_label: "الشملي" },
  { code: "1", city_code: "161", arabic_label: "موقق" },
  { code: "1", city_code: "162", arabic_label: "العويقلية" },
  { code: "1", city_code: "163", arabic_label: "الحرث" },
  { code: "1", city_code: "164", arabic_label: "ضمد" },
  { code: "1", city_code: "165", arabic_label: "الريث" },
  { code: "1", city_code: "166", arabic_label: "بيش" },
  { code: "1", city_code: "167", arabic_label: "فرسان" },
  { code: "1", city_code: "168", arabic_label: "الدائر" },
  { code: "1", city_code: "169", arabic_label: "دومة الجندل" },
  { code: "16", city_code: "12", arabic_label: "اوهايو", zone_code: "12" },
  { code: "1", city_code: "171", arabic_label: "صفوى", zone_code: "45" },
  { code: "1", city_code: "98", arabic_label: "الخضراء" },
  { code: "1", city_code: "99", arabic_label: "الحائر", zone_code: "10" },
  { code: "1", city_code: "100", arabic_label: "الخفجي", zone_code: "45" },
  { code: "1", city_code: "101", arabic_label: "الظبية", zone_code: "22" },
  { code: "1", city_code: "102", arabic_label: "قرية العليا", zone_code: "45" },
  { code: "3", city_code: "6", arabic_label: "حماة" },
  { code: "30", city_code: "1", arabic_label: "بنغلاديش", zone_code: "1" },
  { code: "31", city_code: "1", arabic_label: "الفلبين", zone_code: "1" },
  { code: "16", city_code: "8", arabic_label: "كاليفورنيا", zone_code: "8" },
  { code: "1", city_code: "111", arabic_label: "ساراة عبيدة", zone_code: "27" },
  { code: "41", city_code: "1", arabic_label: "أديس أبابا" },
  { code: "16", city_code: "10", arabic_label: "بنسيلفينيا", zone_code: "10" },
  { code: "1", city_code: "116", arabic_label: "الدرعية" },
  { code: "1", city_code: "117", arabic_label: "ضرماء" },
  { code: "1", city_code: "118", arabic_label: "الحريق", zone_code: "60" },
  { code: "1", city_code: "119", arabic_label: "الغاط" },
  { code: "1", city_code: "120", arabic_label: "مرات" },
  { code: "1", city_code: "121", arabic_label: "الليث" },
  { code: "1", city_code: "122", arabic_label: "رابغ" },
  { code: "1", city_code: "123", arabic_label: "الجموم" },
  { code: "1", city_code: "124", arabic_label: "خليص" },
  { code: "1", city_code: "125", arabic_label: "الكامل" },
  { code: "1", city_code: "126", arabic_label: "رنية" },
  { code: "1", city_code: "127", arabic_label: "تربة" },
  { code: "1", city_code: "128", arabic_label: "ميسان" },
  { code: "1", city_code: "129", arabic_label: "العلا", zone_code: "14" },
  { code: "1", city_code: "170", arabic_label: "الفريدة", zone_code: "45" },
  { code: "10", city_code: "4", arabic_label: "واشنطن" },
  { code: "1", city_code: "172", arabic_label: "سيهات", zone_code: "45" },
  { code: "6", city_code: "2", arabic_label: "الجهرة", zone_code: "1" },
  { code: "6", city_code: "3", arabic_label: "الكويت", zone_code: "2" },
  { code: "1", city_code: "103", arabic_label: "عسير", zone_code: "27" },
  { code: "1", city_code: "104", arabic_label: "الجوف", zone_code: "34" },
  { code: "1", city_code: "105", arabic_label: "جازان", zone_code: "22" },
  { code: "1", city_code: "106", arabic_label: "تمير", zone_code: "10" },
  { code: "1", city_code: "107", arabic_label: "رماح", zone_code: "10" },
  { code: "1", city_code: "108", arabic_label: "الرمحية", zone_code: "10" },
  { code: "1", city_code: "109", arabic_label: "طبرجل", zone_code: "5" },
  { code: "18", city_code: "4", arabic_label: "كسلة", zone_code: "1" },
  { code: "1", city_code: "112", arabic_label: "بقيق", zone_code: "45" },
  { code: "1", city_code: "114", arabic_label: "تثليث", zone_code: "55" },
  { code: "1", city_code: "115", arabic_label: "العوامية", zone_code: "45" },
  { code: "1", city_code: "173", arabic_label: "الحجرة", zone_code: "32" },
  { code: "39", city_code: "1", arabic_label: "كوالمبور", zone_code: "1" },
  { code: "1", city_code: "110", arabic_label: "عقلة الصقور", zone_code: "12" },
  {
    code: "10",
    city_code: "3",
    arabic_label: "المملكة المتحدة",
    zone_code: "1",
  },
  { code: "40", city_code: "1", arabic_label: "امستردام" },
  { code: "16", city_code: "9", arabic_label: "فيرجينيا", zone_code: "9" },
  { code: "17", city_code: "3", arabic_label: "مقديشو", zone_code: "4" },
  { code: "42", city_code: "1", arabic_label: "دبي", zone_code: "1" },
  { code: "42", city_code: "2", arabic_label: "أبوظبي", zone_code: "2" },
];
export const nationality = [
  { arabic_label: "سعودي", label: "Saudi" },
  { arabic_label: "استرالي", label: "Australian" },
  { arabic_label: "إيرلندا", label: "Irish" },
  { arabic_label: "غايانا", label: "Guyanese" },
  { arabic_label: "إماراتي", label: "United Arab Emirates" },
  { arabic_label: "صيني", label: "Chinese" },
  { arabic_label: "أردني", label: "Jordanian" },
  { arabic_label: "سوري", label: "Syrian" },
  { arabic_label: "فلسطيني", label: "Palestinian" },
  { arabic_label: "عراقي", label: "Iraqi" },
  { arabic_label: "كويتي", label: "Kuwaiti" },
  { arabic_label: "يمني", label: "Yemeni" },
  { arabic_label: "مصري", label: "Egyptian" },
  { arabic_label: "مغربي", label: "Moroccan" },
  { arabic_label: "بريطاني", label: "British" },
  { arabic_label: "أسباني", label: "Spanish" },
  { arabic_label: "كندي", label: "Canadian" },
  { arabic_label: "هندي", label: "Indian" },
  { arabic_label: "لبناني", label: "Lebanese" },
  { arabic_label: "قطري", label: "Qatari" },
  { arabic_label: "أمريكي", label: "American" },
  { arabic_label: "صومالي", label: "Somalian" },
  { arabic_label: "بحريني", label: "Bahraini" },
  { arabic_label: "دانمركي", label: "Denemark" },
  { arabic_label: "بنغالي", label: "Bengali" },
  { arabic_label: "فلبيني", label: "Philippine" },
  { arabic_label: "تونسي", label: "Tunisian" },
  { arabic_label: "نيجيري", label: "Nigerian" },
  { arabic_label: "نيوزلندي", label: "New Zealand" },
  { arabic_label: "اثيوبي", label: "Ethiopian" },
  { arabic_label: "عماني", label: "Omanian" },
  { arabic_label: "سوداني", label: "Sudanese" },
  { arabic_label: "موريتاني", label: "Mauritanian" },
  { arabic_label: "اريتري", label: "Eritrean" },
  { arabic_label: "باكستاني", label: "Pakistani" },
  { arabic_label: "فرنسي", label: "French" },
  { arabic_label: "افغاني", label: "Afghani" },
  { arabic_label: "بوركيني", label: "Burkinabe" },
  { arabic_label: "قبائل نازحة", label: "Cross-Border Tribes" },
  { arabic_label: "مقيم", label: "Resident" },
  { arabic_label: "ألماني", label: "Germany" },
  { arabic_label: "شيكي", label: "Czechoslovakia" },
  { arabic_label: "جزائري", label: "Algerian" },
  { arabic_label: "أذربيجاني", label: "Azerbaijan" },
  { arabic_label: "مالي", label: "Malian" },
  { arabic_label: "الماليزية", label: "Malaysian" },
  { arabic_label: "هولندي", label: "Dutch" },
  { arabic_label: "جيبوتي", label: "Djiboutian" },
  { arabic_label: "ليبي", label: "Libyan" },
  { arabic_label: "أرجنتيني", label: "Argentina" },
  { arabic_label: "برازيلي", label: "Brazilian" },
  { arabic_label: "برتغالي", label: "Portuguese" },
  { arabic_label: "بوسني", label: "Bosnia" },
  { arabic_label: "سنغالي", label: "Senegalian" },
  { arabic_label: "سويدي", label: "Sweden" },
  { arabic_label: "مكسيكي", label: "Mexico" },
  { arabic_label: "نرويجي", label: "Norway" },
  { arabic_label: "نمساوي", label: "Austria" },
  { arabic_label: "ياباني", label: "japan" },
  { arabic_label: "اندونيسي", label: "Indonesia" },
  { arabic_label: "ايطالي", label: "Italy" },
  { arabic_label: "الباني", label: "Albanian" },
  { arabic_label: "اوزبكي", label: "Uzbekistan" },
  { arabic_label: "Belgium", label: "Belgium" },
  { arabic_label: "بولندي", label: "poland" },
  { arabic_label: "تركمستاني", label: "Turkmenistan" },
  { arabic_label: "تركي", label: "Turkey" },
  { arabic_label: "جزر القمر", label: "Comoros" },
  { arabic_label: "جنوب افريقيا", label: "South Africa" },
  { arabic_label: "جورجي", label: "Georgia" },
  { arabic_label: "روسي", label: "Russian" },
  { arabic_label: "سنغفوري", label: "Singapore" },
  { arabic_label: "سويسري", label: "Switzerland" },
  { arabic_label: "سيريلانكي", label: "Sri Lanka" },
  { arabic_label: "قبرصي", label: "Cyprus" },
  { arabic_label: "كرواتي", label: "Croatian" },
];
export const countries = [
  { code: "1", arabic_label: "السعودية", label: "Saudi Arabia" },
  { code: "23", arabic_label: "استراليا", label: "Australia" },
  { code: "24", arabic_label: "إيرلندا", label: "Ireland" },
  { code: "25", arabic_label: "غايانا", label: "Guyana" },
  {
    code: "42",
    arabic_label: "الامارات العربية المتحدة",
    label: "United Arab Emirates",
  },
  { code: "46", arabic_label: "الصين" },
  { code: "2", arabic_label: "الأردن", label: "Jordan" },
  { code: "3", arabic_label: "سوريا", label: "Syria" },
  { code: "4", arabic_label: "فلسطين", label: "Palestine" },
  { code: "5", arabic_label: "العراق", label: "Iraq" },
  { code: "6", arabic_label: "الكويت", label: "Kuwait" },
  { code: "7", arabic_label: "اليمن", label: "Yemen" },
  { code: "8", arabic_label: "مصر", label: "Egypt" },
  { code: "9", arabic_label: "المغرب", label: "Morocco" },
  { code: "10", arabic_label: "المملكة المتحدة", label: "United Kingdom" },
  { code: "11", arabic_label: "أسبانيا", label: "Spain" },
  { code: "12", arabic_label: "كندا", label: "Canda" },
  { code: "13", arabic_label: "الهند", label: "India" },
  { code: "14", arabic_label: "لبنان", label: "Lebanon" },
  { code: "15", arabic_label: "قطر", label: "Qatar" },
  {
    code: "16",
    arabic_label: "الولايات المتحدة الأمريكية",
    label: "United States of America",
  },
  { code: "17", arabic_label: "الصومال", label: "Somalia" },
  { code: "32", arabic_label: "مملكة البحرين", label: "Bahrain Kingdom" },
  { code: "36", arabic_label: "الدانمارك", label: "Denemark" },
  { code: "30", arabic_label: "بنغلادش", label: "Bangladesh" },
  { code: "31", arabic_label: "الفلبين", label: "Philippine" },
  { code: "33", arabic_label: "تونس", label: "Tunis" },
  { code: "34", arabic_label: "نيجيريا", label: "Nigeria" },
  { code: "37", arabic_label: "نيوزلندا", label: "New Zealand" },
  { code: "41", arabic_label: "أثيوبيا", label: "Ethiopia" },
  { code: "44", arabic_label: "عمان" },
  { code: "18", arabic_label: "السودان", label: "Sudan" },
  { code: "19", arabic_label: "موريتانيا", label: "Mauritania" },
  { code: "20", arabic_label: "اريتريا", label: "Eritrea" },
  { code: "21", arabic_label: "باكستان", label: "Pakistan" },
  { code: "22", arabic_label: "فرنسا", label: "France" },
  { code: "26", arabic_label: "افغانستان", label: "Afghanistan" },
  { code: "27", arabic_label: "بوركينا فاسو", label: "Burkina Faso" },
  { code: "28", arabic_label: "قبائل نازحة", label: "Cross-Border Tribes" },
  { code: "29", arabic_label: "مقيم", label: "Resident" },
  { code: "38", arabic_label: "المانيا", label: "German" },
  { code: "43", arabic_label: "تشيكوسلوفاكيا", label: "Czechoslovakia" },
  { code: "45", arabic_label: "الجزائر", label: "Algeria" },
  { code: "47", arabic_label: "أذربيجان", label: "Azerbaijan" },
  { code: "35", arabic_label: "مالي", label: "Mali" },
  { code: "39", arabic_label: "ماليزيا", label: "Malaysia" },
  { code: "40", arabic_label: "هولندا" },
  { code: "48", arabic_label: "جيبوتي", label: "Djibouti" },
  { code: "49", arabic_label: "ليبيا", label: "Libya" },
  { code: "50", arabic_label: "الأرجنتين", label: "Argentina" },
  { code: "51", arabic_label: "البرازيل", label: "Brazil" },
  { code: "52", arabic_label: "البراتغال", label: "Portugal" },
  {
    code: "53",
    arabic_label: "البوسنة والهرسك",
    label: "Bosnia and Herzegovina",
  },
  { code: "54", arabic_label: "السنغال", label: "Senegal" },
  { code: "55", arabic_label: "السويد", label: "Sweden" },
  { code: "56", arabic_label: "المكسيك", label: "Mexico" },
  { code: "57", arabic_label: "النرويج", label: "Norway" },
  { code: "58", arabic_label: "النمسا", label: "Austria" },
  { code: "59", arabic_label: "اليابان", label: "japan" },
  { code: "60", arabic_label: "اندونيسيا", label: "Indonesia" },
  { code: "61", arabic_label: "ايطاليا", label: "Italy" },
  { code: "62", arabic_label: "البانيا", label: "Albania" },
  { code: "63", arabic_label: "اوزبكستان", label: "Uzbekistan" },
  { code: "64", arabic_label: "بلجيكا", label: "Belgium" },
  { code: "65", arabic_label: "بولندا", label: "poland" },
  { code: "66", arabic_label: "تركمستان", label: "Turkmenistan" },
  { code: "67", arabic_label: "تركيا", label: "Turkey" },
  { code: "68", arabic_label: "جزر القمر", label: "Comoros" },
  { code: "69", arabic_label: "جنوب افريقيا", label: "South Africa" },
  { code: "70", arabic_label: "جورجيا", label: "Georgia" },
  { code: "71", arabic_label: "روسيا", label: "Russia" },
  { code: "72", arabic_label: "سنغفورا", label: "Singapore" },
  { code: "73", arabic_label: "سويسرا", label: "Switzerland" },
  { code: "74", arabic_label: "سيريلانكا", label: "Sri Lanka" },
  { code: "75", arabic_label: "قبرص", label: "Cyprus" },
  { code: "76", arabic_label: "كرواتيا", label: "Croatia" },
];
export const schools = [
  { label: "مدارس المشاعل الأهلية", code: "1" },
  { label: "مدارس التعليم المتطور", code: "1" },
  { label: "ثانوية نور التوحيد الأهلية", code: "1" },
  { label: "الثانوية السادسة والخمسون", code: "1" },
  { label: "الثانوية الثالثة", code: "1" },
  { label: "الثانوية السادسة بالمبرز", code: "1" },
  { label: "مدارس الرياض", code: "1" },
  { label: "الثانوية الثانية والاربعون", code: "1" },
  { label: "ثانوية أجيال الفكر الأهلية", code: "1" },
  { label: "ثانوية عهد النموذجية الاهلية", code: "1" },
  { label: "ثانوية الفنار الأهلية", code: "1" },
  { label: "الملك عبدالله بن عبدالعزيز", code: "1" },
  { label: "ثانوية رياض الصالحين الأهلية", code: "1" },
  { label: "ثانوية نبع العلوم الأهلية", code: "1" },
  { label: "ثانوية اضواء الرياض الأهلية", code: "1" },
  { label: "ثانوية طيبة الأهلية", code: "1" },
  { label: "الثانوية الثانية بالوجه نظام المقررات", code: "1" },
  { label: "مدارس القمم الدولية", code: "1" },
  { label: "ثانوية الامتياز الاهلية", code: "1" },
  { label: "ثانوية الاوائل", code: "1" },
  {
    label: "مدارس منارات الرياض العالمية",
    arabic_label: "Manarat Al_Riyadh",
    code: "1",
  },
  { label: "ثانوية المستقبل الاهلية", code: "1" },
  { label: "ثانوية التوحيد الاهلية", code: "1" },
  { label: "ثانوية الرابعة والثمانون", code: "1" },
  { label: "ثانوية الزيادي النموذجية الاهلية", code: "1" },
  { label: "الثانوية الخامسة والعشرون", code: "1" },
  { label: "ثانوية الإدريسي", code: "1" },
  { label: "ثانوية الغد الأهلية", code: "1" },
  { label: "الثانوية الاربعون", code: "1" },
  { label: "ثانوية المنهاج الأهلية", code: "1" },
  { label: "الثانوية التاسعة والثلاثون بعد المائة", code: "1" },
  { label: "ثانوية دار النهل الاهلية", code: "1" },
  { label: "مدارس وسائل الشروق الأهلية", code: "1" },
  { label: "الثانوية الثانية والخمسون", code: "1" },
  { label: "ثانوية النخبة الأهلية", code: "1" },
  { label: "ثانوية دار العلوم الأهلية", code: "1" },
  { label: "ثانوية الورود الاهلية", code: "1" },
  { label: "ثانوية الديوان الأهلية", code: "1" },
  { label: "الثانوية السادسة والخمسون بعد المائة", code: "1" },
  { label: "الثانوية الثلاثون بعد المائة", code: "1" },
  { label: "الثانوية السادسة والتسعون", code: "1" },
  { label: "الثانوية الحادية والستون", code: "1" },
  { label: "ثانوية الابتكارية الاهلية", code: "1" },
  { label: "ثانوية العربية الأهلية", code: "1" },
  { label: "الثانوية الاولى بشقراء", code: "1" },
  { label: "ثانوية الحرمين الأهلية", code: "1" },
  { label: "الثانوية السبعون", code: "1" },
  { label: "الثانوية الخمسون", code: "1" },
  { label: "الثانوية الرابعة والثلاثون بعد المائة", code: "1" },
  { label: "ثانوية رواد التربية الاهلية", code: "1" },
  { label: "ثانوية الاحسان الاهلية", code: "1" },
  { label: "المدرسة الثانية بأبها", code: "1" },
  { label: "ثانوية السمو الأهلية", code: "1" },
  { label: "ثانوية المتقدمة الاهلية", code: "1" },
  { label: "ثانوية اطياب الأهلية", code: "1" },
  { label: "ثانوية رواد العلوم الأهلية", code: "1" },
  { label: "ثانوية فرسان الجزيزة الاهلية بالخرج", code: "1" },
  { label: "مدارس الرواد العالمية", code: "1" },
  { label: "الثانوية الاولى بالمجمعة", code: "1" },
  { label: "الثانوية الخامسة", code: "1" },
  { label: "الثانوية الثالثة عشر", code: "1" },
  { label: "ثانوية التربية الاسلامية الاهلية", code: "1" },
  { label: "الثانوية الخامسة والثمانون", code: "1" },
  { label: "ثانوية الجرف", code: "1" },
  { label: "اجيال ساجر", code: "1" },
  { label: "ديحمة الثانوية", code: "1" },
  { label: "مدرسة مسلية", code: "1" },
  { label: "ثانوية الدريعية", code: "1" },
  { label: "ثانوية سمية بنت خياط رضي الله عنها", code: "1" },
  { label: "مدرسة ابن العقيل الثانوية", code: "1" },
  { label: "ثانوية واحة المعرفة الأهلية", code: "1" },
  { label: "ثانوية منارات المزاحمية الأهلية", code: "1" },
  { label: "ثانوية مجمع الملك سعود بن عبدالعزيز", code: "1" },
  { label: "الثانوية السابعة والستون بعد المائة", code: "1" },
  { label: "ثانوية أبي اسماعيل الهروي", code: "1" },
  { label: "ثانوية العلم العالمية", code: "1" },
  { label: "ثانوية الثانية والتسعون", code: "1" },
  { label: "الثانوية العشرون بعد المائة", code: "1" },
  { label: "الثانوية الرابعة والأربعون بعد المائة", code: "1" },
  { label: "الثانوية الثالتة والسبعون", code: "1" },
  { label: "ثانوية دار البشائر الأهلية", code: "1" },
  { label: "ثانوية الأمير محمد بن سعود الكبير", code: "1" },
  { label: "الثانوية الحادية والأربعون", code: "1" },
  { label: "أم المؤمنين حفصة الشرقية", code: "1" },
  { label: "الثانوية الخامسة والأربعون", code: "1" },
  { label: "الثانوية الخامسة عشر بعد المائة", code: "1" },
  { label: "ثانوية حنين مقررات", code: "1" },
  { label: "الثانوية الخامسة عشر", code: "1" },
  { label: "الثانوية الحادية بعد المائة", code: "1" },
  { label: "مدارس رحاب المعرفة الأهلية", code: "1" },
  { label: "الثانوية السادسة والثلاثون بعد المائة", code: "1" },
  { label: "الثانوية الخامسة والسبعون", code: "1" },
  { label: "ثانوية روابي الاندلس الاهلية", code: "1" },
  { label: "الغد الاهلية", code: "1" },
  { label: "الثانوية الرابعة عشر بعد المائة", code: "1" },
  { label: "الثانوية الاولى بضباء", code: "1" },
  { label: "الثانوية الحادي والثلاثون", code: "1" },
  { label: "Fulwood academy", code: "10" },
  { label: "الثانوية السادسة والثلاثون", code: "1" },
  { label: "مدارس الغراء الأهلية", code: "1" },
  { label: "ثانوية نور الايمان", code: "1" },
  { label: "مدارس رواد الامراء الاهلية", code: "1" },
  { label: "الثانوية الثالثة بعد المئة", code: "1" },
  { label: "ثانوية منارات اليمامة الاهلية", code: "1" },
  { label: "ثانوية الابناء بالرياض", code: "1" },
  { label: "مدارس العليا الاهلية", code: "1" },
  { label: "الثانوية السابعة والعشرون بالمدينة", code: "1" },
  { label: "ثانوية الصدارة الأهلية", code: "1" },
  { label: "مدرسة دار الحكمة العالمية", code: "1" },
  { label: "الثانوية الرابعة والعشرون بعد المائة", code: "1" },
  { label: "ثانوية السلام الأهلية بالخبر", code: "1" },
  { label: "ثانوية الملك عبدالله", code: "1" },
  { label: "الثانوية الرابعة بالخفجي", code: "1" },
  { label: "مدارس عهد النموذجية الأهلية", code: "1" },
  { label: "المجلس الثقافي البريطاني", code: "1" },
  { label: "ثانوية الشوكاني بالظهران", code: "1" },
  { label: "ثانوية آفاق البديعة الأهلية", code: "1" },
  { label: "ثانوية المناهج الأهلية", code: "1" },
  { label: "القديح", code: "1" },
  { label: "ثانوية ام الحمام - مقررات", code: "1" },
  { label: "الثانوية الخامسة عشر بالدمام مقررات", code: "1" },
  { label: "محمد بن ادريس", code: "1" },
  { label: "ثانوية كرامة الأحمر - مقررات", code: "1" },
  { label: "الاكاديمية الاسلامية السعودية", code: "16" },
  { label: "الثانوية الأولى بالنعيرية", code: "1" },
  { label: "فاطمة بنت المنذر للمقررات بالجبيل", code: "1" },
  { label: "العمرية الاهلية", code: "1" },
  { label: "المغيرة بن شعبة", code: "1" },
  { label: "ثانوية الحدقة", code: "1" },
  { label: "عتبة بن مسعود", code: "1" },
  { label: "اجيال الفكر", code: "1" },
  { label: "الثانوية الأولى بالأرطاوية", code: "1" },
  { label: "أنجال المملكة الأهلية بالحوية الثانوية", code: "1" },
  { label: "ضرية الثانوية", code: "1" },
  { label: "ابن ابي حاتم", code: "1" },
  { label: "ابن العميد", code: "1" },
  { label: "ابو بكر المخزومي", code: "1" },
  { label: "ثانوية السعد الأهلية - مقررات", code: "1" },
  { label: "الثانوية الثمانية و الأربعين بعد المائة", code: "1" },
  { label: "الشيخ عبدالله القرعاوي", code: "1" },
  { label: "ثانوية أبي تمام", code: "1" },
  { label: "احد", code: "1" },
  { label: "ثانوية الإمام محمد بن سعود", code: "1" },
  { label: "افاق التربية", code: "1" },
  { label: "الابناء سكن البحرية", code: "1" },
  { label: "الرواد الأهلية الثانوية", code: "1" },
  { label: "ثانوية سناء الجعفري", code: "1" },
  { label: "الأضواء", code: "1" },
  { label: "الالباني", code: "1" },
  { label: "الملك فهد الثانوية بمحلية", code: "1" },
  { label: "الامام الشافعي", code: "1" },
  { label: "الامام الشوكاني", code: "1" },
  { label: "الامام عبد العزيز بن محمد", code: "1" },
  { label: "الامام العواجي", code: "1" },
  { label: "ثانوية أسيد بن حضير - مقررات", code: "1" },
  { label: "الثانوية الأولى بالطرف", code: "1" },
  { label: "مجمع المعجل التعليمي في حوطة سدير", code: "1" },
  { label: "الامير احمد", code: "1" },
  { label: "الامير بدر", code: "1" },
  { label: "ثانوية بيت القيم الأهلية", code: "1" },
  { label: "الثانوية السادسة بعد المائة", code: "1" },
  { label: "الثانوية الثالثة", code: "1" },
  { label: "الامير سلمان", code: "1" },
  { label: "الامير طلال بن عبدالعزيز", code: "1" },
  { label: "المجيدية الثانوية نظام المقررات", code: "1" },
  { label: "ثانوية القبس الأهلية", code: "1" },
  { label: "الامير مشعل بن عبدالعزيز", code: "1" },
  { label: "ثانوية فرسان الجوبة الاهلية", code: "1" },
  { label: "ثانوية الشبيكية", code: "1" },
  { label: "التربية الحديثة", code: "1" },
  { label: "الجزيرة الاهلية", code: "1" },
  { label: "الحجاب الاهلية", code: "1" },
  { label: "الحجاز الاهلية", code: "1" },
  { label: "الحسن بن علي", code: "1" },
  { label: "الخليج الاهلية", code: "1" },
  { label: "الرشد الأهلية", code: "1" },
  { label: "الرواء العلمية", code: "1" },
  { label: "الرياض", code: "1" },
  { label: "السعودية الاهلية", code: "1" },
  { label: "السنابلي", code: "1" },
  { label: "الشاطي", code: "1" },
  { label: "الشمس الأهلية", code: "1" },
  { label: "الشوكاني", code: "1" },
  { label: "الشيخ محمد العثيمين", code: "1" },
  { label: "الشيخ محمد عبدالوهاب", code: "1" },
  { label: "الصديق", code: "1" },
  { label: "الصرح الاهلية", code: "1" },
  { label: "الطموح الاهلية", code: "1" },
  { label: "العباس بن عبدالمطلب", code: "1" },
  { label: "العروبة الاهلية", code: "1" },
  { label: "الفاروق", code: "1" },
  { label: "الفيصلية", code: "1" },
  { label: "الفيصلية الأهلية", code: "1" },
  { label: "الامام محمد بن سعود", code: "1" },
  { label: "الماوردي", code: "1" },
  { label: "المبرز", code: "1" },
  { label: "المتقدمة الاهلية", code: "1" },
  { label: "المجد الاهلية", code: "1" },
  { label: "المحافظة الاهلية", code: "1" },
  { label: "المدائن", code: "1" },
  { label: "المدارس السعودية", code: "1" },
  { label: "ثانوية القلم الأهلية", code: "1" },
  { label: "المزاحمية", code: "1" },
  { label: "المستقبل", code: "1" },
  { label: "مدارس الأثير الأهلية", code: "1" },
  { label: "المعتصم", code: "1" },
  { label: "المعتمد", code: "1" },
  { label: "المعهد الفني", code: "1" },
  { label: "الملك عبدالعزيز", code: "1" },
  { label: "الملك عبدالله", code: "1" },
  { label: "الملك فهد", code: "1" },
  { label: "مدارس المدينة الأكاديمية الأهلية", code: "1" },
  { label: "مدرسة الرمال العالمية", code: "1" },
  { label: "ثانوية الفضل الأهلية بمكة المكرمة", code: "1" },
  { label: "المواهب", code: "1" },
  { label: "الموحد", code: "1" },
  { label: "الموحد الاهلية", code: "1" },
  { label: "الميعاز", code: "1" },
  { label: "النجاشي", code: "1" },
  { label: "الثانوية الثانية بعنيزه", code: "1" },
  { label: "مدارس رياض الصالحين", code: "1" },
  { label: "ماس الأهلية", code: "1" },
  { label: "النمو", code: "1" },
  { label: "النمو الاهلية", code: "1" },
  { label: "ثانوية مدارس شمس الجزيرة الأهلية للبنات في الدمام", code: "1" },
  { label: "                        الثانوية الأولى في الفريدة", code: "1" },
  { label: "اليرموك", code: "1" },
  { label: "اليمامة", code: "1" },
  { label: "ام القري", code: "1" },
  { label: "بدر", code: "1" },
  { label: "الشيط", code: "1" },
  { label: "تجسير", code: "1" },
  { label: "ثانوية الاثلة", code: "1" },
  { label: "ثانوية ابن النفيس - مقررات", code: "1" },
  { label: "ثانوية صفوان", code: "1" },
  { label: "ثانوية الامير مقرن", code: "1" },
  { label: "ثانوية بني ظبيان", code: "1" },
  { label: "ثانوية الحريق", code: "1" },
  { label: "ثانوية الحسن بن علي", code: "1" },
  { label: "ثانوية الرائد", code: "1" },
  { label: "ثانوية ماليزية", code: "39" },
  { label: "ثانوية الشفاء", code: "1" },
  { label: "ثانوية المارودي", code: "1" },
  { label: "ثانوية المعتمد", code: "1" },
  { label: "ثانوية بدر", code: "1" },
  { label: "ثانوية جبل طارق", code: "1" },
  { label: "ثانوية حي النظيم", code: "1" },
  { label: "ثانوية عكاظ", code: "1" },
  { label: "ثاوية السليمانية", code: "1" },
  { label: "جده الدولي", code: "1" },
  { label: "سعد بن عبدالعزيز", code: "1" },
  { label: "سنام", code: "1" },
  { label: "ضرماء", code: "1" },
  { label: "ظليم الثانوية", code: "1" },
  { label: "عبدالرحمن الغافقي", code: "1" },
  { label: "عبدالمحسن بن عبدالعزيز", code: "1" },
  { label: "عتبة بن غزوان", code: "1" },
  { label: "عثمان بن عفان", code: "1" },
  { label: "قرطبة", code: "1" },
  { label: "كلية التقنية", code: "1" },
  { label: "متوسطة وثانوية الولامين", code: "1" },
  { label: "مجمع الامير احمد", code: "1" },
  { label: "مجمع الامير سلطان", code: "1" },
  { label: "مجمع الملك فهد", code: "1" },
  { label: "محمد الفاتح", code: "1" },
  { label: "مدارس الخليج", code: "1" },
  { label: "مدارس النبلاء", code: "1" },
  { label: "مدارس الفكر الاهلية ", code: "1" },
  { label: "معهد", code: "1" },
  { label: "معهد التنمية العربي", code: "1" },
  { label: "معهد الخليج", code: "1" },
  { label: "الثانوية الأولى بصوير", code: "1" },
  { label: "ثانوية النخبة العلمية الأهلية", code: "1" },
  { label: "منارات الشرقية", code: "1" },
  { label: "منارات المدينة", code: "1" },
  { label: "موسى بن نصير", code: "1" },
  { label: "الأندلس الأهلية الثانوية", code: "1" },
  { label: "نعام", code: "1" },
  { label: "وثيلان", code: "1" },
  { label: "ياقوت الحموي", code: "1" },
  { label: "الخبر الثانوية", code: "1" },
  { label: "الخطيب البغدادي", code: "1" },
  { label: "الملك فهد الثانوية", code: "1" },
  { label: "الرملية الثانوية", code: "1" },
  { label: "ثانوية البشائر الأهلية", code: "1" },
  { label: "ثانوية حوطة بني تميم", code: "1" },
  { label: "ثانوية محاسن", code: "1" },
  { label: "الاحساء", code: "1" },
  { label: "ثانوية الخيالة", code: "1" },
  { label: "ثانوية الملك عبدالله", code: "1" },
  { label: "الأمام ابو داوود", code: "1" },
  { label: "ثانوية روضة سدير", code: "1" },
  { label: "الخرمة الثانوية", code: "1" },
  { label: "ثانوية السليل", code: "1" },
  { label: "ثانوية مكة المكرمة - نظام مقررات", code: "1" },
  { label: "عمر بن الخطاب", code: "1" },
  { label: "ثانوية العارض", code: "1" },
  { label: "الثانوية الثانية بعد المائة", code: "1" },
  { label: "عمر بن العاص", code: "1" },
  { label: "عنيزة العامة", code: "1" },
  { label: "مدرسة العرين", code: "1" },
  { label: "التربية الاسلامية", code: "1" },
  { label: "الحديثة", code: "1" },
  { label: "ثانوية سعد", code: "1" },
  { label: "الاحساء الاهلية", code: "1" },
  { label: "مجمع عبدالعزيز وعبدلله التويجري التعليمي", code: "1" },
  { label: "عبدالرحمن بن عوف", code: "1" },
  { label: "ثانوية الشقيقه", code: "1" },
  { label: "ثانوية الشيخ بن عثيمن بابها", code: "1" },
  { label: "طيبة", code: "1" },
  { label: "ثانوية عقلة الصقور", code: "1" },
  { label: "ثانوية عفيف", code: "1" },
  { label: "ثانوية بدر في الهمجة", code: "1" },
  { label: "الحكم بن هشام", code: "1" },
  { label: "العرباض بن سارية", code: "1" },
  { label: "الخماسين", code: "1" },
  { label: "الثانوية الأولى بليلى", code: "1" },
  { label: "الثانوية الثالثة بالخبر", code: "1" },
  { label: "الثانوية الأولى", code: "1" },
  { label: "الثانوية الأولى بالدرعية", code: "1" },
  { label: "الثانوية الثالثة والأربعون", code: "1" },
  { label: "الثانوية الثامنة", code: "1" },
  { label: "الثانوية الثامنة والتسعون", code: "1" },
  { label: "الثانوية الثانية والثلاثون", code: "1" },
  { label: "الثانوية الثانية والعشرون", code: "1" },
  { label: "الثانوية الثانية والعشرون بعد المائة", code: "1" },
  { label: "الثانوية الحادية والثلاثون", code: "1" },
  { label: "الثانوية الحادية والعشرون", code: "1" },
  { label: "الثانوية الخامسة والستون", code: "1" },
  { label: "الثانوية الرابعة بالرياض", code: "1" },
  { label: "الثانوية السابعة عشر بعد المائة", code: "1" },
  { label: "دار الحنان الأهلية الثانوية", code: "1" },
  { label: "الثانوية السادسة عشر بعد المائة", code: "1" },
  { label: "الثانوية العاشرة", code: "1" },
  { label: "مدرسة الملك عبدالعزيز الأهلية النموذجية الثانوية", code: "1" },
  { label: "المدرسة العالمية الارتيرية", code: "1" },
  { label: "ثانوية الأبناء بالكلية الحربية", code: "1" },
  { label: "ثانوية اجيال المعرفة الأهلية", code: "1" },
  { label: "ثانوية سعود الفيصل", code: "1" },
  { label: "ثانوية أم المؤمنين صفية بنت حيي", code: "1" },
  { label: "ثانوية معهد الرياض النموذجي", code: "1" },
  {
    label: "مدارس ابن خلدون الأهلية",
    arabic_label: "Ibn Khaldun School",
    code: "1",
  },
  { label: "مدارس البيان الأهلية", code: "1" },
  { label: "مدارس التربية النموذجية الأهلية", code: "1" },
  { label: "مدارس السلام الأهلية", code: "1" },
  { label: "مدارس شموخ الأجيال الأهلية", code: "1" },
  { label: "مدارس العبير الأهلية", code: "1" },
  { label: "مدارس الفيصل الحديثة الأهلية", code: "1" },
  { label: "مدارس المعالي الأهلية", code: "1" },
  { label: "مدارس النخبة الأهلية فرع الخليج", code: "1" },
  { label: "ثانوية الدوادمي", code: "1" },
  { label: "الامام النووي الثانوية", code: "1" },
  { label: "مدرسة البراء بن مالك الثانوية", code: "1" },
  { label: "الملك خالد الثانوية بالهفوف", code: "1" },
  { label: "الثانوية الثامنة", code: "1" },
  { label: "النجاح مقررات", code: "1" },
  {
    label: "مدرسة نافذة المستقبل العالمية",
    arabic_label: "future window int s.",
    code: "1",
  },
  { label: "الثانوية الحادية والعشرون بالدمام", code: "1" },
  { label: "مدارس الجامعة بالخبر", code: "1" },
  { label: "مدارس الملك عبدالعزيز النموذجية", code: "1" },
  { label: "الثانوية الثالثة بخميس مشيط", code: "1" },
  { label: "ثانوية الرونة بخميس مشيط", code: "1" },
  { label: "المدرسة الثانوية الأولى بمكة المكرمة", code: "1" },
  { label: "الثانويه الثانيه والسبعون", code: "1" },
  { label: "المدارس الأهليه", code: "1" },
  { label: "ثانوية بنات الغد", code: "1" },
  { label: "ثانوية ثار", code: "1" },
  { label: "التربيه والتعليم الاهليه", code: "1" },
  { label: "الثانويه الثامنه", code: "1" },
  { label: "النخبه الاهليه", code: "1" },
  { label: "ثانوية اللقيه", code: "1" },
  { label: "مجمع الامير محمد بن فهد التعليمي", code: "1" },
  { label: "الثانوية التاسعة عشر", code: "1" },
  { label: "الثانوية الثالثة بالخرج", code: "1" },
  { label: "الثانوية الثانيه بخيبر", code: "1" },
  { label: "الثانوية الثانيه بعد المائه", code: "1" },
  { label: "الثانويه الحاديه والثلاثون بعد المائه", code: "1" },
  { label: "الثانويه الحاديه والعشرون", code: "1" },
  { label: "الثانويه الخامسه والثلاثون", code: "1" },
  { label: "الثانويه المائه", code: "1" },
  { label: "السنابل الاهليه", code: "1" },
  { label: "العربيه الاهليه", code: "1" },
  { label: "الفرسان الاهليه", code: "1" },
  { label: "الفيصليه الأهليه", code: "1" },
  { label: "القمه الاهليه", code: "1" },
  { label: "المجد الاهليه", code: "1" },
  { label: "الملك عبدالعزيز الاهليه", code: "1" },
  { label: "ابن العقيل", code: "1" },
  { label: "ثانوية الملك فهد", code: "1" },
  { label: "الثانوية السابعة و الأربعون", code: "1" },
  { label: "ثانوية القادسية", code: "1" },
  { label: "الدالوه", code: "1" },
  { label: "القارة", code: "1" },
  { label: "اليمامةالثانوية", code: "1" },
  { label: "الامام عبدالرحمن الفيصل الثانوية", code: "1" },
  { label: "نجدالثانوية", code: "1" },
  { label: "الملك فهد", code: "1" },
  { label: "ليلي", code: "1" },
  { label: "الثانوية التاسعة", code: "1" },
  { label: "ثانوية رفحاء", code: "1" },
  { label: "ثانوية روضة هباس", code: "1" },
  { label: "الخبر النموذجية", code: "1" },
  { label: "ثانوية الادريسي", code: "1" },
  { label: "الداهنه", code: "1" },
  { label: "الرضوان الثانوية", code: "1" },
  { label: "الفيصليه بالحنايج", code: "1" },
  { label: "المدارس السعوديه", code: "1" },
  { label: "الملك عبدالعزيز", code: "1" },
  { label: "جرير الاهليه", code: "1" },
  { label: "رائدة السلاح", code: "1" },
  { label: "سهما الاهليه", code: "1" },
  { label: "طليطلة", code: "1" },
  { label: "المتنبي", code: "1" },
  { label: "أبوبكر العربي", code: "1" },
  { label: "أبي بكر المخزومي", code: "1" },
  { label: "أجيال اليرموك الأهلية", code: "1" },
  { label: "أركان التدريب الصحي", code: "1" },
  { label: "الابناء", code: "1" },
  { label: "الابواء", code: "1" },
  { label: "الادريسي", code: "1" },
  { label: "الأعتصام الاهلية", code: "1" },
  { label: "الأكاديمية الدولية للعلوم الصحية", code: "1" },
  { label: "الإمام الجويني", code: "1" },
  { label: "الأمام الشاطبي", code: "1" },
  { label: "الامام عبدالله بن سعود", code: "1" },
  { label: "الامير سلمان التعليمي", code: "1" },
  { label: "الامير عبدالرحمن", code: "1" },
  { label: "الأمير عبدالعزيز بن محمد", code: "1" },
  { label: "الأمير عبدالمحسن بن عبدالعزيز", code: "1" },
  { label: "الامير فيصل بن فهد", code: "1" },
  { label: "الامير ماجد بن عبدالعزيز", code: "1" },
  { label: "الأمير مشعل بن سعود", code: "1" },
  { label: "الأمير ناصر بن عبدالعزيز", code: "1" },
  { label: "الاميرنايف بن عبدالعزيز", code: "1" },
  { label: "التربية النموذجية", code: "1" },
  { label: "الجبيل الاهلية", code: "1" },
  { label: "الحجاز الأهلية", code: "1" },
  { label: "الحضارة الأهلية", code: "1" },
  { label: "الخبر النموذجية الأهلية", code: "1" },
  { label: "الخبرالثانوية", code: "1" },
  { label: "الرائد الأهلية", code: "1" },
  { label: "الزوار الاهلية", code: "1" },
  { label: "السفراء الأهلية", code: "1" },
  { label: "الشفا الاهلية", code: "1" },
  { label: "الشفاء الاهلية", code: "1" },
  { label: "الشوري", code: "1" },
  { label: "الصحافة الاهلية", code: "1" },
  { label: "العارض الاهلية", code: "1" },
  { label: "العبير الأهلية", code: "1" },
  { label: "العربية الأهلية", code: "1" },
  { label: "ثانوية معاوية بن ابي سفيان", code: "1" },
  { label: "ثانوية الملك فهد", code: "1" },
  { label: "أكاديمية قطر للقادة", code: "15" },
  { label: "الشرق الاهلية", code: "1" },
  { label: "الحسن بن الهيثم", code: "1" },
  { label: "القاسم بن سلام", code: "1" },
  { label: "القدس", code: "1" },
  { label: "ثانوية نجد - نظام المقررات", code: "1" },
  { label: "الثانوية الرابعة بطريف", code: "1" },
  { label: "عيون الجواء", code: "1" },
  { label: "المعهد الصحي", code: "1" },
  { label: "المعهد الفني بابها", code: "1" },
  { label: "المعهد الفني للتدريب الصحي", code: "1" },
  { label: "الملك سعود التعليمي", code: "1" },
  { label: "المنهل الأهلية", code: "1" },
  { label: "المورد الأهلية", code: "1" },
  { label: "النخبة الأهلية", code: "1" },
  { label: "النظامية الاهلية", code: "1" },
  { label: "النوابغ الأهلية", code: "1" },
  { label: "الهدى", code: "1" },
  { label: "الوسط الأهلية", code: "1" },
  { label: "بلاط الشهداء", code: "1" },
  { label: "ثانوية الأمير سطام بن عبدالعزيز", code: "1" },
  { label: "ثانوية الخرج", code: "1" },
  { label: "ثانوية الرضوان", code: "1" },
  { label: "ثانوية الرياض", code: "1" },
  { label: "ثانوية الشيخ عبدالعزيز بن باز", code: "1" },
  { label: "ثانوية الصديق", code: "1" },
  { label: "ثانوية الفراء", code: "1" },
  { label: "ثانوية المدائن", code: "1" },
  { label: "ثانوية الملك عبدالعزيز", code: "1" },
  { label: "ثانوية الملك فهد", code: "1" },
  { label: "ثانوية الملك فيصل", code: "1" },
  { label: "ثانوية المواهب", code: "1" },
  { label: "ثانوية المواهب الأهلية", code: "1" },
  { label: "ثانوية النبلاء الأهلية", code: "1" },
  { label: "ثانوية تمير", code: "1" },
  { label: "ثانوية حريملاء", code: "1" },
  { label: "ثانوية رفحاء", code: "1" },
  { label: "ثانوية طيبه", code: "1" },
  { label: "ثانوية عبدالرحمن الغافقي", code: "1" },
  { label: "ثانوية عبدالرحمن بن مهدي", code: "1" },
  { label: "ثانوية عثمان بن أبي العاص", code: "1" },
  { label: "ثانوية عرقة", code: "1" },
  { label: "ثانوية عمرو بن العاص", code: "1" },
  { label: "ثانوية محمد الغزنوي", code: "1" },
  { label: "ثنوية الأمير سعد بن عبدالعزيز", code: "1" },
  { label: "جامعة الطائف", code: "1" },
  { label: "جبل طارق", code: "1" },
  { label: "جيل الريادة الأهلية", code: "1" },
  { label: "حي الشفا", code: "1" },
  { label: "دار الرأي", code: "1" },
  { label: "دار الصفوة", code: "1" },
  { label: "راية الفرقان", code: "1" },
  { label: "رياض الموحد الأهلية", code: "1" },
  { label: "شباب الفهد", code: "1" },
  { label: "شبه الجزيره الأهلية", code: "1" },
  { label: "شروق المعرفة", code: "1" },
  { label: "شقراء", code: "1" },
  { label: "عبدالرحمن بن مهدي", code: "1" },
  { label: "عبدالله القرعاوي", code: "1" },
  { label: "عبدالله بن مضعون", code: "1" },
  { label: "عثمان بن عفان الأهلية", code: "1" },
  { label: "عكاظ الأهلية", code: "1" },
  { label: "علوم الرياض الأهلية", code: "1" },
  { label: "عمرالفاتح", code: "1" },
  { label: "فرسان الجزيرة", code: "1" },
  { label: "كلية العلوم الصحية", code: "1" },
  { label: "مجمع العليان التعليمي", code: "1" },
  { label: "محمود الغزنوي", code: "1" },
  { label: "مدارس أبها", code: "1" },
  { label: "دار الفيصل الأهلية", code: "1" },
  { label: "الثانوية السبعون بعد المائة", code: "1" },
  { label: "ثانوية مسار العلوم و المعرفة الأهلية", code: "1" },
  { label: "مدارس القتح", code: "1" },
  { label: "مدارس الهدى", code: "1" },
  { label: "ثانوية منارات عنيزة الاهلية", code: "1" },
  { label: "مدرسة عهد الأهلية", code: "1" },
  { label: "معهد السباعي", code: "1" },
  { label: "معهد العلوم الصحية", code: "1" },
  { label: "معهد بوابة المستقبل", code: "1" },
  { label: "معهد حفر الباطن", code: "1" },
  { label: "معهدالعاصمة النموذجي", code: "1" },
  { label: "الثانوية الثالثة", code: "1" },
  { label: "الملك فهد", code: "1" },
  { label: "مواهب انجال الحوية", code: "1" },
  { label: "نخبة الفرسان", code: "1" },
  { label: "امل المستقبل الاهلية", code: "1" },
  { label: "سعيد بن جبير الثانوية بالدمام", code: "1" },
  { label: "مناهل التربية الثانوية", code: "1" },
  { label: "الامير مقرن بن عبد العزيز", code: "1" },
  { label: "هاشم بن العاص", code: "1" },
  { label: "الملك خالد", code: "1" },
  { label: "مجمع الاميرسلطان التعليمي", code: "1" },
  { label: "ابي ايوب الانصاري", code: "1" },
  { label: "ثانوية السلمانية", code: "1" },
  { label: "المعرفة الاهلية", code: "1" },
  { label: "الشيخ عبدالله القرعاوي بصامطة", code: "1" },
  { label: "حذيفةبن اليمان", code: "1" },
  { label: "دار الصفوة", code: "1" },
  { label: "تانوية راس تنورة", code: "1" },
  { label: "امل المستقبل الاهلية", code: "1" },
  { label: "تمبر العامة", code: "1" },
  { label: "ثانوية الواديين", code: "1" },
  { label: "ثانوية السفارات", code: "1" },
  { label: "ثانوية الطائف", code: "1" },
  { label: "المعارف", code: "1" },
  { label: "الادريسي", code: "5" },
  { label: "الثانوية السابعة بالمبرز", code: "1" },
  { label: "آفاق المنصورة الأهلية", code: "1" },
  { label: "الثانوية الاولى بالخفجي", code: "1" },
  { label: "ثانوية القمم الأهلية بسكاكا", code: "1" },
  { label: "مدرسة الخفجي", code: "1" },
  { label: "دار الحنان بتبوك", code: "1" },
  { label: "الثانوية الواحدة والعشرون", code: "1" },
  { label: "المعهد العالي التقني", code: "1" },
  { label: "مدارس اجيال الثقافة الأهلية", code: "1" },
  { label: "الملك فهد", code: "1" },
  { label: "مدارس أجيال الأقصى الاهلية", code: "1" },
  { label: "الثانوية الثانية", code: "1" },
  { label: "التربية الاهلية", code: "1" },
  { label: "التربية الاهلية بالخبر", code: "1" },
  { label: "الشيخ عبدالله الخليفي", code: "1" },
  { label: "الثانوية الثامنة", code: "1" },
  { label: "ثانوية ابن النفيس", code: "1" },
  { label: "مجمع ابن القيم التعليمي الثانوي", code: "1" },
  { label: "الثانوية الاولى بحلة محيش", code: "1" },
  { label: "الثانوية الأولى بسيهات", code: "1" },
  { label: "ثانوية الإمام محمد بن سعود", code: "1" },
  { label: "عبدالله بن مسعود الثانوية", code: "1" },
  { label: "العويقيلة الثانوية", code: "1" },
  { label: "ثانوية الأمير محمد بن عبدالعزيز", code: "1" },
  { label: "النعيرية الأهلية", code: "1" },
  { label: "حطين", code: "1" },
  { label: "ثانوية صقر قرش", code: "1" },
  { label: "الثانوية الثانية بالعوامية", code: "1" },
  { label: "الثانوية الثانية بسيهات", code: "1" },
  { label: "مجمع نوره الجبر التعليمي القسم الثانوي", code: "1" },
  { label: "الثانوية الثانية والعشرون المطوره", code: "1" },
  { label: "الثانوية الحبش الأولى", code: "1" },
  { label: "الثانوية الخامسة بالدمام", code: "1" },
  { label: "الثانوية الرابعة", code: "1" },
  { label: "الثانوية الرابعة بالجبيل", code: "1" },
  { label: "الثانوية الرابعة بالمبرز", code: "1" },
  { label: "الثانوية السادسة", code: "1" },
  { label: "الثانوية السادسة بالجبيل", code: "1" },
  { label: "الثامنة و الثلاثون", code: "1" },
  { label: "ثانوية القدس بمكة", code: "1" },
  { label: "الثانوية العاشرة", code: "1" },
  { label: "الخالدية الاهلية", code: "1" },
  { label: "ثانوية الحادية والعشرون بالدمام", code: "1" },
  { label: "ثانوية دور العلوم الاهلية", code: "1" },
  { label: "الثانوية الثانية بالحناكية", code: "1" },
  { label: "مدارس التربية والتعليم الاهلية", code: "1" },
  { label: "مدارس الحصان النموذجية الاهلية للبنات بالدمام", code: "1" },
  { label: "ثانوية الغصون", code: "1" },
  { label: "الابرار الاهلية", code: "1" },
  { label: "الثانوية الأولى للبنات  نظام المقررات", code: "1" },
  { label: "المعتزبالله الاهلية", code: "1" },
  { label: "ثانوية الفائجة", code: "1" },
  { label: "دار الهجرة الاهلية", code: "1" },
  { label: "اجيال المعرفة الثانوية", code: "1" },
  { label: "اكادمية الفيصل العالمية", code: "1" },
  { label: "ثانوية الثانية بالثقبه", code: "1" },
  { label: "الامام الشافعي الاهلية", code: "1" },
  { label: "الثانوية الأولى بالجفر", code: "1" },
  { label: "مجمع شباب الفهد التعليمي الأهلية الثانوية", code: "1" },
  { label: "التربية الاسلامية", code: "1" },
  { label: "الثانوية السابعة", code: "1" },
  { label: "التاسعة بأبها", code: "1" },
  { label: "الثانوية 119", code: "1" },
  { label: "الثانوية 122", code: "1" },
  { label: "الثانوية 22", code: "1" },
  { label: "الثانوية 25", code: "1" },
  { label: "الثانوية التاسعة بالخرج", code: "1" },
  { label: "هوازن", code: "1" },
  { label: "الثانوية الثامنة والخامسون", code: "1" },
  { label: "ثانوية رؤية المستقبل", code: "1" },
  { label: "الشواطن", code: "1" },
  { label: "ثانوية الأمير سلطان بن عبدالعزيز", code: "1" },
  { label: "المدرسة السعودية في مدريد - انتساب", code: "11" },
  { label: "الثانوية السادسة والاربعون", code: "1" },
  { label: "الثانوية الستون", code: "1" },
  { label: "الثانوية الواحدة والثلاثون", code: "1" },
  { label: "الدوحة الاهلية", code: "1" },
  { label: "الإمام تركي بن عبدالله الثانوية", code: "1" },
  { label: "الثانوية الثالثة بالقطيف ( مقررات )", code: "1" },
  { label: "ثانوية البديع", code: "1" },
  { label: "الرياض الاهلية", code: "1" },
  { label: "ثانوية ابن المنذر", code: "1" },
  { label: "ثانوية الأمير سلمان بن محمد - مقررات", code: "1" },
  { label: "بدر الاهلية", code: "1" },
  { label: "بسمة النجاح الاهلية", code: "1" },
  { label: "ثانوية 102", code: "1" },
  { label: "ثانوية 117", code: "1" },
  { label: "ثانوية الابناء", code: "1" },
  { label: "ثانوية الأبناء الخرج", code: "1" },
  { label: "ثانوية الخليج الاهلية", code: "1" },
  { label: "ثانوية المنارات بمحافظة عنيزة", code: "1" },
  { label: "جامعة الملك السعود", code: "1" },
  { label: "صقر قريش", code: "1" },
  { label: "عائشة ام المؤمنين", code: "1" },
  { label: "مدارس  التربية الاهلية", code: "1" },
  { label: "مدارس الامام الشافعي", code: "1" },
  { label: "ثانوية مدارس الخليج النموذجية الأهلية للبنات بالدمام", code: "1" },
  { label: "مدارس التقوى الاسلامية الاهلية", code: "1" },
  { label: "الثانوية الثلاثون", code: "1" },
  { label: "مدارس الألسن العالمية", code: "1" },
  { label: "مدارس الزيادي النموذجية الاهلية", code: "1" },
  { label: "مدارس العربية الاهلية", code: "1" },
  { label: "ثانوية رواد السلام الأهلية", code: "1" },
  { label: "مدارس القمة الاهلية", code: "1" },
  { label: "طريف الثانوية بمحافظة طريف", code: "1" },
  { label: "رياض الاسلام الأهلية", code: "1" },
  { label: "مدارس المملكة الاهلية", code: "1" },
  { label: "مدارس المنصورة العالمية", code: "1" },
  { label: "الشبحة", code: "1" },
  { label: "مدارس النخبة الاهلية فرع العليا", code: "1" },
  { label: "مدارس جرير الاهلية", code: "1" },
  { label: "مدارس خالد العالمية", code: "1" },
  { label: "مدارس دار البشائر الاهلية", code: "1" },
  { label: "جعفر الطيار", code: "1" },
  { label: "الهفوف الثانوية - مقررات", code: "1" },
  { label: "ثانوية القيروان", code: "1" },
  { label: "مدارس قرطبة الاهلية", code: "1" },
  { label: "مدراس الزهراء الاهلية", code: "1" },
  { label: "مدرسة سري في لندن", code: "1" },
  { label: "الثانويه الا ولى بالعتيق", code: "1" },
  { label: "الثانويه الاولى بالجارودية", code: "1" },
  { label: "الثانويه الاولى بالربيعه", code: "1" },
  { label: "الثانويه الاولى بالمبرز", code: "1" },
  { label: "الثانويه الاولى بصفوى", code: "1" },
  { label: "الثانويه الثانيه بالقطيف", code: "1" },
  { label: "الثانويه الرابعه بالدمام", code: "1" },
  { label: "الثانويه العاشره بالدمام", code: "1" },
  { label: "ثانويه الانجال الاهليه", code: "1" },
  { label: "ثانويه الواحه الاهليه", code: "1" },
  { label: "المعهد الصحى بالمدينه المنورة", code: "1" },
  { label: "الثانويه الثالثه والستون", code: "1" },
  { label: "الثانويه الثامنه والثلاثون", code: "1" },
  { label: "الثانويه الثانيه بالزلفي", code: "1" },
  { label: "الثانويه الثانيه والثمانون", code: "1" },
  { label: "الثانويه الحاديه والاربعون", code: "1" },
  { label: "الثانويه الخامسه والتسعون", code: "1" },
  { label: "الثانويه السابعه والثمانون", code: "1" },
  { label: "الثانويه السابعه والستون", code: "1" },
  { label: "الثانويه السادسه عشرة", code: "1" },
  { label: "الثانويه العشرون بعد المائه", code: "1" },
  { label: "الثانويه المائه بعد العشرون", code: "1" },
  { label: "الثانويه المائه واحدى عشر", code: "1" },
  { label: "الثانويه المئه والتسعه عشر", code: "1" },
  { label: "ثانويه السادسه والتسعون", code: "1" },
  { label: "ثانويه العبير الاهليه", code: "1" },
  { label: "ثانويه العليا", code: "1" },
  { label: "ثانويه العيينه", code: "1" },
  { label: "ثانوية آفاق التربية الاهلية", code: "1" },
  { label: "ثانويه الفيصليه الحديثه الاهليه", code: "1" },
  { label: "ثانويه فرسان الجزيره", code: "1" },
  { label: "ثانويه مدارس التقوى الاسلاميه", code: "1" },
  { label: "نيد الضالع الثانوية", code: "1" },
  { label: "علي بن ابي طالب", code: "1" },
  { label: "ابن رشد بالحجرة", code: "1" },
  { label: "مدارس طيبه الاهليه", code: "1" },
  { label: "المبرز الثانوية", code: "1" },
  { label: "الثانويه الثالثه", code: "1" },
  { label: "ثانويه العيد ابي", code: "1" },
  { label: "المدرسة السعودية في مدريد", code: "11" },
  { label: "مهد العالمية", code: "17" },
  { label: "اليمامه الاهليه", code: "1" },
  { label: "ثانوية الابناء الاولى", code: "1" },
  { label: "ثانوية الابناء باسكان البحريه", code: "1" },
  { label: "ثانوية الحلوه", code: "1" },
  { label: "ثانوية الفيصليه", code: "1" },
  { label: "ثانوية دخنه", code: "1" },
  { label: "قرطبه الاهليه", code: "1" },
  { label: "مدارس الابناء", code: "1" },
  { label: "مدارس الحضاره الاهليه", code: "1" },
  { label: "مدارس النبلاء الاهليه", code: "1" },
  { label: "واحة التربيه الحديثه", code: "1" },
  { label: "معادلة/lansdown college", code: "10" },
  { label: "الثانوية الثامنة والعشرون", code: "1" },
  { label: "الثانوية الثامنةوالتسعون", code: "1" },
  { label: "الثانويه الاولى بالمزاحميه", code: "1" },
  { label: "الثانويه التاسعه والعشرون", code: "1" },
  { label: "الثانويه الثالثه بصبيا", code: "1" },
  { label: "الثانويه الثالثه بعد المائه", code: "1" },
  { label: "الثانويه الثالثه والسبعون لتعليم الكبيرات", code: "1" },
  { label: "الثانويه الثامنه عشر بعد المائه", code: "1" },
  { label: "الثانويه الثامنه والستون", code: "1" },
  { label: "الثانويه الحاديه والثلاثون", code: "1" },
  { label: "الثانويه الحاديه والسبعون", code: "1" },
  { label: "الثانويه الخامسه والخمسون", code: "1" },
  { label: "الثانويه الرابعه عشر بعد المائه", code: "1" },
  { label: "الثانويه الرابعه عشرة", code: "1" },
  { label: "الثانويه السادسه", code: "1" },
  { label: "الزيادي النوذجيه الاهليه", code: "1" },
  { label: "ثانوية بسمة النجاح الاهليه", code: "1" },
  { label: "مدارس التنوير الاهليه", code: "1" },
  { label: "ثانوية نهج التميز الأهلية", code: "1" },
  { label: "الثانوية الثانية عشر", code: "1" },
  { label: "نجران (مقررات)", code: "1" },
  { label: "مدارس الرقي الاهليه", code: "1" },
  { label: "الثانوية الثالثة و الخمسين بعد المائة", code: "1" },
  { label: "شروق المعرفة", code: "1" },
  { label: "القدس", code: "1" },
  { label: "مدارس القبس الاهلية", code: "1" },
  { label: "مدارس النخبة الاهلية", code: "1" },
  { label: "مدارس النخبه الاهليه فرع المصيف", code: "1" },
  { label: "مدارس النهاج الاهلية", code: "1" },
  { label: "مدارس واحة التربية الحديثة", code: "1" },
  { label: "الثانويه الثالثه بالدمام", code: "1" },
  { label: "ثانوية الابناء بالاسطول الشرقي بالجبيل", code: "1" },
  { label: "ثانوية تربية الاجيال", code: "1" },
  { label: "الثانويه الاولى بخيبر", code: "1" },
  { label: "الثانوية الثالثة", code: "1" },
  { label: "الرابعه بصامطه", code: "1" },
  { label: "ثانوية المخرم", code: "1" },
  { label: "ثانوية الحليله الأولى", code: "1" },
  { label: "ثانوية الإخاء", code: "1" },
  { label: "مدارس رياض الفيحاء بالجبيل", code: "1" },
  { label: "ثانوية السابعة عشر بعد المائة", code: "1" },
  { label: "مدرسة المعارف", code: "1" },
  { label: "مدارس الجبيل الخاصة-ثانوي", code: "1" },
  { label: "الثانوية الثالثة والعشرون", code: "1" },
  { label: "الثانوية التاسعة", code: "1" },
  { label: "الثانوية الثالثة بالقطيف", code: "1" },
  { label: "ثانوية السلمية بالخرج", code: "1" },
  { label: "ثانوية الحاير", code: "1" },
  { label: "مدارس التربية الأهلية", code: "1" },
  { label: "الثانوية التاسعة والعشرون بعد المائة", code: "1" },
  {
    label: "المدرسة السعودية الثانوية في المملكة المتحدة وايرلندا",
    code: "10",
  },
  { label: "ثانوية دار المعارف", code: "1" },
  { label: "ثانوية العروة الوثقى الأهلية", code: "1" },
  { label: "ثانوية التربية النموذجية", code: "1" },
  { label: "ثانوية دار السلام الاهلية", code: "1" },
  { label: "الثانوية الحادية عشر", code: "1" },
  { label: "الثانوية الثالثة والثلاثون", code: "1" },
  { label: "مدارس الفيصلية الحديثة الأهلية", code: "1" },
  { label: "الثانوية الثامنة والستون", code: "1" },
  { label: "الثانوية الثانية بصفوى", code: "1" },
  { label: "ثانوية قوات الامن الخاصة بالعيينة", code: "1" },
  { label: "ثانوية الستون مقررات", code: "1" },
  { label: "ثانوية الحادي عشر بعد المائة", code: "1" },
  { label: "الثانوية العاشرة للمقررات", code: "1" },
  { label: "الثانوية السابعة عشر", code: "1" },
  { label: "ثانوية الفهد الاهلية", code: "1" },
  { label: "الثانوية الحادية والثلاثون بعد المائة", code: "1" },
  { label: "الثانوية الحادية والعشرون", code: "1" },
  { label: "ثانوية التعاون الأهلية", code: "1" },
  { label: "الثانوية الثانية بالجبيل", code: "1" },
  { label: "ثانوية العيينة", code: "1" },
  { label: "الثانوية الثالثة والخمسون", code: "1" },
  { label: "الثانوية الاولى بالعوامية", code: "1" },
  { label: "ثانوية الأثير الأهلية", code: "1" },
  { label: "الثانوية السابعة و الثمانون", code: "1" },
  { label: "ثانوية نجد الأهلية", code: "1" },
  { label: "ثانوية المنهل الأهلية", code: "1" },
  { label: "ثانوية الامام الشافعي الأهلية", code: "1" },
  { label: "ثانوية منارات الرياض الأهلية", code: "1" },
  { label: "الثانوية الثامنة عشر بعد المائة", code: "1" },
  { label: "ثانوية العبير الأهلية", code: "1" },
  { label: "الثانوية الرابعة والسبعون", code: "1" },
  { label: "ثانوية الجيل الأهلية", code: "1" },
  { label: "المدرسة المتحدة العالمية", code: "1" },
  { label: "المدرسة التاسعة", code: "1" },
  { label: "مدارس الفتح الأهلية", code: "1" },
  { label: "الثانوية الحادي والستون بعد المائة", code: "1" },
  { label: "الثانوية الثانية بالهفوف", code: "1" },
  { label: "ثانوية الوسطى الأهلية", code: "1" },
  { label: "مدارس الانجال الاهلية", code: "1" },
  { label: "الثانوية الاولى بالمنصورة", code: "1" },
  { label: "الثانوية الرابعة والاربعون", code: "1" },
  { label: "الثانوية التاسعة بعد المائة", code: "1" },
  { label: "الثانوية السادسة والثمانون", code: "1" },
  { label: "ثانوية الوسط الأهلية", code: "1" },
  { label: "ثانوية قاعدة الامير سلطان الجوية بالخرج", code: "1" },
  { label: "دار المعرفة الاهلية", code: "1" },
  { label: "ثانوية الشمس الأهلية", code: "1" },
  { label: "ثانوية الاجيال الاهلية", code: "1" },
  { label: "الثانوية الحادية و الثمانون", code: "1" },
  { label: "ثانوية الاركان الاهلية", code: "1" },
  { label: "ثانوية العروبة الاهلية", code: "1" },
  { label: "الثانوية الثامنة بالقطيف", code: "1" },
  { label: "ثانوية النخبة الاهلية بالخرج", code: "1" },
  { label: "الاقبال العالمية", code: "1" },
  { label: "مدارس الرسالة الأهلية", code: "1" },
  { label: "مدارس الأبرار الأهلية", code: "1" },
  { label: "ثانوية الجيل الصالح الأهلية", code: "1" },
  { label: "الثانوية التاسعة والثمانون", code: "1" },
  { label: "ثانوية رائدة السلام الأهلية", code: "1" },
  { label: "مدارس شبه الجزيرة الأهلية المحدودة", code: "1" },
  { label: "مدارس رياض الهدى الأهلية بالجبيل", code: "1" },
  { label: "الثانوية الثانية والعشرون بالدمام", code: "1" },
  { label: "ثانوية صقر الجزيرة", code: "1" },
  { label: "الثانوية التاسعة والستون", code: "1" },
  { label: "مدارس البيارق الأهلية", code: "1" },
  { label: "ثانوية أسراري الأهلية", code: "1" },
  { label: "الثانوية الأولى بالقارة", code: "1" },
  { label: "الثانوية السابعة والعشرون", code: "1" },
  { label: "أم مبشر الأنصارية الثانوية للبنات", code: "6" },
  { label: "مدارس السعودية الأهلية", code: "1" },
  { label: "عرفات الثانوية", code: "1" },
  { label: "مدارس العقيق الاهلية", code: "1" },
  { label: "مدرسة الرفاع الغربي الثانوية للبنات", code: "32" },
  { label: "ثانوية بنات قطنا الجديدة", code: "3" },
  { label: "ثانوية سعد بن أبي وقاس", code: "1" },
  {
    label: "western canada highschool",
    arabic_label: "western canada highschool",
    code: "12",
  },
  { label: "ثانوية البيروني الليلية", code: "1" },
  { label: "مدارس رياض نجد الأهلية-مقررات", code: "1" },
  { label: "ثانوية الأبناء الأولى بنات", code: "1" },
  { label: "مدارس الريادة العالمية", code: "1" },
  { label: "الثانوية الثانية بالمدينة", code: "1" },
  { label: "الثانوية الثانية عشر بالهفوف", code: "1" },
  { label: "الثانوية السابعة بعد المائة 107-مقررات", code: "1" },
  { label: "ثانوية اليعقوبي", code: "1" },
  { label: "ثانوية الفلاح الأهلية", code: "1" },
  { label: "ثانوية البيان الأهلية", code: "1" },
  { label: "مدارس الملك عبد العزيز الأهلية بالخبر", code: "1" },
  { label: "الثانوية التاسعة والسبعون", code: "1" },
  { label: "كريستيانزبورج", arabic_label: "Christiansburg", code: "16" },
  { label: "ثانوية الزهراوي", code: "1" },
  { label: "الحصان النموذجية الأهلية بالجبيل", code: "1" },
  { label: "أم الدوم الثانوية", code: "1" },
  { label: "مدرسة البجادية", code: "1" },
  { label: "أما الدولية البحرين", code: "32" },
  { label: "ثانوية رائدات المستقبل", code: "1" },
  { label: "الثانوية السابعة و التسعون", code: "1" },
  { label: "ديوان النهضة الثانوية الأهلية", code: "1" },
  { label: "ثانوية الأمير عبدالاله بن عبدالعزيز", code: "1" },
  { label: "الوسام الثانوية الأهلية", code: "1" },
  { label: "ابن منظور الثانوية", code: "1" },
  { label: "ثانوية الأندلس", code: "1" },
  { label: "مدارس السلام", code: "1" },
  { label: "الثانوية السادسة", code: "1" },
  { label: "الثانوية السابعة و الستون", code: "1" },
  { label: "الثانوية الثالثة و الستون", code: "1" },
  { label: "الجبيل الأهلية", code: "1" },
  { label: "الثانوية السابعة", code: "1" },
  { label: "الثانوية الثامنة و الثمانون", code: "1" },
  { label: "الثانوية الأولى", code: "1" },
  { label: "مدارس عبد العزيز العالمية", code: "1" },
  { label: "الثانوية التاسعة عشر", code: "1" },
  { label: "أم القرى", code: "1" },
  { label: "مجمع الامير سعود بن نايف التعليمي", code: "1" },
  { label: "مدرسة يارا العالمية", code: "1" },
  { label: "ثانوية المنتزه", code: "1" },
  { label: "ثانوية في دبي", code: "42" },
  { label: "ثانوية في أبوظبي", code: "42" },
  {
    label: "مدارس الشرق الأوسط العالمية",
    arabic_label: "Middle East International Schools",
    code: "1",
  },
  { label: "ثانوية الواحة الأهلية", code: "1" },
  { label: "ثانوية الإمام البزار", code: "1" },
  { label: "مزهرة الثانوية", code: "1" },
  { label: "الثانوية الثانية بصامطه", code: "1" },
  { label: "الثانوية الثامنة والأربعون", code: "1" },
  { label: "الثانوية الثالثة والعشرون بعد المائة", code: "1" },
  { label: "ثانوية الزهراء", code: "1" },
  { label: "الثانوية الخامسة بالجبيل", code: "1" },
  { label: "الثانوية الحادية والعشرون بتبوك", code: "1" },
  { label: "ثانوية الجامعة الأهلية", code: "1" },
  { label: "الثانوية الحادية والعشرون بالمدينة", code: "1" },
  { label: "الثانوية التاسعة عشر بعد المائة", code: "1" },
  { label: "خولة بنت ثعلبة الثانوية", code: "1" },
  { label: "ثانوية المجد الأهلية", code: "1" },
  { label: "الثانوية السابعة و العشرون بعد المائة", code: "1" },
  { label: "الثانوية الخامسة والثلاثون", code: "1" },
  { label: "مدارس الملك عبد العزيز الأهلية للبنات", code: "1" },
  { label: "الثانوية الثالثة عشر بالهفوف مقررات", code: "1" },
  { label: "ثانوية العز بن عبد السلام", code: "1" },
  { label: "الثانوية الخامسة بمكة المكرمة", code: "1" },
  { label: "مدارس الحكمة الأهلية", code: "1" },
  { label: "غير محدد", code: "6" },
  { label: "الثانوية الثالثة والأربعون بعد المائة", code: "1" },
  { label: "الثانوية الرابعة بينبع البحر ( نظام مقررات )", code: "1" },
  { label: "الثانوية الرابعة والخمسون", code: "1" },
  { label: "ثانوية الاندلس الاهلية", code: "1" },
  { label: "ثانوية الأجيال الاهلية", code: "1" },
  { label: "ثانوية عبدالله بن جعفر", code: "1" },
  { label: "ثانوية الثغر النموذجية الأهلية", code: "1" },
  { label: "الثانوية الأولى ببقيق", code: "1" },
  { label: "ثانوية اجيال اليمامة الأهلية", code: "1" },
  { label: "ثانوية التربية الاسلامية الأهلية - مقررات", code: "1" },
  { label: "الثانوية العاشرة", code: "1" },
  { label: "ثانوية دار القلم الأهلية", code: "1" },
  { label: "الثانوية السابعة عشر بالدمام", code: "1" },
  { label: "الثانوية الخامسة و الثلاثون بالمدينة", code: "1" },
  { label: "مدارس دار البيان الأهلية", code: "1" },
  { label: "يوسف العذبي الصباح الثانوية للبنين", code: "6" },
  { label: "مدارس طلائع الغد النموجية الأهلية", code: "1" },
  { label: "ثانوية ال دحمان", code: "1" },
  { label: "ثانوية الملك فهد", code: "1" },
  { label: "الثانوية الأولى بالظهران", code: "1" },
  { label: "الثانوية الحادية و الثلاثون بالدمام", code: "1" },
  { label: "ثانوية الأمير بندر بن عبدالعزيز", code: "1" },
  { label: "مدارس الجامعة الأهلية للبنات", code: "1" },
  { label: "الثانوية الثالثة بنات بطريف", code: "1" },
  { label: "الثانوية الأولى بالعوامية", code: "1" },
  { label: "الثانوية السادسة عشر", code: "1" },
  { label: "الثانوية الثامنة عشر بحفر الباطن", code: "1" },
  { label: "الثانوية الثامنة و السبعون", code: "1" },
  { label: "مدارس مداد البيان الأهلية", code: "1" },
  { label: "ثانوية حفصة بنت عمر", code: "1" },
  { label: "ثانوية الحسينية", code: "1" },
  { label: "الأمير محمد بن نايف الثانوية", code: "1" },
  { label: "ثانوية جيل التربية الاهلية", code: "1" },
  { label: "الأكاديمة الاسلامية السعودية", code: "16" },
  { label: "ثانوية بسيطا الأهلية", code: "1" },
  { label: "ثانوية النخبة النموذجية", code: "1" },
  { label: "ثانوية الفضيلة الأهلية", code: "1" },
  { label: "الثانوية السادسة و العشرون بعد المائة", code: "1" },
  { label: "الثانوية الأولى ببجر ابو سكينة للبنات", code: "1" },
  { label: "ثانوية أم سلمة بالحرس الوطني", code: "1" },
  { label: "الثانوية السادسة و السبعون", code: "1" },
  { label: "ثانوية الحوية الخامسة", code: "1" },
  { label: "ثانوية اسبطر", code: "1" },
  { label: "ثانوية الحفاير", code: "1" },
  { label: "ثانوية الحقو", code: "1" },
  { label: "ثانوية آفاق السويدي الأهلية للبنات", code: "1" },
  { label: "ثانوية غامد الزناد", code: "1" },
  { label: "الثانوية الثالثة و الثلاثون بعد المائة 133", code: "1" },
  { label: "الثانوية الحادية و العشرون بعد المائة", code: "1" },
  { label: "ثانوية دمشق العربية الخاصة", code: "3" },
  { label: "مدرسة كريمة الثانوية للبنين", code: "2" },
  { label: "معاذ بن جبل بالعظة", code: "1" },
  { label: "الأمير عبدالله بن عبدالعزيز", code: "1" },
  { label: "ثانوية التعلم النموذجية الأهلية", code: "1" },
  { label: "مجمع الأمير سلطان الثانوي", code: "1" },
  { label: "ثانوية ابن تيمية", code: "1" },
  { label: "عروى", code: "1" },
  { label: "ثانوية الضبية", code: "1" },
  { label: "المنجارة", code: "1" },
  { label: "الخليل بن أحمد الاهلية الثانوية", code: "1" },
  { label: "محمد الفاتح", code: "1" },
  { label: "قمم الحياه العالمية", code: "1" },
  { label: "الثانوية السادسة و العشرون بالدمام", code: "1" },
  { label: "الثانوية الخامسة بسيهات", code: "1" },
  { label: "الثانوية السادسة بالقطيف", code: "1" },
  { label: "ثانوية الأفق الأهلية", code: "1" },
  { label: "المدرسة السعودية في باريس", code: "22" },
  { label: "الثانوية الثانية بالمبرزمقررات", code: "1" },
  { label: "الثانوية التسعون", code: "1" },
  { label: "صبحاء", code: "1" },
  { label: "ثانوية الهدى الأهلية", code: "1" },
  { label: "الثانوية السابعة و الثلاثون", code: "1" },
  { label: "الثانوية الثانية بالدرعية", code: "1" },
  { label: "مدارس دار الصفوة الأهلية", code: "1" },
  { label: "الثانوية الرابعة بعد المائة", code: "1" },
  { label: "الثانوية الرابعة بالقطيف", code: "1" },
  { label: "ثانوية الأبناء الثانية", code: "1" },
  { label: "مدارس المنارات الأهلية الثانوية", code: "1" },
  { label: "ثانوية نيار الأهلية", code: "1" },
  { label: "ثانوية الرواد الأهلية", code: "1" },
  { label: "الثانوية الثالثة بعفيف", code: "1" },
  { label: "الثانوية الثانية و العشرون", code: "1" },
  { label: "ثانوية الشافي الأهلية بالطائف", code: "1" },
  { label: "مدارس الشعلة الأهلية", code: "1" },
  { label: "ثانوية الرويضة", code: "1" },
  { label: "ثانوية الأمير عبدالإله بن عبدالعزيز", code: "1" },
  { label: "ثانوية القاسم بن محمد", code: "1" },
  { label: "الثانوية السادسة والاربعون بعد المائة", code: "1" },
  { label: "ثانوية رواد الحضارة الأهلية", code: "1" },
  { label: "ثانوية صروح العلم و التربية الأهلية بالطائف", code: "1" },
  { label: "مدارس طويق", code: "1" },
  { label: "الثانوية السابعة و الخمسون", code: "1" },
  { label: "مدارس النخبة الأهلية", code: "1" },
  { label: "مدارس الملك عبد العزيز النموذجية", code: "1" },
  { label: "الثانوية الثالثة بالمبرز مقررات", code: "1" },
  { label: "مدارس نخبة نجد الأهلية", code: "1" },
  { label: "الثانوية السابعة بالدمام", code: "1" },
  { label: "ثانوية العصرالنموذجية الأهلية-نظام المقررات بتبوك", code: "1" },
  { label: "ثانوية الأندلس الأهلية", code: "1" },
  { label: "ثانوية الظهران", code: "1" },
  { label: "ثانوية الإمام علي بن أبي طالب", code: "1" },
  { label: "الثانوية الثانية", code: "1" },
  { label: "مدارس نور المعارف العالمية", code: "1" },
  { label: "الثانوية الثلاثون بعد المائة 130-مقررات", code: "1" },
  { label: "الثانوية الرابعة بتبوك", code: "1" },
  { label: "ثانوية المطعن", code: "1" },
  { label: "الثانوية السابعة والعشرون بالدمام", code: "1" },
  { label: "ثانوية الخاصرة", code: "1" },
  { label: "حفر الباطن الثانوية", code: "1" },
  { label: "ثانوية اليمامة الأهلية", code: "1" },
  { label: "مدارس رياض نجد الأهلية", code: "1" },
  { label: "الثانوية السادسة", code: "1" },
  { label: "ثانوية التربية النموذجية الأهلية( الروابي )", code: "1" },
  { label: "ثانوية بن شريم", code: "1" },
  { label: "ثانوية رواد النجاح الأهلية", code: "1" },
  { label: "الثانوية الرابعة والعشرون", code: "1" },
  { label: "مدارس قمم الحياة العالمية", code: "1" },
  { label: "الثانوية الخامسة بخميس مشيط", code: "1" },
  { label: "ثانوية العناية الأهلية", code: "1" },
  { label: "الثانوية الواحد والخمسون بعد المائة", code: "1" },
  { label: "مدرسة أم القرى", code: "1" },
  { label: "عمر بن الخطاب", code: "1" },
  { label: "ثانوية عمر بن العاص", code: "1" },
  { label: "ثانوية حي السفارات", code: "1" },
  { label: "ثانوية الامام احمد بن حنبل", code: "1" },
  { label: "الثانوية الأولى بالظهران ( مقررات)", code: "1" },
  { label: "ثانوية يزيد بن معاوية", code: "1" },
  { label: "آفاق التعليم الأهلية", code: "1" },
  { label: "ثانوية مجمع الملك عبدالله بن عبدالعزيز", code: "1" },
  { label: "دار الحكمة العالمية", code: "1" },
  { label: "ابن البيطار", code: "1" },
  { label: "مدارس الصفوة الأهلية بالمدينة", code: "1" },
  { label: "ثانوية الامام الألوسي", code: "1" },
  { label: "ثانوية أشيقر", code: "1" },
  { label: "كعب بن ربيعة", code: "1" },
  { label: "ثانوية مناهل الجامعة الاهلية", code: "1" },
  { label: "ثانوية البديع", code: "1" },
  { label: "ثانوية المدينة المنورة - المقررات", code: "1" },
  { label: "الاحنف بن قيس", code: "1" },
  { label: "مدارس الحصان النموذجية الاهلية بالدمام", code: "1" },
  { label: "مدرسة الملك عبدالعزيز", code: "1" },
  { label: "الثانوية الثالثة والثمانون", code: "1" },
  { label: "مدارس منارات ينبع الأهلية", code: "1" },
  { label: "الثانوية التاسعة والخمسون", code: "1" },
  { label: "شروق المعرفة الاهلية", code: "1" },
  { label: "الثانوية الرابعة بعفيف", code: "1" },
  { label: "الثانوية التاسعة والثلاثون", code: "1" },
  { label: "ثانوية المزاحمية الأهلية", code: "1" },
  { label: "سكستش اكاديمي", code: "16" },
  { label: "الدليمية", code: "1" },
  { label: "ثانوية 140 بنات", code: "1" },
  { label: "ثانوية الرايه الأهلية", code: "1" },
  { label: "ثانوية الحصاد الأهلية", code: "1" },
  { label: "مدارس المعتز بالله الأهلية", code: "1" },
  { label: "الثانوية الخامسة بالمدينة", code: "1" },
  { label: "الثانوية المائة", code: "1" },
  { label: "الثانوية الثانية والثلاثون بعد المائة", code: "1" },
  { label: "مدارس السلام الخاصة بالخبر", code: "1" },
  { label: "الشيحية", code: "1" },
  { label: "مدارس دلتا العالمية", code: "1" },
  { label: "ثانوية مريم بنت عمران بالخفجي", code: "1" },
  { label: "ثانوية الرياض الأهلية-مقررات", code: "1" },
  { label: "ثانوية الشبيلي", code: "1" },
  { label: "مدارس التربية الإسلامية بحائل", code: "1" },
  { label: "ثانوية الشيخ عبدالرحمن بن سعدي", code: "1" },
  { label: "ثانوية رواد الجزيرة الأهلية", code: "1" },
  { label: "ثانوية الإبداع الأهلية", code: "1" },
  { label: "ثانوية محمد بن ابراهيم", code: "1" },
  { label: "الريان الاهلية", code: "1" },
  { label: "منارات القصيم", code: "1" },
  { label: "ثانوية نخبة الغد الاهلية بعفيف", code: "1" },
  { label: "ثانوية نمرة بالقنفذة", code: "1" },
  { label: "الثانوية الثانية بالتقبه", code: "1" },
  { label: "الثانوية الثامنة عشر بالدمام", code: "1" },
  { label: "اكاديمية الملك فهد", code: "10" },
  { label: "ثانوية الأقصى الاهلية", code: "1" },
  { label: "الثانوية الرابعة و الثلاثون", code: "1" },
  { label: "ثانوية الابناء الأولى", code: "1" },
  { label: "ثانوية الملك عبدالله", code: "1" },
  { label: "مدارس العروبة العالمية", code: "1" },
  { label: "ثانوية الجودة الاهلية", code: "1" },
  { label: "ثانوية الصفا الأهلية", code: "1" },
  { label: "ثانوية عنيزة العامة", code: "1" },
  { label: "التعاون الأهلية", code: "1" },
  { label: "ثانوية البيروني مقررات", code: "1" },
  { label: "الثانوية الأولى بالشعبة مقررات", code: "1" },
  { label: "نور الغد الأهلية الثانوية بحفر الباطن", code: "1" },
  { label: "ثانوية عبدالله بن ابي السرح", code: "1" },
  { label: "مدارس الفيصلية الإسلامية بالخبر", code: "1" },
  { label: "مدارس الفارس العالمية", code: "1" },
  { label: "الثانوية الحادية والتسعون", code: "1" },
  { label: "الثانوية الأولى بالقطيف", code: "1" },
  { label: "ثانوية مالك بن دينار", code: "1" },
  { label: "الثانوية الخامسة و العشرون بالدمام", code: "1" },
  { label: "الثانوية الخامسة بحفر الباطن", code: "1" },
  { label: "الثانوية التاسعة والأربعون بعد المائة 149", code: "1" },
  { label: "الثانوية الثالثة (مقررات ) للبنات بدومة الجندل", code: "1" },
  { label: "مدارس العلا العربية الأهلية", code: "1" },
  { label: "ثانوية الكفاح الأهلية عام", code: "1" },
  { label: "ثانوية نهاوند", code: "1" },
  {
    label: "كريستن بيرج",
    arabic_label: "Christiansburg High School",
    code: "16",
  },
  { label: "الثانوية النموذجية الأهلية", code: "1" },
  { label: "الثانوية الثامنة والثلاثون بمكة المكرمة", code: "1" },
  { label: "الثانوية الثانية و التسعون", code: "1" },
  { label: "ابن حزم", code: "1" },
  {
    label: "مدارس المناهل الأهلية",
    arabic_label: "Almanahel Private Schools",
    code: "1",
  },
  { label: "الابناء الرابعة الثانوية", code: "1" },
  { label: "وعلان", code: "1" },
  { label: "ثانوية مكة المكرمة - مقررات", code: "1" },
  { label: "الثانوية الحادية و السبعون", code: "1" },
  { label: "مدارس العلم النافع الأهلية بمكة المكرمة", code: "1" },
  { label: "الثانوية الثانية عشر بنجران", code: "1" },
  { label: "الثانوية التاسعة والأربعون", code: "1" },
  { label: "ثانوية الأمجاد الأولية", code: "1" },
  { label: "الثانوية الرابعة عشر", code: "1" },
  { label: "ثانوية شموس رفحاء", code: "1" },
  { label: "الثانوية الأولى بالعمران", code: "1" },
  { label: "ثانوية إسكان الكلابية", code: "1" },
  { label: "الدفي", code: "1" },
  { label: "الثانوية الخامسة بطريف", code: "1" },
  { label: "الامام سعود الكبير", code: "1" },
  { label: "ثانوية عامر بن فهيرة", code: "1" },
  { label: "الاوائل الأهلية", code: "1" },
  { label: "ثانوية الصفوه النموذجية الأهلية", code: "1" },
  { label: "مدارس طلائع القصيم الأهلية ببريدة", code: "1" },
  { label: "ثانوية مدارس جازان الأهلية للبنات", code: "1" },
  { label: "ثانوية مليح", code: "1" },
  { label: "مدارس الصحافة العالمية", code: "1" },
  { label: "الثانوية الأولى", code: "1" },
  { label: "ثانوية مأرز الإيمان", code: "1" },
  { label: "الثانوية السابعة", code: "1" },
  { label: "ثانوية الفهد مقررات", code: "1" },
  { label: "مدرسة النصر الأهلية", code: "1" },
  { label: "مجمع الأمير متعب بن عبدالعزيز", code: "1" },
  { label: "ثانوية المقريزي", code: "1" },
  { label: "ثانوية رياض الفكر الأهلية", code: "1" },
  { label: "الثانوية الخامسة برفحاء", code: "1" },
  { label: "الرسالة النموذجية الأهلية الثانوية", code: "1" },
  { label: "ثانوية التضامن الإسلامية الأهلية", code: "1" },
  { label: "الملك سعود بالحرس الوطني", code: "1" },
  { label: "ثانوية الثانية", code: "1" },
  { label: "ثانوية مبايض", code: "1" },
  { label: "ثانوية الملك سلمان بن عبدالعزيز", code: "1" },
  { label: "ثانوية واحة التربية الحديثة الأهلية", code: "1" },
  { label: "ثانوية معالم الصفوة الأهلية", code: "1" },
  { label: "الثانوية الثانية و الثمانون", code: "1" },
  { label: "الثانوية الخامسة", code: "1" },
  { label: "طلائع الفكر الأهلية", code: "1" },
  { label: "مدرسة إقراء الثانوية", code: "17" },
  { label: "جريبة", code: "1" },
  { label: "مدارس المعرفة الأهليه", code: "1" },
  { label: "الثانوية الثانية بصفوى", code: "1" },
  { label: "ثانوية النيصية - مقررات", code: "1" },
  { label: "ثانوية أمجاد قرطبة الأهلية", code: "1" },
  { label: "ثانوية الحريق - نظام المقررات", code: "1" },
  { label: "ثانوية أبناء الرياض الاهلية", code: "1" },
  { label: "المتنبي", code: "1" },
  { label: "العلم العالمية", code: "1" },
  { label: "محيرقة", code: "1" },
  { label: "مدارس النور العالمية", code: "1" },
  { label: "مونتيري هاي سكوول", code: "16" },
  {
    label: "المدرسة الهندية العالمية",
    arabic_label: "International indian school",
    code: "1",
  },
  { label: "الضاحية الأهلية", code: "1" },
  { label: "الانجال الأهلية", code: "1" },
  { label: "القاسم بن محمد بن أبي بكر", code: "1" },
  { label: "ثانوية ثمامة بن اثال", code: "1" },
  { label: "ثانوية الأمير منصور بن عبدالعزيز", code: "1" },
  { label: "ذات الصواري", code: "1" },
  { label: "ثانوية جعفر بن محمد", code: "1" },
  { label: "الجوهري", code: "1" },
  { label: "مدارس الإرتقاء العالمية", code: "1" },
  { label: "ثانوية ابن خلدون", code: "1" },
  { label: "مدارس رياض التربية الحديثة الأهلية", code: "1" },
  { label: "ثانوية علماء الغد الأهلية", code: "1" },
  { label: "مدارس الملك عبد العزيز النموذجية", code: "1" },
  { label: "ثانوية التربية النموذجية( الروابي)", code: "1" },
  { label: "مدارس جواثا الأهلية", code: "1" },
  { label: "الثانوية الثامنة بالخرج", code: "1" },
  { label: "الثانوية الأولى بينبع الصناعية", code: "1" },
  { label: "الثانوية السادسة عشر بالهفوف", code: "1" },
  { label: "الثانوية الثانية عشر بعد المائة", code: "1" },
  { label: "الثانوية الخامسة بالقطيف", code: "1" },
  { label: "الثانوية الأولى بضمد", code: "1" },
  { label: "الثانوية السادسة", code: "1" },
  { label: "ثانوية منار السبيل الأهلية", code: "1" },
  { label: "الثانوية العاشرة بعد المائة", code: "1" },
  { label: "ثانوية السليل الثانية", code: "1" },
  { label: "الحباب بن المنذر", code: "1" },
  { label: "ابن خلدون الثانوية", code: "1" },
  { label: "ثانوية عبد العزيز بن مروان", code: "1" },
  { label: "مدارس الظهران الأهلية", code: "1" },
  { label: "ثانوية الملك فهد للتطوير", code: "1" },
  { label: "الثانوية الثالثة والثلاثون", code: "1" },
  { label: "الثانوية السابعة والأربعون", code: "1" },
  { label: "الثانوية الأولى بأم الحمام", code: "1" },
  { label: "الثانوية الثانية بالخبر", code: "1" },
  { label: "الثانوية الأولى بالرميلة", code: "1" },
  { label: "ثانوية الشروق الأهلية", code: "1" },
  { label: "ثانوية بنات الغد الأهلية بعرعر", code: "1" },
  { label: "الثانوية الأولى بوادي بن هشبل", code: "1" },
  { label: "ثانوية الأرقم الأهلية", code: "1" },
  { label: "حطين الثانوية", code: "1" },
  { label: "ثانوية دار القلم الأهلية", code: "1" },
  { label: "ثانويه الانجال الاهليه", code: "1" },
  { label: "ثانوية التربية النموذجية الأهلية - مقررات", code: "1" },
  { label: "ثانوية قرطبة الأهلية", code: "1" },
  { label: "ثانوية القويعية", code: "1" },
  { label: "ثانوية القادة الأهلية", code: "1" },
  { label: "تبوك النموذجية الأهلية", code: "1" },
  { label: "ثانوية ابن خلدون الأهلية (النفل ) مقررات", code: "1" },
  { label: "ابن سينا", code: "1" },
  { label: "ثانوية المدرسة الأهلية - مقررات", code: "1" },
  { label: "المدرسة السعودية في كراتشي", code: "21" },
  { label: "ثانوية الزهراء بجدة", code: "1" },
  { label: "ثانوية الملك فهد", code: "1" },
  { label: "الثانوية الرابعة", code: "1" },
  { label: "ثانوية الثالثة عشر بعد المائة", code: "1" },
  { label: "الثانوية الثامنة والثلاثون38-مقررات", code: "1" },
  { label: "الثانوية الثانية و الستون بعد المائة 162", code: "1" },
  { label: "ثانوية الحازمي", code: "1" },
  { label: "صلاح الدين الأييوبي الثانوية", code: "1" },
  { label: "المدرسة الإثيوبية العالمية", code: "1" },
  { label: "الثانوية الحادية و الأربعون بعد المائة", code: "1" },
  { label: "الأوائل الأهلية الثانوية", code: "1" },
  { label: "الثانوية الثامنة و الخمسون بعد المائة", code: "1" },
  { label: "ثانوية الشيخ محمد العثيمين", code: "1" },
  { label: "ثانوية شعاع المعرفة الأهلية", code: "1" },
  { label: "ثانوية اشبيلية", code: "1" },
  { label: "ثانوية عائشة ام المؤمنين رضي الله عنها", code: "1" },
  { label: "الأهلية الحديثة", code: "1" },
  { label: "مدارس ابن خلدون العالمية", code: "1" },
  { label: "مدارس منار الشمس العالمية", code: "1" },
  { label: "ثانوية مدارس رياض الإحسان الأهلية للبنات بالدمام", code: "1" },
  { label: "الوليد بن عبدالملك", code: "1" },
  { label: "الملك سعود", code: "1" },
  { label: "ثانوية أبها الاولى - مقررات", code: "1" },
  { label: "البشرى بالزاهر", code: "1" },
  { label: "العمران الثانوية", code: "1" },
  { label: "ثانوية الخشيبي", code: "1" },
  { label: "ابن رشد", code: "1" },
  { label: "ثانوية الدوحة السعودية الأهلية", code: "1" },
  { label: "ثانوية ذات الصواري", code: "1" },
  { label: "ثانوية الاحساء النموذجية الاهلية", code: "1" },
  { label: "العزيزية الأهلية", code: "1" },
  { label: "أسد بن فرات الثانوية", code: "1" },
  { label: "ثانوية صقلية", code: "1" },
  { label: "الفيضة", code: "1" },
  { label: "داورد الثانوية - مقررات", code: "1" },
  { label: "ثانوية سعيد بن زيد", code: "1" },
  { label: "حنين", code: "1" },
  { label: "ثانوية المدينة المنورة في بريدة", code: "1" },
  { label: "الحجيف", code: "1" },
  { label: "الفيصل بخميس مشيط", code: "1" },
  { label: "الفارابي", code: "1" },
  { label: "مدارس المنارات الأهلية الثانوية", code: "1" },
  { label: "ثانوية لبخة", code: "1" },
  { label: "الإمام السخاوي الثانوية", code: "1" },
  { label: "الثانوية السابعة والثلاثون بعد المائة", code: "1" },
  { label: "ثانوية المطورون الأهلية", code: "1" },
  { label: "ثانوية البدائع", code: "1" },
  { label: "الشيخ عبدالعزيز بن باز", code: "1" },
  { label: "زيد الخير الثانوية", code: "1" },
  { label: "مدارس النصر العالمية", code: "1" },
  { label: "ثانوية الغد الأهلية", code: "1" },
  { label: "مزهرة الثانوية", code: "1" },
  { label: "الحديثة الأهلية الثانوية", code: "1" },
  { label: "مدارس الاقبال العالمية الأهلية", code: "1" },
  { label: "الثانوية الثامنة بنجران", code: "1" },
  { label: "ثانوية الأمجاد الأهلية", code: "1" },
  { label: "الثانوية الثالثة و السبعون", code: "1" },
  { label: "ثانوية البتول الأهلية بمكة المكرمة", code: "1" },
  { label: "ثانوية التربية الأهلية-مقررات", code: "1" },
  { label: "ثانوية دور العلوم الأهلية", code: "1" },
  { label: "ثانوية الرفايع", code: "1" },
  { label: "خديجة بنت خويلد الثانوية بمكة المكرمة", code: "1" },
  { label: "ثانوية زهرات المستقبل الأهلية", code: "1" },
  { label: "ثانوية التربية السعودية الأهلية", code: "1" },
  { label: "ثانوية مجد العلوم الأهلية", code: "1" },
  { label: "ثانوية القريات الأهلية", code: "1" },
  { label: "الثانوية السابعة والسبعون", code: "1" },
  { label: "محمد عبدالله المهيني الثانوية للبنين", code: "6" },
  { label: "ثانوية أجيال الحضارة الأهلية", code: "1" },
  { label: "طارق بن زياد الثانوية", code: "1" },
  { label: "التربية الرقمية الأهلية", code: "1" },
  { label: "الثانوية الثامنة عشر", code: "1" },
  { label: "ثانوية الثندوة", code: "1" },
  { label: "الثانوية الثانية عشر", code: "1" },
  { label: "الثانوية الرابعة والعشرون", code: "1" },
  { label: "الثانوية المائة و ثمانية", code: "1" },
  { label: "الثانوية العشرون", code: "1" },
  { label: "الثانوية الثامنة", code: "1" },
  { label: "الهندية العالمية الأولى بالرياض", code: "1" },
  { label: "سعد بن عبادة الثانوية", code: "1" },
  { label: "الجزيرة", code: "1" },
  { label: "ثانوية الصرح الأهلية", code: "1" },
  { label: "المدارس البنجلاديشية العالمية", code: "1" },
  { label: "العرباض بن سارية", code: "1" },
  { label: "برشير هاي سكول", code: "16" },
  { label: "ثانوية التميز التربوي الأهلية", code: "1" },
  { label: "مجمع الثغر النموذجي بالخالدية", code: "1" },
  { label: "هوازن الثانوية", code: "1" },
  { label: "ثانوية العلم و الإيمان بتبوك", code: "1" },
  { label: "مدارس مدينة العلوم العالمية", code: "1" },
  { label: "مدارس السنابل الأهلية", code: "1" },
  { label: "ثانوية الاخلاص الاهلية", code: "1" },
  { label: "مدارس أنجال القصيم الأهلية", code: "1" },
  { label: "الريان", code: "1" },
  { label: "الملك فهد الثانوية", code: "1" },
  { label: "الإمام مسلم الثانوية (مقررات)", code: "1" },
  { label: "الجزيرة الثانوية - مقررات", code: "1" },
  { label: "عمر بن الخطاب بالظهارة", code: "1" },
  { label: "ابن باز", code: "1" },
  { label: "ثانوية الإمام عبدالعزيز بن محمد", code: "1" },
  { label: "ثانوية مرات", code: "1" },
  { label: "مدارس رواد الخليج الأهلية للبنات", code: "1" },
  { label: "الثانوية الثانية", code: "1" },
  { label: "الثانوية العاشرة", code: "1" },
  { label: "ثانوية الرواد الأهلية", code: "1" },
  { label: "سعيد بن زيد الثانوية", code: "1" },
  { label: "الثانوية الثامنة و العشرون بعد المائة", code: "1" },
  { label: "الثانوية الرابعة و التسعون", code: "1" },
  { label: "الثانوية الرابعة و الخمسون بعد المائة", code: "1" },
  { label: "بشائر الجزيرة الاهلية", code: "1" },
  { label: "ثانوية سهما الأهلية", code: "1" },
  { label: "عاصم بن عدي", code: "1" },
  { label: "النخبة النموذجية الثانوية الاهلية", code: "1" },
  { label: "ثانوية ابن سينا", code: "1" },
  { label: "ثانوية معالم التربية الأهلية", code: "1" },
  { label: "السراوات الأهلية", code: "1" },
  { label: "ثانوية آفاق السويدي الأهلية", code: "1" },
  { label: "ابن القيم", code: "1" },
  { label: "أبان بن سعيد", code: "1" },
  { label: "ثانوية العبدلية", code: "1" },
  { label: "الثانوية السابعة", code: "1" },
  { label: "دار العلوم مقررات", code: "1" },
  { label: "الشيخ محمد بن عثيمين", code: "1" },
  {
    label: "Iman Acdemy southwest",
    arabic_label: "Iman Acdemy southwest",
    code: "16",
  },
  { label: "الخط الأهلية", code: "1" },
  { label: "ثانوية معالم الصفوة الأهلية", code: "1" },
  { label: "ثانوية جابر بن عبدالله بالفوارة", code: "1" },
  { label: "ثانوية الهمجة", code: "1" },
  { label: "الإمام تركي بن عبدالله آل سعود الثانوية", code: "1" },
  { label: "عطي", code: "1" },
  { label: "الملك عبدالعزيز الثانوية ( مقررات )", code: "1" },
];
export const zones = [
  { arabic_label: "عمان", code: "2" },
  { arabic_label: "دمشق", code: "3" },
  { arabic_label: "حلب", code: "3" },
  { arabic_label: "القدس", code: "4" },
  { arabic_label: "غزة", code: "4" },
  { arabic_label: "كراتشي", code: "21" },
  { arabic_label: "البصرة", code: "5" },
  { arabic_label: "الموصل", code: "5" },
  { arabic_label: "صنعاء", code: "7" },
  { arabic_label: "القاهرة", code: "8" },
  { arabic_label: "الدار البيضاء", code: "9" },
  { arabic_label: "المملكة المتحدة", code: "10" },
  { arabic_label: "الهند", code: "13" },
  { arabic_label: "لبنان", code: "14" },
  { arabic_label: "قطر", code: "15" },
  { arabic_label: "الولايات المتحدة الأمريكية", code: "16" },
  { arabic_label: "الصومال", code: "17" },
  { arabic_label: "السودان", code: "18" },
  { arabic_label: "باكستان", label: "pakestan", code: "21" },
  { arabic_label: "كانبرا", code: "23" },
  { arabic_label: "افغانستان", code: "26" },
  { arabic_label: "باريس", code: "22" },
  { arabic_label: "عيسى", code: "32" },
  { arabic_label: "واشنطن", code: "16" },
  { arabic_label: "الاحساء", code: "1" },
  { arabic_label: "الافلاج", code: "1" },
  { arabic_label: "الحدود الشمالية", code: "1" },
  { arabic_label: "المنامة", code: "32" },
  { arabic_label: "الخرج", code: "1" },
  { arabic_label: "حماة", code: "3" },
  { arabic_label: "الرس", code: "1" },
  { arabic_label: "الرياض", label: "Riyadh", code: "1" },
  { arabic_label: "القريات", code: "1" },
  { arabic_label: "القصيم", code: "1" },
  { arabic_label: "اوهايو", code: "16" },
  { arabic_label: "المدينة المنورة", code: "1" },
  { arabic_label: "جازان", code: "1" },
  { arabic_label: "حفر الباطن", code: "1" },
  { arabic_label: "عسير", code: "1" },
  { arabic_label: "عنيزة", code: "1" },
  { arabic_label: "مكة المكرمة", code: "1" },
  { arabic_label: "نجران", code: "1" },
  { arabic_label: "مدريد", code: "11" },
  { arabic_label: "كندا", label: "Canada", code: "12" },
  { arabic_label: "المنطقة الوسطى", code: "17" },
  { arabic_label: "بغداد", code: "5" },
  { arabic_label: "الشرقية", code: "1" },
  { arabic_label: "أطلانطا", code: "16" },
  { arabic_label: "بلمان واشنطن", code: "16" },
  { arabic_label: "أوريغون", label: "oregon", code: "16" },
  { arabic_label: "الولايات المتحدة", code: "16" },
  { arabic_label: "مينابولس", code: "16" },
  { arabic_label: "بنغلاديش", code: "30" },
  { arabic_label: "مانيلا", code: "31" },
  { arabic_label: "كاليفورنيا", label: "California", code: "16" },
  { arabic_label: "بنسلفينيا", code: "16" },
  { arabic_label: "تونس", code: "33" },
  { arabic_label: "الباحة", code: "1" },
  { arabic_label: "عفيف", code: "1" },
  { arabic_label: "الجوف", code: "1" },
  { arabic_label: "الطائف", code: "1" },
  { arabic_label: "تبوك", code: "1" },
  { arabic_label: "وادي الدواسر", code: "1" },
  { arabic_label: "باماكو", code: "35" },
  { arabic_label: "المجمعة", code: "1" },
  { arabic_label: "القويعية", code: "1" },
  { arabic_label: "كوالامبور", code: "39" },
  { arabic_label: "اربد", code: "2" },
  { arabic_label: "لندن", code: "10" },
  { arabic_label: "الجهرة", code: "6" },
  { arabic_label: "الكويت", code: "6" },
  { arabic_label: "البكيرية", code: "1" },
  { arabic_label: "الدوادمي", code: "1" },
  { arabic_label: "الزلفي", code: "1" },
  { arabic_label: "النماص", code: "1" },
  { arabic_label: "القنفذة", code: "1" },
  { arabic_label: "المهد", code: "1" },
  { arabic_label: "بيشة", code: "1" },
  { arabic_label: "جدة", code: "1" },
  { arabic_label: "صبياء", code: "1" },
  { arabic_label: "شقراء", code: "1" },
  { arabic_label: "حائل", code: "1" },
  { arabic_label: "أسبانيا", code: "11" },
  { arabic_label: "حوطة بني تميم والحريق", code: "1" },
  { arabic_label: "المذنب", code: "1" },
  { arabic_label: "ينبع", code: "1" },
  { arabic_label: "العلا", code: "1" },
  { arabic_label: "رجال ألمع", code: "1" },
  { arabic_label: "محايل عسير", code: "1" },
  { arabic_label: "المخواة", code: "1" },
  { arabic_label: "الليث", code: "1" },
  { arabic_label: "سراة عبيدة", code: "1" },
  { arabic_label: "غير محدد", code: "" },
];
export const getcountryCode = (e,data) => {

  if (e)

    return data?.filter((l) => l.country_name_arabic === e || l.country_name_english === e)[0];

};
export const getcityCode = (e, data) => {
  if (e) return data?.filter((l) => l.city_name_arabic === e || l.city_name_english === e)[0];
};
export const getzoneCode = (e) => {
  if (e) return zones.filter((l) => l.arabic_label === e || l.label === e)[0];
};
export const getschoolCode = (e) => {
  if (e) return schools.filter((l) => l.arabic_label === e || l.label === e)[0];
};

export const countryCodes = [
  {
    country: "Mayotte",
    calling_code: "262",
  },
  {
    country: "Romania",
    calling_code: "40",
  },
  {
    country: "Australia",
    calling_code: "61",
  },
  {
    country: "Bengal",
    calling_code: "33",
  },
  {
    country: "Benin",
    calling_code: "229",
  },
  {
    country: "Guadeloupe",
    calling_code: "590",
  },
  {
    country: "Pakistan",
    calling_code: "92",
  },
  {
    country: "Slovenia",
    calling_code: "386",
  },
  {
    country: "Belize",
    calling_code: "501",
  },
  {
    country: "Latvia",
    calling_code: "371",
  },
  {
    country: "Haiti",
    calling_code: "509",
  },
  {
    country: "Andorra",
    calling_code: "376",
  },
  {
    country: "Rwanda",
    calling_code: "250",
  },
  {
    country: "Albania",
    calling_code: "355",
  },
  {
    country: "Grenada",
    calling_code: "1473",
  },
  {
    country: "Iraq",
    calling_code: "964",
  },
  {
    country: "Micronesia (Federated States of)",
    calling_code: "691",
  },
  {
    country: "Korea (Democratic People's Republic of)",
    calling_code: "850",
  },
  {
    country: "Sri Lanka",
    calling_code: "94",
  },
  {
    country: "Vanuatu",
    calling_code: "678",
  },
  {
    country: "Austria",
    calling_code: "43",
  },
  {
    country: "Tunisia",
    calling_code: "216",
  },
  {
    country: "United Arab Emirates",
    calling_code: "971",
  },
  {
    country: "Germany",
    calling_code: "49",
  },
  {
    country: "Israel",
    calling_code: "972",
  },
  {
    country: "Moldova (Republic of)",
    calling_code: "373",
  },
  {
    country: "Puerto Rico",
    calling_code: "1787",
  },
  {
    country: "Turkey",
    calling_code: "90",
  },
  {
    country: "Lebanon",
    calling_code: "961",
  },
  {
    country: "Maldives",
    calling_code: "960",
  },
  {
    country: "Saint Pierre and Miquelon",
    calling_code: "508",
  },
  {
    country: "Suriname",
    calling_code: "597",
  },
  {
    country: "Turkmenistan",
    calling_code: "993",
  },
  {
    country: "Cuba",
    calling_code: "53",
  },
  {
    country: "Côte d'Ivoire",
    calling_code: "225",
  },
  {
    country: "Pitcairn",
    calling_code: "64",
  },
  {
    country: "Saint Barthélemy",
    calling_code: "590",
  },
  {
    country: "Croatia",
    calling_code: "385",
  },
  {
    country: "Indonesia",
    calling_code: "62",
  },
  {
    country: "Kenya",
    calling_code: "254",
  },
  {
    country: "Trinidad and Tobago",
    calling_code: "1868",
  },
  {
    country: "Virgin Islands (U.S.)",
    calling_code: "1 340",
  },
  {
    country: "French Guiana",
    calling_code: "594",
  },
  {
    country: "India",
    calling_code: "91",
  },
  {
    country: "American Samoa",
    calling_code: "1684",
  },
  {
    country: "Bhutan",
    calling_code: "975",
  },
  {
    country: "Burundi",
    calling_code: "257",
  },
  {
    country: "Greece",
    calling_code: "30",
  },
  {
    country: "Kuwait",
    calling_code: "965",
  },
  {
    country: "Macao",
    calling_code: "853",
  },
  {
    country: "Mauritania",
    calling_code: "222",
  },
  {
    country: "San Marino",
    calling_code: "378",
  },
  {
    country: "Belarus",
    calling_code: "375",
  },
  {
    country: "Chad",
    calling_code: "235",
  },
  {
    country: "China",
    calling_code: "86",
  },
  {
    country: "Republic of Kosovo",
    calling_code: "383",
  },
  {
    country: "Estonia",
    calling_code: "372",
  },
  {
    country: "Gabon",
    calling_code: "241",
  },
  {
    country: "Palau",
    calling_code: "680",
  },
  {
    country: "Peru",
    calling_code: "51",
  },
  {
    country: "Falkland Islands (Malvinas)",
    calling_code: "500",
  },
  {
    country: "Marshall Islands",
    calling_code: "692",
  },
  {
    country: "Saint Martin (French part)",
    calling_code: "590",
  },
  {
    country: "Sudan",
    calling_code: "249",
  },
  {
    country: "Timor-Leste",
    calling_code: "670",
  },
  {
    country: "Denmark",
    calling_code: "45",
  },
  {
    country: "Egypt",
    calling_code: "20",
  },
  {
    country: "Mauritius",
    calling_code: "230",
  },
  {
    country: "Oman",
    calling_code: "968",
  },
  {
    country: "Sao Tome and Principe",
    calling_code: "239",
  },
  {
    country: "Curaçao",
    calling_code: "599",
  },
  {
    country: "Ecuador",
    calling_code: "593",
  },
  {
    country: "Ireland",
    calling_code: "353",
  },
  {
    country: "Isle of Man",
    calling_code: "44",
  },
  {
    country: "Spain",
    calling_code: "34",
  },
  {
    country: "Virgin Islands (British)",
    calling_code: "1284",
  },
  {
    country: "Equatorial Guinea",
    calling_code: "240",
  },
  {
    country: "Italy",
    calling_code: "39",
  },
  {
    country: "Madagascar",
    calling_code: "261",
  },
  {
    country: "Togo",
    calling_code: "228",
  },
  {
    country: "Bulgaria",
    calling_code: "359",
  },
  {
    country: "Cocos (Keeling) Islands",
    calling_code: "61",
  },
  {
    country: "Finland",
    calling_code: "358",
  },
  {
    country: "Saudi Arabia",
    calling_code: "966",
  },
  {
    country: "Korea (Republic of)",
    calling_code: "82",
  },
  {
    country: "Cabo Verde",
    calling_code: "238",
  },
  {
    country: "Nepal",
    calling_code: "977",
  },
  {
    country: "Nigeria",
    calling_code: "234",
  },
  {
    country: "Papua New Guinea",
    calling_code: "675",
  },
  {
    country: "Saint Kitts and Nevis",
    calling_code: "1869",
  },
  {
    country: "Dominican Republic",
    calling_code: "1809",
  },
  {
    country: "France",
    calling_code: "33",
  },
  {
    country: "Azerbaijan",
    calling_code: "994",
  },
  {
    country: "Bolivia (Plurinational State of)",
    calling_code: "591",
  },
  {
    country: "Central African Republic",
    calling_code: "236",
  },
  {
    country: "Ghana",
    calling_code: "233",
  },
  {
    country: "Åland Islands",
    calling_code: "358",
  },
  {
    country: "Nicaragua",
    calling_code: "505",
  },
  {
    country: "Norway",
    calling_code: "47",
  },
  {
    country: "Montenegro",
    calling_code: "382",
  },
  {
    country: "Montserrat",
    calling_code: "1664",
  },
  {
    country: "Poland",
    calling_code: "48",
  },
  {
    country: "Burkina Faso",
    calling_code: "226",
  },
  {
    country: "Honduras",
    calling_code: "504",
  },
  {
    country: "Iran (Islamic Republic of)",
    calling_code: "98",
  },
  {
    country: "Japan",
    calling_code: "81",
  },
  {
    country: "Malawi",
    calling_code: "265",
  },
  {
    country: "Qatar",
    calling_code: "974",
  },
  {
    country: "Botswana",
    calling_code: "267",
  },
  {
    country: "Colombia",
    calling_code: "57",
  },
  {
    country: "Czech Republic",
    calling_code: "420",
  },
  {
    country: "Monaco",
    calling_code: "377",
  },
  {
    country: "Nauru",
    calling_code: "674",
  },
  {
    country: "Guinea-Bissau",
    calling_code: "245",
  },
  {
    country: "South Sudan",
    calling_code: "211",
  },
  {
    country: "Syrian Arab Republic",
    calling_code: "963",
  },
  {
    country: "Afghanistan",
    calling_code: "93",
  },
  {
    country: "Tuvalu",
    calling_code: "688",
  },
  {
    country: "Guatemala",
    calling_code: "502",
  },
  {
    country: "Philippines",
    calling_code: "63",
  },
  {
    country: "Portugal",
    calling_code: "351",
  },
  {
    country: "Saint Lucia",
    calling_code: "1758",
  },
  {
    country: "Singapore",
    calling_code: "65",
  },
  {
    country: "Brazil",
    calling_code: "55",
  },
  {
    country: "Gibraltar",
    calling_code: "350",
  },
  {
    country: "Mexico",
    calling_code: "52",
  },
  {
    country: "Bermuda",
    calling_code: "1441",
  },
  {
    country: "Congo",
    calling_code: "242",
  },
  {
    country: "Hong Kong",
    calling_code: "852",
  },
  {
    country: "Jordan",
    calling_code: "962",
  },
  {
    country: "Solomon Islands",
    calling_code: "677",
  },
  {
    country: "Uruguay",
    calling_code: "598",
  },
  {
    country: "Cameroon",
    calling_code: "237",
  },
  {
    country: "Ethiopia",
    calling_code: "251",
  },
  {
    country: "Greenland",
    calling_code: "299",
  },
  {
    country: "Senegal",
    calling_code: "221",
  },
  {
    country: "Seychelles",
    calling_code: "248",
  },
  {
    country: "Niue",
    calling_code: "683",
  },
  {
    country: "Angola",
    calling_code: "244",
  },
  {
    country: "Antigua and Barbuda",
    calling_code: "1268",
  },
  {
    country: "Barbados",
    calling_code: "1246",
  },
  {
    country: "Cook Islands",
    calling_code: "682",
  },
  {
    country: "Guinea",
    calling_code: "224",
  },
  {
    country: "Anguilla",
    calling_code: "1264",
  },
  {
    country: "Djibouti",
    calling_code: "253",
  },
  {
    country: "Wallis and Futuna",
    calling_code: "681",
  },
  {
    country: "Belgium",
    calling_code: "32",
  },
  {
    country: "Réunion",
    calling_code: "262",
  },
  {
    country: "Macedonia (the former Yugoslav Republic of)",
    calling_code: "389",
  },
  {
    country: "Myanmar",
    calling_code: "95",
  },
  {
    country: "New Zealand",
    calling_code: "64",
  },
  {
    country: "South Africa",
    calling_code: "27",
  },
  {
    country: "Western Sahara",
    calling_code: "212",
  },
  {
    country: "Iceland",
    calling_code: "354",
  },
  {
    country: "Samoa",
    calling_code: "685",
  },
  {
    country: "Ukraine",
    calling_code: "380",
  },
  {
    country: "Palestine, State of",
    calling_code: "970",
  },
  {
    country: "Taiwan",
    calling_code: "886",
  },
  {
    country: "Bahamas",
    calling_code: "1242",
  },
  {
    country: "Brunei Darussalam",
    calling_code: "673",
  },
  {
    country: "Congo (Democratic Republic of the)",
    calling_code: "243",
  },
  {
    country: "El Salvador",
    calling_code: "503",
  },
  {
    country: "Mongolia",
    calling_code: "976",
  },
  {
    country: "Faroe Islands",
    calling_code: "298",
  },
  {
    country: "Fiji",
    calling_code: "679",
  },
  {
    country: "Guam",
    calling_code: "1671",
  },
  {
    country: "Morocco",
    calling_code: "212",
  },
  {
    country: "Bosnia and Herzegovina",
    calling_code: "387",
  },
  {
    country: "French Polynesia",
    calling_code: "689",
  },
  {
    country: "Liechtenstein",
    calling_code: "423",
  },
  {
    country: "Christmas Island",
    calling_code: "61",
  },
  {
    country: "Hungary",
    calling_code: "36",
  },
  {
    country: "Kazakhstan",
    calling_code: "76",
  },
  {
    country: "Kyrgyzstan",
    calling_code: "996",
  },
  {
    country: "Lao People's Democratic Republic",
    calling_code: "856",
  },
  {
    country: "Lithuania",
    calling_code: "370",
  },
  {
    country: "Uzbekistan",
    calling_code: "998",
  },
  {
    country: "Turks and Caicos Islands",
    calling_code: "1649",
  },
  {
    country: "Chile",
    calling_code: "56",
  },
  {
    country: "Comoros",
    calling_code: "269",
  },
  {
    country: "New Caledonia",
    calling_code: "687",
  },
  {
    country: "Somalia",
    calling_code: "252",
  },
  {
    country: "Tanzania, United Republic of",
    calling_code: "255",
  },
  {
    country: "Bahrain",
    calling_code: "973",
  },
  {
    country: "Mali",
    calling_code: "223",
  },
  {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    calling_code: "290",
  },
  {
    country: "Sweden",
    calling_code: "46",
  },
  {
    country: "Yemen",
    calling_code: "967",
  },
  {
    country: "Bangladesh",
    calling_code: "880",
  },
  {
    country: "Serbia",
    calling_code: "381",
  },
  {
    country: "Swaziland",
    calling_code: "268",
  },
  {
    country: "Jamaica",
    calling_code: "1876",
  },
  {
    country: "Malaysia",
    calling_code: "60",
  },
  {
    country: "Mozambique",
    calling_code: "258",
  },
  {
    country: "Saint Vincent and the Grenadines",
    calling_code: "1784",
  },
  {
    country: "Viet Nam",
    calling_code: "84",
  },
  {
    country: "Aruba",
    calling_code: "297",
  },
  {
    country: "Cyprus",
    calling_code: "357",
  },
  {
    country: "Tokelau",
    calling_code: "690",
  },
  {
    country: "United States of America",
    calling_code: "1",
  },
  {
    country: "Zimbabwe",
    calling_code: "263",
  },
  {
    country: "Martinique",
    calling_code: "596",
  },
  {
    country: "Namibia",
    calling_code: "264",
  },
  {
    country: "Niger",
    calling_code: "227",
  },
  {
    country: "Argentina",
    calling_code: "54",
  },
  {
    country: "Cayman Islands",
    calling_code: "1345",
  },
  {
    country: "Luxembourg",
    calling_code: "352",
  },
  {
    country: "Panama",
    calling_code: "507",
  },
  {
    country: "Paraguay",
    calling_code: "595",
  },
  {
    country: "Eritrea",
    calling_code: "291",
  },
  {
    country: "Gambia",
    calling_code: "220",
  },
  {
    country: "Thailand",
    calling_code: "66",
  },
  {
    country: "Algeria",
    calling_code: "213",
  },
  {
    country: "Cambodia",
    calling_code: "855",
  },
  {
    country: "Canada",
    calling_code: "1",
  },
  {
    country: "Norfolk Island",
    calling_code: "672",
  },
  {
    country: "Zambia",
    calling_code: "260",
  },
  {
    country: "Malta",
    calling_code: "356",
  },
  {
    country: "Switzerland",
    calling_code: "41",
  },
  {
    country: "Tonga",
    calling_code: "676",
  },
  {
    country: "Lesotho",
    calling_code: "266",
  },
  {
    country: "Sierra Leone",
    calling_code: "232",
  },
  {
    country: "Guyana",
    calling_code: "592",
  },
  {
    country: "Libya",
    calling_code: "218",
  },
  {
    country: "Russian Federation",
    calling_code: "7",
  },
  {
    country: "Czechoslovakia",
    calling_code: "42",
  },
  {
    country: "Slovakia",
    calling_code: "421",
  },
  {
    country: "Armenia",
    calling_code: "374",
  },
  {
    country: "Liberia",
    calling_code: "231",
  },
  {
    country: "Netherlands",
    calling_code: "31",
  },
  {
    country: "United Kingdom of Great Britain and Northern Ireland",
    calling_code: "44",
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    calling_code: "500",
  },
  {
    country: "Costa Rica",
    calling_code: "506",
  },
  {
    country: "Georgia",
    calling_code: "995",
  },
  {
    country: "Kiribati",
    calling_code: "686",
  },
  {
    country: "Tajikistan",
    calling_code: "992",
  },
  {
    country: "Uganda",
    calling_code: "256",
  },
  {
    country: "Venezuela (Bolivarian Republic of)",
    calling_code: "58",
  },
];
export const countryNationality = [
  {
    country: "Peru",
    nationality: "Peruvian",
  },
  {
    country: "Brazil",
    nationality: "Brazilian",
  },
  {
    country: "Libya",
    nationality: "Libyan",
  },
  {
    country: "San Marino",
    nationality: "Sammarinese",
  },
  {
    country: "Thailand",
    nationality: "Thai",
  },
  {
    country: "Malaysia",
    nationality: "Malaysian",
  },
  {
    country: "Paraguay",
    nationality: "Paraguayan",
  },
  {
    country: "Namibia",
    nationality: "Namibian",
  },
  {
    country: "Czechoslovakia",
    nationality: "Czechoslovakia",
  },
  {
    country: "Slovakia",
    nationality: "Slovak",
  },
  {
    country: "China",
    nationality: "Chinese",
  },
  {
    country: "Honduras",
    nationality: "Honduran",
  },
  {
    country: "Uganda",
    nationality: "Ugandan",
  },
  {
    country: "Indonesia",
    nationality: "Indonesia",
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    nationality: "South Georgia and the South Sandwich Islander",
  },
  {
    country: "Yemen",
    nationality: "Yemeni",
  },
  {
    country: "Azerbaijan",
    nationality: "Azerbaijan",
  },
  {
    country: "Israel",
    nationality: "Israeli",
  },
  {
    country: "Singapore",
    nationality: "Singapore",
  },
  {
    country: "United Arab Emirates",
    nationality: "United Arab Emirates",
  },
  {
    country: "Bolivia (Plurinational State of)",
    nationality: "Bolivian",
  },
  {
    country: "Ireland",
    nationality: "Irish",
  },
  {
    country: "Central African Republic",
    nationality: "Central African",
  },
  {
    country: "French Polynesia",
    nationality: "French Polynesian",
  },
  {
    country: "Grenada",
    nationality: "Grenadian",
  },
  {
    country: "Guinea-Bissau",
    nationality: "Guinea-Bissauan",
  },
  {
    country: "Nepal",
    nationality: "Nepalese",
  },
  {
    country: "Panama",
    nationality: "Panamanian",
  },
  {
    country: "Burkina Faso",
    nationality: "Burkinabe",
  },
  {
    country: "Cambodia",
    nationality: "Cambodian",
  },
  {
    country: "Uzbekistan",
    nationality: "Uzbekistan",
  },
  {
    country: "Anguilla",
    nationality: "Anguillian",
  },
  {
    country: "Korea (Republic of)",
    nationality: "South Korean",
  },
  {
    country: "Curaçao",
    nationality: "Curaçaoan",
  },
  {
    country: "Wallis and Futuna",
    nationality: "Wallis and Futuna Islander",
  },
  {
    country: "Myanmar",
    nationality: "Burmese",
  },
  {
    country: "Egypt",
    nationality: "Egyptian",
  },
  {
    country: "Gambia",
    nationality: "Gambian",
  },
  {
    country: "Saudi Arabia",
    nationality: "Saudi",
  },
  {
    country: "Argentina",
    nationality: "Argentina",
  },
  {
    country: "Canada",
    nationality: "Canadian",
  },
  {
    country: "Montenegro",
    nationality: "Montenegrin",
  },
  {
    country: "United States of America",
    nationality: "American",
  },
  {
    country: "Cook Islands",
    nationality: "Cook Islander",
  },
  {
    country: "Macao",
    nationality: "Macanese",
  },
  {
    country: "Cocos (Keeling) Islands",
    nationality: "Cocos Islander",
  },
  {
    country: "Saint Lucia",
    nationality: "Saint Lucian",
  },
  {
    country: "Cyprus",
    nationality: "Cyprus",
  },
  {
    country: "Iceland",
    nationality: "Icelander",
  },
  {
    country: "Côte d'Ivoire",
    nationality: "Ivorian",
  },
  {
    country: "Bangladesh",
    nationality: "Bangladeshi",
  },
  {
    country: "Bhutan",
    nationality: "Bhutanese",
  },
  {
    country: "Saint Kitts and Nevis",
    nationality: "Kittian and Nevisian",
  },
  {
    country: "Finland",
    nationality: "Finnish",
  },
  {
    country: "Macedonia (the former Yugoslav Republic of)",
    nationality: "Macedonian",
  },
  {
    country: "Qatar",
    nationality: "Qatari",
  },
  {
    country: "Sudan",
    nationality: "Sudanese",
  },
  {
    country: "Isle of Man",
    nationality: "Manx",
  },
  {
    country: "Latvia",
    nationality: "Latvian",
  },
  {
    country: "Timor-Leste",
    nationality: "East Timorese",
  },
  {
    country: "Sao Tome and Principe",
    nationality: "Sao Tomean",
  },
  {
    country: "Sweden",
    nationality: "Sweden",
  },
  {
    country: "Martinique",
    nationality: "Martinican",
  },
  {
    country: "Sierra Leone",
    nationality: "Sierra Leonean",
  },
  {
    country: "South Africa",
    nationality: "South Africa",
  },
  {
    country: "Tanzania, United Republic of",
    nationality: "Tanzanian",
  },
  {
    country: "Costa Rica",
    nationality: "Costa Rican",
  },
  {
    country: "Iran (Islamic Republic of)",
    nationality: "Iranian",
  },
  {
    country: "Dominican Republic",
    nationality: "Dominican",
  },
  {
    country: "Nigeria",
    nationality: "Nigerian",
  },
  {
    country: "Palestine, State of",
    nationality: "Palestinian",
  },
  {
    country: "Bosnia and Herzegovina",
    nationality: "Bosnia",
  },
  {
    country: "Cayman Islands",
    nationality: "Caymanian",
  },
  {
    country: "Maldives",
    nationality: "Maldivan",
  },
  {
    country: "Japan",
    nationality: "Japan",
  },
  {
    country: "Chad",
    nationality: "Chadian",
  },
  {
    country: "Guinea",
    nationality: "Guinean",
  },
  {
    country: "Madagascar",
    nationality: "Malagasy",
  },
  {
    country: "Italy",
    nationality: "Italy",
  },
  {
    country: "Jordan",
    nationality: "Jordanian",
  },
  {
    country: "Liechtenstein",
    nationality: "Liechtensteiner",
  },
  {
    country: "Mongolia",
    nationality: "Mongolian",
  },
  {
    country: "Tonga",
    nationality: "Tongan",
  },
  {
    country: "Ecuador",
    nationality: "Ecuadorean",
  },
  {
    country: "Guam",
    nationality: "Guamanian",
  },
  {
    country: "Kuwait",
    nationality: "Kuwaiti",
  },
  {
    country: "Kyrgyzstan",
    nationality: "Kirghiz",
  },
  {
    country: "Mauritius",
    nationality: "Mauritian",
  },
  {
    country: "Åland Islands",
    nationality: "Ålandish",
  },
  {
    country: "Bahrain",
    nationality: "Bahraini",
  },
  {
    country: "Mexico",
    nationality: "Mexico",
  },
  {
    country: "Poland",
    nationality: "Poland",
  },
  {
    country: "Chile",
    nationality: "Chilean",
  },
  {
    country: "Djibouti",
    nationality: "Djiboutian",
  },
  {
    country: "Ethiopia",
    nationality: "Ethiopian",
  },
  {
    country: "Mauritania",
    nationality: "Mauritanian",
  },
  {
    country: "Korea (Democratic People's Republic of)",
    nationality: "North Korean",
  },
  {
    country: "Swaziland",
    nationality: "Swazi",
  },
  {
    country: "Aruba",
    nationality: "Aruban",
  },
  {
    country: "Austria",
    nationality: "Austria",
  },
  {
    country: "Haiti",
    nationality: "Haitian",
  },
  {
    country: "Portugal",
    nationality: "Portuguese",
  },
  {
    country: "Vanuatu",
    nationality: "Ni-Vanuatu",
  },
  {
    country: "Christmas Island",
    nationality: "Christmas Island",
  },
  {
    country: "Colombia",
    nationality: "Colombian",
  },
  {
    country: "Cuba",
    nationality: "Cuban",
  },
  {
    country: "French Guiana",
    nationality: "French Guianese",
  },
  {
    country: "Afghanistan",
    nationality: "Afghani",
  },
  {
    country: "Brunei Darussalam",
    nationality: "Bruneian",
  },
  {
    country: "Jamaica",
    nationality: "Jamaican",
  },
  {
    country: "Moldova (Republic of)",
    nationality: "Moldovan",
  },
  {
    country: "France",
    nationality: "French",
  },
  {
    country: "Kenya",
    nationality: "Kenyan",
  },
  {
    country: "Oman",
    nationality: "Omanian",
  },
  {
    country: "Albania",
    nationality: "Albanian",
  },
  {
    country: "Cabo Verde",
    nationality: "Cape Verdian",
  },
  {
    country: "Turkey",
    nationality: "Turkey",
  },
  {
    country: "Zimbabwe",
    nationality: "Zimbabwean",
  },
  {
    country: "Algeria",
    nationality: "Algerian",
  },
  {
    country: "Niger",
    nationality: "Nigerien",
  },
  {
    country: "Hungary",
    nationality: "Hungarian",
  },
  {
    country: "Spain",
    nationality: "Spanish",
  },
  {
    country: "Germany",
    nationality: "Germany",
  },
  {
    country: "Guatemala",
    nationality: "Guatemalan",
  },
  {
    country: "Mozambique",
    nationality: "Mozambican",
  },
  {
    country: "Palau",
    nationality: "Palauan",
  },
  {
    country: "Lesotho",
    nationality: "Mosotho",
  },
  {
    country: "Marshall Islands",
    nationality: "Marshallese",
  },
  {
    country: "Suriname",
    nationality: "Surinamer",
  },
  {
    country: "Guyana",
    nationality: "Guyanese",
  },
  {
    country: "Sri Lanka",
    nationality: "Sri Lanka",
  },
  {
    country: "Lebanon",
    nationality: "Lebanese",
  },
  {
    country: "Mayotte",
    nationality: "Mahoran",
  },
  {
    country: "Samoa",
    nationality: "Samoan",
  },
  {
    country: "Solomon Islands",
    nationality: "Solomon Islander",
  },
  {
    country: "Denmark",
    nationality: "Denemark",
  },
  {
    country: "Kiribati",
    nationality: "I-Kiribati",
  },
  {
    country: "Switzerland",
    nationality: "Switzerland",
  },
  {
    country: "Lithuania",
    nationality: "Lithuanian",
  },
  {
    country: "Somalia",
    nationality: "Somalian",
  },
  {
    country: "Norfolk Island",
    nationality: "Norfolk Islander",
  },
  {
    country: "Taiwan",
    nationality: "Taiwanese",
  },
  {
    country: "Ghana",
    nationality: "Ghanaian",
  },
  {
    country: "New Zealand",
    nationality: "New Zealand",
  },
  {
    country: "Senegal",
    nationality: "Senegalian",
  },
  {
    country: "Togo",
    nationality: "Togolese",
  },
  {
    country: "Zambia",
    nationality: "Zambian",
  },
  {
    country: "Belarus",
    nationality: "Belarusian",
  },
  {
    country: "Saint Vincent and the Grenadines",
    nationality: "Saint Vincentian",
  },
  {
    country: "El Salvador",
    nationality: "Salvadoran",
  },
  {
    country: "Norway",
    nationality: "Norway",
  },
  {
    country: "Malta",
    nationality: "Maltese",
  },
  {
    country: "Nicaragua",
    nationality: "Nicaraguan",
  },
  {
    country: "Australia",
    nationality: "Australian",
  },
  {
    country: "Luxembourg",
    nationality: "Luxembourger",
  },
  {
    country: "Pakistan",
    nationality: "Pakistani",
  },
  {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    nationality: "Saint Helenian",
  },
  {
    country: "Turks and Caicos Islands",
    nationality: "Turks and Caicos Islander",
  },
  {
    country: "Western Sahara",
    nationality: "Sahrawi",
  },
  {
    country: "Belgium",
    nationality: "Belgium",
  },
  {
    country: "Eritrea",
    nationality: "Eritrean",
  },
  {
    country: "Seychelles",
    nationality: "Seychellois",
  },
  {
    country: "Antigua and Barbuda",
    nationality: "Antiguan, Barbudan",
  },
  {
    country: "Liberia",
    nationality: "Liberian",
  },
  {
    country: "Tuvalu",
    nationality: "Tuvaluan",
  },
  {
    country: "Bermuda",
    nationality: "Bermudian",
  },
  {
    country: "Cameroon",
    nationality: "Cameroonian",
  },
  {
    country: "Saint Pierre and Miquelon",
    nationality: "Saint-Pierrais",
  },
  {
    country: "Ukraine",
    nationality: "Ukrainian",
  },
  {
    country: "Georgia",
    nationality: "Georgia",
  },
  {
    country: "Netherlands",
    nationality: "Dutch",
  },
  {
    country: "Mali",
    nationality: "Malian",
  },
  {
    country: "Niue",
    nationality: "Niuean",
  },
  {
    country: "Puerto Rico",
    nationality: "Puerto Rican",
  },
  {
    country: "United Kingdom of Great Britain and Northern Ireland",
    nationality: "British",
  },
  {
    country: "Armenia",
    nationality: "Armenian",
  },
  {
    country: "Greenland",
    nationality: "Greenlandic",
  },
  {
    country: "Kazakhstan",
    nationality: "Kazakhstani",
  },
  {
    country: "Réunion",
    nationality: "Réunionese",
  },
  {
    country: "Saint Martin (French part)",
    nationality: "Saint Martin Islander",
  },
  {
    country: "Belize",
    nationality: "Belizean",
  },
  {
    country: "Greece",
    nationality: "Greek",
  },
  {
    country: "Trinidad and Tobago",
    nationality: "Trinidadian",
  },
  {
    country: "Benin",
    nationality: "Beninese",
  },
  {
    country: "Bengal",
    nationality: "Bengali",
  },
  {
    country: "Tajikistan",
    nationality: "Tadzhik",
  },
  {
    country: "Czech Republic",
    nationality: "Czech",
  },
  {
    country: "India",
    nationality: "Indian",
  },
  {
    country: "Lao People's Democratic Republic",
    nationality: "Laotian",
  },
  {
    country: "Syrian Arab Republic",
    nationality: "Syrian",
  },
  {
    country: "Turkmenistan",
    nationality: "Turkmenistan",
  },
  {
    country: "Botswana",
    nationality: "Motswana",
  },
  {
    country: "Virgin Islands (British)",
    nationality: "Virgin Islander (British)",
  },
  {
    country: "American Samoa",
    nationality: "American Samoan",
  },
  {
    country: "Guadeloupe",
    nationality: "Guadeloupian",
  },
  {
    country: "New Caledonia",
    nationality: "New Caledonian",
  },
  {
    country: "Viet Nam",
    nationality: "Vietnamese",
  },
  {
    country: "Comoros",
    nationality: "Comoros",
  },
  {
    country: "Micronesia (Federated States of)",
    nationality: "Micronesian",
  },
  {
    country: "Burundi",
    nationality: "Burundian",
  },
  {
    country: "Morocco",
    nationality: "Moroccan",
  },
  {
    country: "Saint Barthélemy",
    nationality: "Saint Barthélemy Islander",
  },
  {
    country: "Barbados",
    nationality: "Barbadian",
  },
  {
    country: "Virgin Islands (U.S.)",
    nationality: "Virgin Islander (U.S.)",
  },
  {
    country: "Iraq",
    nationality: "Iraqi",
  },
  {
    country: "Papua New Guinea",
    nationality: "Papua New Guinean",
  },
  {
    country: "Equatorial Guinea",
    nationality: "Equatorial Guinean",
  },
  {
    country: "Gabon",
    nationality: "Gabonese",
  },
  {
    country: "Uruguay",
    nationality: "Uruguayan",
  },
  {
    country: "Monaco",
    nationality: "Monegasque",
  },
  {
    country: "Tunisia",
    nationality: "Tunisian",
  },
  {
    country: "Fiji",
    nationality: "Fijian",
  },
  {
    country: "Rwanda",
    nationality: "Rwandan",
  },
  {
    country: "Angola",
    nationality: "Angolan",
  },
  {
    country: "Falkland Islands (Malvinas)",
    nationality: "Falkland Islander",
  },
  {
    country: "Serbia",
    nationality: "Serbian",
  },
  {
    country: "Slovenia",
    nationality: "Slovenian",
  },
  {
    country: "Congo (Democratic Republic of the)",
    nationality: "Congolese (Democratic Republic)",
  },
  {
    country: "Malawi",
    nationality: "Malawian",
  },
  {
    country: "Venezuela (Bolivarian Republic of)",
    nationality: "Venezuelan",
  },
  {
    country: "Bulgaria",
    nationality: "Bulgarian",
  },
  {
    country: "Hong Kong",
    nationality: "Hong Kongese",
  },
  {
    country: "Gibraltar",
    nationality: "Gibraltar",
  },
  {
    country: "South Sudan",
    nationality: "South Sudanese",
  },
  {
    country: "Nauru",
    nationality: "Nauruan",
  },
  {
    country: "Pitcairn",
    nationality: "Pitcairn Islander",
  },
  {
    country: "Russian Federation",
    nationality: "Russian",
  },
  {
    country: "Andorra",
    nationality: "Andorran",
  },
  {
    country: "Faroe Islands",
    nationality: "Faroese",
  },
  {
    country: "Congo",
    nationality: "Congolese",
  },
  {
    country: "Croatia",
    nationality: "Croatian",
  },
  {
    country: "Montserrat",
    nationality: "Montserratian",
  },
  {
    country: "Philippines",
    nationality: "Philippine",
  },
  {
    country: "Republic of Kosovo",
    nationality: "Kosovar",
  },
  {
    country: "Romania",
    nationality: "Romanian",
  },
  {
    country: "Tokelau",
    nationality: "Tokelauan",
  },
  {
    country: "Bahamas",
    nationality: "Bahamian",
  },
  {
    country: "Estonia",
    nationality: "Estonian",
  },
];
export const countryShortCode = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
];

export const ScoreType = [
  {id: 1, name: 'qudTah'},
  {id: 2, name: 'SAT'}
]

export const SaudiScoreType = [
  {id: 1, name: 'qudTah'}
]

export const AmericanScoreType = [
  {id: 1, name: 'qudTah'},
  {id: 2, name: 'SAT'},
  {id: 3, name: 'act'},
]

export const BritishScoreType = [
  {id: 1, name: 'qudTah'},
  {id: 2, name: 'SAT'},
  {id: 4, name: 'GSCE'},
]
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { surveyInfo } from "../../../../common/helper/getAuthFields";
import { validate } from "../../../../common/helper/validate";
import {Grid, MenuItem, Checkbox, FormControlLabel} from "@material-ui/core";

const PerosnalInfo = ({ handleSubmit, submitRef, next, form }) => {
  // const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [t, i18n] = useTranslation();
  const isArabicLang = i18n.language === "ar";

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const submit = (values) => {
    next();
  };

  return (
    <form onSubmit={handleSubmit((values) => submit(values))}>
      <Grid direction={isArabicLang ? "row-reverse" : "row"} container justify="center" spacing={2}>
        {surveyInfo.map((fieldData, index) => {
          return (
            <Grid item xs={12} key={index}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={6} key={index}>
                  <Field
                    name={fieldData.name}
                    label={t(`register.labels.${fieldData.name}`)}
                    required
                    component={fieldData.component}
                  >
                    {fieldData.items.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {i18n.language === "en"
                            ? item.label
                            : item.arabic_label}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </Grid>
              </Grid>
            </Grid>
          );
        })}

        <Field
          name="agree"
          required
          component={() => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={checked}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={t("register.labels.terms")}
              />
            );
          }}
        />
        <button
          type="submit"
          ref={submitRef}
          style={{ display: "none" }}
        ></button>
      </Grid>
    </form>
  );
};

const mapStateToProps = ({ form }) => ({ form });

export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
  validate,
})(connect(mapStateToProps)(PerosnalInfo));

import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { reduxForm, change as changeFieldValue } from "redux-form";
import { useStyles } from "../../../../app/applicant/components/uploadForm/uploadFormstyles";
import { validate } from "../../../../common/helper/validate";
import MuiUploadField from "../../../../common/components/muiUploadField/MuiUploadField";
import EngDocumentState from "./EngDocumentState";

const NewDocuments = ({ submits, next, form }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    // add ther status in formdata objet with files
    // after passing all rquired fields upload formdata file
    dispatch(changeFieldValue("register", "applicant_type", "FS"));
    // next();
  };

  const addFile = (name, file) => {
    dispatch(changeFieldValue("register", name, file));
  };

  const addScore = (e) => {
    dispatch(changeFieldValue("register", "english_certf_score", e.target.value));
  };

  const removeScore = () => {
    dispatch(changeFieldValue("register", "english_certf_score", undefined));
  };

  const removeFile = (name) => {
    dispatch(changeFieldValue("register", name, undefined));
  };

  let {scoreType, employment_state, SAT, ACT, GSCE, photo, national_file, high_school, quadrant_file, tahisli_file, employee_card, secondary_region} = form.register.values;

  return (
      <div className={classes.form}>
        <form onSubmit={onSubmit} className={classes.formInner}>
          <MuiUploadField add={addFile} remove={removeFile} required name="national_file" label={t("applicant.uploadForm.inputs.labels.ssn")} description={t("applicant.uploadForm.inputs.tooltips.ssn")} file={national_file} />
          <MuiUploadField required add={addFile} remove={removeFile} name="high_school" label={t("applicant.uploadForm.inputs.labels.school")} description={t("applicant.uploadForm.inputs.tooltips.school")} file={high_school} />
          {secondary_region === 'BRI' && (<MuiUploadField add={addFile} remove={removeFile} name="GSCE" label="GSCE" description={t("applicant.uploadForm.inputs.tooltips.GSCE")} file={GSCE} />)}
          {scoreType === 2 && (<MuiUploadField add={addFile} remove={removeFile} name="SAT" label="SAT" description={t("applicant.uploadForm.inputs.tooltips.score")} file={SAT}/>)}
          {scoreType === 3 && (<MuiUploadField add={addFile} remove={removeFile} name="ACT" label="ACT" description={t("applicant.uploadForm.inputs.tooltips.score")} file={ACT}/>)}
          {employment_state === "work" && (<MuiUploadField required add={addFile} remove={removeFile} name="employee_card" label={t("applicant.uploadForm.inputs.labels.employee_card")} description={t("applicant.uploadForm.inputs.tooltips.employee_card")} file={employee_card}/>)}
          <MuiUploadField required add={addFile} remove={removeFile} name="photo" label={t("applicant.uploadForm.inputs.labels.photo")} description={t("applicant.uploadForm.inputs.tooltips.photo")} file={photo}/>
          {(scoreType === 1) && (
              <>
                <MuiUploadField add={addFile} remove={removeFile} required name="quadrant_file" label={"Quadrat File"} description={t('applicant.uploadForm.inputs.tooltips.qudrantFileUpload')} file={quadrant_file} />
                <MuiUploadField add={addFile} remove={removeFile} required name="tahisli_file" label={"Tahsili File"} description={t('applicant.uploadForm.inputs.tooltips.tahsiliFileUpload')} file={tahisli_file} />
              </>
          )}
          <EngDocumentState form={form} add={addFile} remove={removeFile} addScore={addScore} removeScore={removeScore}/>
          <button type="submit" ref={submits} style={{ display: "none" }}></button>
        </form>
      </div>
  );
};

const mapStateToProps = ({ form }) => ({ form });

export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
  validate
})(connect(mapStateToProps)(NewDocuments));

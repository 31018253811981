import React, { useState,useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect,useDispatch } from "react-redux";
import moment from 'moment';
import { Field, reduxForm,change } from "redux-form";
import { perosnalInfo, educationInfo } from "../../../../common/helper/getAuthFields";
import { validate } from "../../../../common/helper/validate";
import asyncValidate from '../../../../common/helper/asyncValidate'
import { MenuItem, Grid, Typography, TextField } from "@material-ui/core";
import HijriDatePicker from "hijri-date-picker";
import { useStyles } from "../registerStyles";
// import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { toast } from "react-toastify";
let momentH = require("moment-hijri"); 

const PerosnalInfo = ({ handleSubmit, submitRef, next, form, initialValues,change }) => {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const isArabicLang = i18n.language === "ar";
  const [healthCondition, sethealthCondition] = useState(
    form.register.values?.health_state === "dis" ? true : false
  );

  const workState = form.register?.values?.employment_state === 'work'
  const dispatch = useDispatch();

  const [hijri, setHijri] = useState(
    form.register.values.birth_date_hegry &&
    (momentH(form.register.values.birth_date_hegry, "iYYYY-iMM-iDD")
      .locale("en")
      .format("iDD/iMM/iYYYY"))
  );


  const [meladi, setMeladi] = useState(
    form.register.values.birth_date && form.register.values.birth_date
  );

  // const handleOnChange = (val) => {
  //   form.register.values.home_phone= val
  //   setPhone(val)
  // }

  const submit = (values) => {
    form.register.values.birth_date_hegry = momentH(
      form.register.values.birth_date,
      "YYYY-MM-DD"
    )
      .locale("en")
      .format("iYYYY-iMM-iDD");
    next();
  };

  return (
    <form onSubmit={handleSubmit((values) => submit(values))}>
      <Grid direction={isArabicLang ? "row-reverse" : "row"} container justify="center" spacing={2} wrap="wrap">
        {/* names FIELDS */}
        {perosnalInfo.nameFileds.map((fieldData, index) => {
          return (
            <Grid item xs={10} sm={3} key={index}>
              <Field
                name={fieldData.name}
                type={fieldData.type}
                label={t(`register.labels.${fieldData.name}`)}
                required
                component={fieldData.component}
              />
            </Grid>
          );
        })}

        {/* general info */}
        {perosnalInfo.generalFields.map((fieldData, index) => {
  return !fieldData.type === "date" ? (
    <Grid item xs={10} sm={6} lg={4} key={index}>
      <Field
        name={fieldData.name}
        type={fieldData.type}
        label={t(`register.labels.${fieldData.name}`)}
        required={fieldData.required}
        component={fieldData.component}
      />
    </Grid>
  ) : (
    <Grid item xs={10} sm={6} lg={4} key={index}>
                <Field
                  name={fieldData.name}
                  type={fieldData.type}
                  label={t(`register.labels.${fieldData.name}`)}
                  required={fieldData.required}
                  component={fieldData.component}
                  value={`${meladi}`}
                  onChange={(e) =>
                    e.target.name === "birth_date" &&
                    setHijri(
                      momentH(e.target.value, "YYYY-MM-DD")
                        .locale("en")
                        .format("iDD/iMM/iYYYY")
                    )
                  }
                />
              </Grid>
            );
  
})}
        <Grid item xs={10} sm={6} lg={4}>
          <div className={classes.hijri__div}>
            <Typography className={classes.hijri__typo}>
              {t(`register.labels.hijri`)}
            </Typography>
            <HijriDatePicker
              className={classes.hijri}
              inputName="birth_date_hegry"
              dateFormat="iDD/iMM/iYYYY"
              // eslint-disable-next-line
              selectedDate={hijri || form.register.values.birth_date && setHijri(
                momentH(form.register.values.birth_date, "YYYY-MM-DD")
                  .locale("en")
                  .format("iDD/iMM/iYYYY")
              )}
              quickSelect
              required
              onChange={(e) => {
                form.register.values.birth_date = momentH(e, "iDD-iMM-iYYYY")
                  .locale("en")
                  .format("YYYY-MM-DD");
                setMeladi(form.register.values.birth_date);
              }}
            ></HijriDatePicker>
          </div>
        </Grid>

        <Grid item xs={10} sm={6} lg={4}>
              <Field
                name={perosnalInfo.spokenLanguage.name}
                type={perosnalInfo.spokenLanguage.type}
                label={t(`register.labels.${perosnalInfo.spokenLanguage.name}`)}
                required
                component={perosnalInfo.spokenLanguage.component}
              >
                {perosnalInfo.spokenLanguage.items.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {i18n.language === "en" ? item.label : item.arabic_label}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
            
        <Grid item xs={10} sm={6} lg={4}>
          <Field
            name={perosnalInfo.martial.name}
            type={perosnalInfo.martial.type}
            label={t(
              `register.labels.${perosnalInfo.martial.name}`
            )}
            required
            component={perosnalInfo.martial.component}
          >
            {perosnalInfo.martial.items.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {i18n.language === "en" ? item.label : item.arabic_label}
                </MenuItem>
              );
            })}
          </Field>
        </Grid>

        {/* courses type*/}

        <Grid item xs={10} sm={6} lg={4}>
          <Field
            name={perosnalInfo.courseType.name}
            type={perosnalInfo.courseType.type}
            label={t(
              `register.labels.${perosnalInfo.courseType.name}`
            )}
            required
            component={perosnalInfo.courseType.component}
      
          >
            {perosnalInfo.courseType.items.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {i18n.language === "en" ? item.label : item.arabic_label}
                </MenuItem>
              );
            })}
          </Field>
        </Grid>

        <Grid item xs={10} sm={6} lg={4}>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <TextField value="966" disabled variant="outlined" />
            </Grid>
            <Grid item xs={3}>
              <Field
                name={perosnalInfo.home_phone.area_code.name}                
                type={perosnalInfo.home_phone.area_code.type}
                label={t(`register.labels.${perosnalInfo.home_phone.area_code.name}`)}
                component={perosnalInfo.home_phone.area_code.component}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={perosnalInfo.home_phone.home_phone.name}
                type={perosnalInfo.home_phone.home_phone.type}
                label={t(`register.labels.${perosnalInfo.home_phone.home_phone.name}`)}
                component={perosnalInfo.home_phone.home_phone.component}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* select fields */}
        {perosnalInfo.selectFields.map((fieldData, index) => {
          return (
            <Grid item xs={12} sm={6} lg={4} key={index}>
              <Field
                name={fieldData.name}
                label={t(`register.labels.${fieldData.name}`)}
                required
                val={{ label: form.register?.values[fieldData.name] || "" }}
                options={fieldData.options && fieldData.options}
                component={fieldData.component}
              >
                {fieldData.items &&
                  fieldData.items.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {i18n.language === "en" ? item.label : item.arabic_label}
                    </MenuItem>
                  ))}
              </Field>
            </Grid>
          );
        })}

        {
          workState && <Grid item xs={10} sm={6} lg={4}>
            <Field
              name={perosnalInfo.employer.name}
              type={perosnalInfo.employertype}
              label={t(
                `register.labels.${perosnalInfo.employer.name}`
              )}
              required
              component={perosnalInfo.employer.component}
            >
              {perosnalInfo.employer.items.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {i18n.language === "en" ? item.label : item.arabic_label}
                  </MenuItem>
                );
              })}
            </Field>
            
          </Grid>
           
        }
        {workState &&
        (
          <>
          {educationInfo.previous_masters.e.map((fieldData, index) => {
          return (
            <Grid item xs={12} sm={6} lg={4} key={index}>
              <Field
                name={fieldData.name}
                label={t(`register.labels.${fieldData.name}`)}
                required
                val={{ label: form.register?.values[fieldData.name] || "" }}
                options={fieldData.options && fieldData.options}
                component={fieldData.component}
              >
                {fieldData.items &&
                  fieldData.items.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {i18n.language === "en" ? item.label : item.arabic_label}
                    </MenuItem>
                  ))}
              </Field>
            </Grid>
          );
        })}
          </>
        )
              
             }
        {/* {workState && (renderFields(educationInfo.previous_masters.e))} */}

        {(workState && form.register?.values.degree === "UG") && <Typography variant="subtitle1" style={{ display: "block", width: "100%", color: "red" }}>{t('register.labels.workPermit')}</Typography>}

        <Grid item xs={10} sm={6} lg={4}>
          <Field
            onChange={(e, val) => sethealthCondition(val === "dis" && true)}
            name={perosnalInfo.variableFields.health.q.name}
            type={perosnalInfo.variableFields.health.q.type}
            label={t(
              `register.labels.${perosnalInfo.variableFields.health.q.name}`
            )}
            required
            component={perosnalInfo.variableFields.health.q.component}
          >
            {perosnalInfo.variableFields.health.q.items.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {i18n.language === "en" ? item.label : item.arabic_label}
                </MenuItem>
              );
            })}
          </Field>
        </Grid>

        {healthCondition && (
          <Grid item xs={12} sm={6}>
            <Field
              name={perosnalInfo.variableFields.health.a.name}
              type={perosnalInfo.variableFields.health.a.type}
              label={t(
                `register.labels.${perosnalInfo.variableFields.health.a.name}`
              )}
              required
              component={perosnalInfo.variableFields.health.a.component}
            >
              {perosnalInfo.variableFields.health.a.items.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {i18n.language === "en" ? item.label : item.arabic_label}
                  </MenuItem>
                );
              })}
            </Field>
          </Grid>
        )}

        {/* Address */}
        <Grid item xs={12}>
          <Typography align="center" variant="h6">{t("register.titles.address")}</Typography>
          <Grid container justify="center" spacing={2}>
            {perosnalInfo.address.select.map((fieldData, index) => {
              return (
                <Grid item xs={12} sm={4} key={index}>
                  <Field
                    name={fieldData.name}
                    label={t(`register.labels.${fieldData.name}`)}
                    val={{ label: form.register?.values[fieldData.name] || "" }}
                    options={fieldData.options && fieldData.options}
                    component={fieldData.component}
                  >
                    {fieldData.items &&
                      fieldData.items.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {i18n.language === "en"
                            ? item.label
                            : item.arabic_label}
                        </MenuItem>
                      ))}
                  </Field> 
                </Grid>
              );
            })}

            {perosnalInfo.address.text.map((fieldData, index) => {
              return (
                <Grid item xs={10} sm={4} key={index}>
                  <Field
                    name={fieldData.name}
                    type={fieldData.type}
                    label={t(`register.labels.${fieldData.name}`)}
                    component={fieldData.component}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <button
          type="submit"
          ref={submitRef}
          style={{ display: "none" }}
        ></button>
      </Grid>
    </form>
  );
};

const mapStateToProps = ({ form }) => ({ form });

export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
  validate,
  asyncValidate: asyncValidate.asyncEmailValidate,
  asyncBlurFields: ['email']
})(connect(mapStateToProps,{change})(PerosnalInfo));

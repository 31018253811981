import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Typography, Slide, TextField } from "@material-ui/core";
import {  change as changeFieldValue } from "redux-form";
import MuiUploadField from "../../../../common/components/muiUploadField/MuiUploadField";
import { useStyles } from "../../../../app/applicant/components/uploadForm/uploadFormstyles";
import { useDispatch } from "react-redux";

const EngDocumentState = ({ add, remove, addScore, removeScore ,form}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [engState, setEngState] = useState(form.register.values?form.register.values?.engState:"");
  const dispatch = useDispatch();
  const [testtype, setEngTestType] = useState();
  const [englishTestOption, setEnglishTestOption] = useState();

  const handleChange = (e) => {
    let value = e.target.value;
    dispatch(changeFieldValue("register", "engState", value))
    if (value === ("close" || "")) {
      setEngState(value);
      remove("english_certf");
      removeScore();
    } else setEngState(value);
  };
  const handleChangeEnglishType = (e) => {
    let value = +e.target.value;
    if(value === 1 ) setEngTestType("academic_ielts");
    else {
      if(value === 2) setEngTestType ("step");
      else if(value === 3) setEngTestType("tofel");
    }
    setEnglishTestOption(value);
  }

  let registerValues = form.register?.values;

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        {t("applicant.uploadForm.titles.eng")}
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-eng-native-simple">{t("applicant.uploadForm.inputs.labels.select")}</InputLabel>
        <Select
          native
          required
          value={engState}
          onChange={handleChange}
          label={t("applicant.uploadForm.inputs.labels.select")}
          inputProps={{name: "eng", id: "outlined-eng-native-simple"}}
        >
          <option value="" />
          <option value="open">{t("applicant.uploadForm.inputs.options.yes")}</option>
          <option value="close">{t("applicant.uploadForm.inputs.options.no")}</option>
        </Select>
      </FormControl>
      {engState === "open" ? (
        <Slide in direction="right">
          <div>
            <Typography variant="subtitle1" gutterBottom>Please select the english test type</Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-eng-native-simple">{t("applicant.uploadForm.inputs.labels.select")}</InputLabel>
              <Select
                  native
                  required
                  value={englishTestOption}
                  onChange={handleChangeEnglishType}
                  label={t("applicant.uploadForm.inputs.labels.select")}
                  inputProps={{name: "eng", id: "outlined-eng-native-simple"}}
              >
                <option value="" />
                <option value="1">{t("applicant.uploadForm.inputs.options.academic_ielts")}</option>
                <option value="2">{t("applicant.uploadForm.inputs.options.step")}</option>
                <option value="3">{t("applicant.uploadForm.inputs.options.tofel")}</option>
              </Select>
            </FormControl>
            <MuiUploadField
              required
              add={add}
              remove={remove}
              name={testtype}
              label={t("applicant.uploadForm.inputs.labels.certf")}
              description={t("applicant.uploadForm.inputs.tooltips.certf")}
              file={registerValues?.english_certf}
            />
            <TextField
              required
              label={t("applicant.uploadForm.inputs.labels.engScore")}
              type="number"
              inputProps={{step: 0.00001}}
              variant="outlined"
              onChange={addScore}
              value={registerValues?.english_certf_score}
            />
          </div>
        </Slide>
      ) : null}
    </>
  );
};

export default EngDocumentState;

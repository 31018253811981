import MuiTextField from "../components/muiTextField/MuiTextField";
import MuiEmailField from "../components/muiTextField/MuiEmailField";
import MuiPhoneField from "../components/muiTextField/MuiPhoneField";
import MuiSelectField from "../components/muiSelectField/MuiSelectField";
import MuiAutoComplete from "../components/muiAutoComplete/MuiAutoComplete";
import {
  nationality,
  genderItems,
  healthConditionItems,
  jobConditionItems,
  schoolType,
  ConditionTypesItems,
  countries,
  certified,
  gpaSystem,
  relation,
  survey,
  schools,
  cities,
  universities,
  stateUniversity,
  tagsirMajor,
  jobSectionItems,
  martialItems,
  zones,
  spokenLanguage,
  applicantEducationStateOptions,
  degree,
  qualifications_degree,
  employeeApprover, secondaryRegionTypes
} from "./selecteLists";
import DobTextField from "../components/muiTextField/DobTextField";

// import FieldValidate from './validate'
// import ParentInfo from '../../auth/components/register/steps/ParentInfo';

export const perosnalInfo = {
  courseType:
    {
      name: "degree",
      label:"ﺩَﺭَﺟَﺔ",
      type: "select",
      items: degree,
      required: true,
      component: MuiSelectField,
    },

  nameFileds: [
    {
      name: "arabic_first_name",
      label: "الاسم الأول",
      type: "text",
      component: MuiTextField,
    },
    {
      name: "arabic_middle_name",
      label: "اسم الأب",
      type: "text",
      component: MuiTextField,
    },
    {
      name: "arabic_last_name",
      label: "اسم الجد",
      type: "text",
      component: MuiTextField,
    },
    {
      name: "arabic_family_name",
      label: "اسم العائلة",
      type: "text",
      component: MuiTextField,
    },
    {
      name: "first_name",
      label: "First Name",
      type: "text",
      component: MuiTextField,
    },
    {
      name: "middle_name",
      label: "Father Name",
      type: "text",
      component: MuiTextField,
    },
    {
      name: "last_name",
      label: "Grand Father Name",
      type: "text",
      component: MuiTextField,
    },
    {
      name: "family_name",
      label: "Family Name",
      type: "text",
      component: MuiTextField,
    },
  ],
  generalFields: [
   
    {
      name: "email",
      label: "البريد الالكتروني",
      required: true,
      type: "email",
      component: MuiEmailField,
    },
    {
      name: "verify_email",
      label: "البريد الالكتروني",
      required: true,
      type: "verify_email",
      component: MuiEmailField,
    },
    {
      name: "birth_date",
      required: true,
      label: "تاريخ الميلاد",
      type: "date",
      component: DobTextField,
    },
    
  ],
  martial: {
    name: "marital_status",
    label: "الحالة الاجتماعية",
    type: "select",
    items: martialItems,
    required: true,
    component: MuiSelectField,
  },
  home_phone: {
    home_phone: {
      name: "home_phone",
      required: false,
      type: "number",
      component: MuiTextField,
    },
    area_code: {
      name: "area_code",
      required: false,
      type: "number",
      component: MuiTextField,
    },
  },
  variableFields: {
    // question: {
    //     q: { name: "question", label: "سؤال التحقق", type: "select", items: questionItems, component: MuiSelectField},
    //     a: { name: "answer", label: "الاجابة", type: "text", component: MuiTextField }
    // },
    health: {
      q: {
        name: "health_state",
        label: "الحالة الصحية",
        type: "select",
        items: healthConditionItems,
        component: MuiSelectField,
      },
      a: {
        name: "health_type",
        label: "نوع الاعاقة",
        type: "select",
        items: ConditionTypesItems,
        component: MuiSelectField,
      },
    },
  },
  spokenLanguage: {
    name: "arabic_speaker",
    label: "هل انت ناطق للغة العربية ",
    type: "select",
    items: spokenLanguage,
    required: true,
    component: MuiSelectField,
  },
  selectFields: [
    {
      name: "birth_place",
      label: "مكان الميلاد",
      type: "select",
      options: countries,
      component: MuiAutoComplete,
    },
    {
      name: "gender",
      label: "الجنس",
      type: "select",
      items: genderItems,
      component: MuiSelectField,
    },
    // {
    //   name: "nationality",
    //   label: "الجنسية",
    //   type: "select",
    //   options: nationality,
    //   component: MuiAutoComplete,
    // },
    {
      name: "mother_nationality",
      label: "جنسية الأم",
      type: "select",
      options: nationality,
      component: MuiAutoComplete,
    },
    {
      name: "employment_state",
      label: "الحالة الوظيفية",
      type: "select",
      items: jobConditionItems,
      component: MuiSelectField,
    },
    /*{
      name: "apply_semester",
      label: "الفصل الدراسي",
      type: "select",
      items: defaultItems,
      component: MuiSelectField,
    },*/
  ],
  employer: {
    name: "employer",
    type: "select",
    items: jobSectionItems,
    component: MuiSelectField,
  },
  address: {
    select: [
      {
        name: "city",
        label: "المدينة",
        type: "select",
        options: cities,
        component: MuiAutoComplete,
      },
    ],
    // select: [],
    text: [
      {
        name: "building_no",
        label: "رقم المبني",
        type: "number",
        component: MuiTextField,
      },
      {
        name: "street_no",
        label: "رقم الشارع",
        type: "number",
        component: MuiTextField,
      },
      {
        name: "district",
        label: "الحي",
        type: "text",
        component: MuiTextField,
      },
      {
        name: "postal_code",
        label: "الرمز البريدي",
        type: "number",
        component: MuiTextField,
      },
      {
        name: "extra_code",
        label: "الرقم الاضافي",
        type: "number",
        component: MuiTextField,
      },
    ],
  },
};

export const basicInfo = {
  id: {
    name: "national_id",
    label: "رقم الهوية الوطنية / الاقامة",
    type: "number",
    component: MuiEmailField,
  },
  phone: {
    name: "phone",
    label: "الجوال",
    type: "number",
    component: MuiPhoneField,
  },
  confirm: {
    name: "phone_code",
    label: "رمز التأكيد",
    type: "number",
    component: MuiTextField,
  },
  nationality: {
    name: "nationality",
    label: "الجنسية",
    type: "select",
    options: nationality,
    component: MuiAutoComplete,
  },
};

export const educationInfo = {
  applicantEducationState: {
    name: "applicantEducationState",
    label: "هل انت طالب/ة مستجد / محول / تجسير ",
    type: "select",
    items: applicantEducationStateOptions,
    component: MuiSelectField,
  },
  secondary_region: {
    name: "secondary_region",
    label: "نوع شهادة الثانوية",
    type: "select",
    items: secondaryRegionTypes,
    component: MuiSelectField,
  },
  hs_country: {
    name: "certificate_country",
    label: "دولة الشهادة الثانوية",
    type: "select",
    options: countries,
    component: MuiAutoComplete,
  },
  hs_city: {
    name: "high_school_city",
    type: "select",
    options: cities,
    component: MuiAutoComplete,
  },
  hs_zone: {
    name: "education_area",
    type: "select",
    options: zones,
    component: MuiAutoComplete,
  },
  school: {
    name: "high_school_name",
    label: "اسم المدرسة",
    options: schools,
    type: "select",
    component: MuiAutoComplete,
  },
  text: [
    {
      name: "high_school_GPA",
      label: "معدل الثانوية العامة",
      type: "number",
      component: MuiTextField,
    },
    {
      name: "high_school_year",
      label: "سنة الثانوية العامة",
      type: "text",
      component: MuiTextField,
    },
    {
      name: "secondary_type",
      label: "نوع الثانوية",
      type: "select",
      items: schoolType,
      component: MuiSelectField,
    }
  ],
  test: {
    qt: [
      {
        name: "qiyas_aptitude",
        label: "درجة اختبار القدرات",
        type: "number",
        component: MuiTextField,
      },
      {
        name: "qiyas_achievement",
        label: "درجة اختبار التحصيلي",
        type: "number",
        component: MuiTextField,
      },
    ],
    sat: [
      {
        name: "sat_score",
        label: "درجة اختبار التحصيلي",
        type: "number",
        component: MuiTextField,
      }
    ],
    act_score: [
      {
        name: "act_score",
        label: "Act Score",
        type: "number",
        component: MuiTextField,
      }
    ],
  },
  // english: {
  //     q: { name: "certified", label: "هل لديك اختبار لغة", type: "select", items: certified, component: MuiSelectField},
  //     a: [
  //         { name: "eng_type", label: "نوع الاختبار", type: "select", items: englishTests, component: MuiSelectField},
  //         { name: "eng_date", label: "تاريخ الاختبار", type: "date", component: MuiTextField },
  //         { name: "eng_grade", label: "درجة الاختبار", type: "number", component: MuiTextField },
  //     ]
  // },
  previous: {
    q: {
      name: "previous",
      label: "هل سبق و التحقت بجامعة/كلية/معهد",
      type: "select",
      items: certified,
      component: MuiSelectField,
    },
    a: [
      {
        name: "previous_university",
        label: "اسم الجامعة/الكلية/المعهد",
        options: universities,
        type: "select",
        component: MuiAutoComplete,
      },
      {
        name: "max_gpa",
        label: "نظام المعدل",
        type: "select",
        items: gpaSystem,
        component: MuiSelectField,
      },
      {
        name: "previous_GPA",
        label: "المعدل",
        type: "number",
        component: MuiTextField,
      },
      {
        name: "state_university",
        label: "الحالة",
        type: "select",
        items: stateUniversity,
        component: MuiSelectField,
      },
    ],
  },
  previous_masters: {
    q: {
      name: "previous",
      label: "هل سبق و التحقت بجامعة/كلية/معهد",
      type: "select",
      items: certified,
      component: MuiSelectField,
    },
    a: [
      {
        name: "previous_university",
        label: "اسم الجامعة/الكلية/المعهد",
        options: universities,
        type: "select",
        component: MuiAutoComplete,
      },
      {
        name: "max_gpa",
        label: "نظام المعدل",
        type: "select",
        items: gpaSystem,
        component: MuiSelectField,
      },
      {
        name: "previous_GPA",
        label: "المعدل",
        type: "number",
        component: MuiTextField,
      },
    ],
    e:[
      {
        name: "employer_approved",
        label: "موافقة الموظف",
        type: "select",
        items: employeeApprover,
        component: MuiSelectField,
      },
      {
        name: "employer_name",
        label: "اسم الموظف",
        type: "text",
        component: MuiTextField,
      },
    ]
  },
  tagsir: {
    q: {
      name: "tagsir",
      type: "select",
      items: certified,
      component: MuiSelectField,
    },
    a: [
      {
        name: "tagseer_institute",
        label: "اسم الجامعة/الكلية/المعهد",
        options: universities,
        type: "select",
        component: MuiAutoComplete,
      },
      {
        name: "tagseer_country",
        label: "اسم الجامعة/الكلية/المعهد",
        options: countries,
        type: "select",
        component: MuiAutoComplete,
      },
      {
        name: "tagseer_department",
        type: "select",
        items: tagsirMajor,
        component: MuiSelectField,
      },
      {
        name: "tagseer_max_gpa",
        label: "نظام المعدل",
        type: "select",
        items: gpaSystem,
        component: MuiSelectField,
      },
      {
        name: "tagseer_GPA",
        type: "number",
        component: MuiTextField,
      },
     
      {
        name: "tagseer_year",
        type: "text",
        component: MuiTextField,
      },
      {
        name: "tagseer_number",
        type: "text",
        component: MuiTextField,
      },
      {
        name: "tagseer_date",
        type: "date",
        component: MuiTextField,
      },
    ],
  },
};

export const parentInfo = {
  fileds: [
    {
      name: "superior_name",
      label: "الاسم",
      required: true,
      type: "text",
      component: MuiTextField,
    },
    {
      name: "superior_nationalID",
      label: "رقم الهوية الوطنية / الاقامة",
      required: true,
      type: "number",
      component: MuiTextField,
    },
    {
      name: "superior_phone",
      label: "الجوال",
      placeholder: "phone_number",
      required: true,
      type: "number",
      component: MuiPhoneField,
    },
    {
      name: "superior_relation",
      label: "درجة القرابة",
      required: true,
      type: "select",
      items: relation,
      component: MuiSelectField,
    },
    {
      name: "superior_qualification",
      label: "المؤهل العلمي",
      required: true,
      type: "select",
      items: qualifications_degree,
      component: MuiSelectField,
    },
  ],
  
  emergency: [
    {
      name: "emergency_name",
      label: "الاسم",
      required: true,
      type: "text",
      component: MuiTextField,
    },
    {
      name: "emergency_nationalID",
      label: "رقم الهوية الوطنية / الاقامة",
      type: "number",
      required: true,
      component: MuiTextField,
    },
    {
      name: "emergency_phone",
      placeholder: "phone_number",
      label: "الجوال",
      type: "number",
      required: true,
      component: MuiPhoneField,
    },
    {
      name: "emergency_relation",
      label: "درجة القرابة",
      required: true,
      type: "select",
      items: relation,
      component: MuiSelectField,
    },
    {
      name: "emergency_qualification",
      label: "المؤهل العلمي", 
      required: true,
      type: "select",
      items: qualifications_degree,
      component: MuiSelectField,
    },
  ],
};

// export const parentInfoMasters = {
//   fileds: [
//     {
//       name: "superior_name",
//       label: "الاسم",
//       // required: true,
//       type: "text",
//       component: MuiTextField,
//     },
//     {
//       name: "superior_nationalID",
//       label: "رقم الهوية الوطنية / الاقامة",
//       // required: true,
//       type: "number",
//       component: MuiTextField,
//     },
//     {
//       name: "superior_phone",
//       label: "الجوال",
//       placeholder: "phone_number",
//       // required: true,
//       type: "number",
//       component: MuiPhoneField,
//     },
//     {
//       name: "superior_relation",
//       label: "درجة القرابة",
//       // required: true,
//       type: "select",
//       items: relation,
//       component: MuiSelectField,
//     },
//     {
//       name: "superior_qualification",
//       label: "المؤهل العلمي",
//       // required: true,
//       type: "select",
//       items: qualifications_degree,
//       component: MuiSelectField,
//     },
//   ],
//   emergency: [
//     {
//       name: "emergency_name",
//       label: "الاسم",
//       type: "text",
//       // required: false,
//       component: MuiTextField,
//     },
//     {
//       name: "emergency_nationalID",
//       label: "رقم الهوية الوطنية / الاقامة",
//       type: "number",
//       // required: false,
//       component: MuiTextField,
//     },
//     {
//       name: "emergency_phone",
//       placeholder: "phone_number",
//       label: "الجوال",
//       type: "number",
//       // required: false,
//       component: MuiPhoneField,
//     },
//     {
//       name: "emergency_relation",
//       label: "درجة القرابة",
//       type: "select",
//       items: relation,
//       // required: false,
//       component: MuiSelectField,
//     },
//     {
//       name: "emergency_qualification",
//       label: "المؤهل العلمي",   
//       // required: true,
//       type: "select",
//       items: qualifications_degree,
//       component: MuiSelectField,
//     },
//   ],
// };

export const surveyInfo = [
  {
    name: "first_questionare",
    label: "كانت شروط و ضوابط القبول عادلة و واضحة",
    type: "select",
    items: survey,
    component: MuiSelectField,
  },
  {
    name: "second_questionare",
    label: "كانت خطوات التقديم للقبول سهلة و سلسة",
    type: "select",
    items: survey,
    component: MuiSelectField,
  },
];

import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import {  reduxForm, change as changeFieldValue } from "redux-form";
import { useStyles } from "../../../../app/applicant/components/uploadForm/uploadFormstyles";
import MuiUploadField from "../../../../common/components/muiUploadField/MuiUploadField";
import EngDocumentState from "./EngDocumentState";

const TransferDocuments = ({ submits,form, next }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const onSubmit = (e) => {
        e.preventDefault();
        // add ther status in formdata objet with files
        // after passing all rquired fields upload formdata file
        dispatch(changeFieldValue("register", "applicant_type", "FS"))
        next()
    };

    const addFile = (name, file) => {
        //   add to the form data object
        dispatch(changeFieldValue("register", name, file));
    };

    const removeFile = (name) => {
        dispatch(changeFieldValue("register", name, undefined));
    }

    const addScore = (e) => {
        dispatch(changeFieldValue("register", "english_certf_score", e.target.value));
    }

    const removeScore = () => {
        dispatch(changeFieldValue("register", "english_certf_score",undefined));
    }

    let registerValues = form.register?.values;

    return (
        <div className={classes.form}>
            <form onSubmit={onSubmit} className={classes.formInner}>
                <MuiUploadField
                    add={addFile}
                    remove={removeFile}
                    required
                    name="national_file"
                    label={t("applicant.uploadForm.inputs.labels.ssn")}
                    description={t("applicant.uploadForm.inputs.tooltips.ssn")}
                    file={registerValues?.national_file}
                />
                <MuiUploadField
                    required
                    name="high_school"
                    add={addFile}
                    remove={removeFile}
                    label={t("applicant.uploadForm.inputs.labels.school")}
                    description={t("applicant.uploadForm.inputs.tooltips.school")}
                    file={registerValues?.high_school}
                />
                <MuiUploadField
                    required
                    name="transcript"
                    add={addFile}
                    remove={removeFile}
                    label={t("applicant.uploadForm.inputs.labels.transcript")}
                    description={t("applicant.uploadForm.inputs.tooltips.transcript")}
                    file={registerValues?.transcript}
                />
                {Boolean(registerValues?.tagseer_department) && (
                    <MuiUploadField
                        // required
                        add={addFile}
                        remove={removeFile}
                        name="diploma"
                        label={t("applicant.uploadForm.inputs.labels.diploma")}
                        description={t("applicant.uploadForm.inputs.tooltips.diploma")}
                        file={registerValues?.diploma}
                    />
                )}
                {Boolean(registerValues?.tagseer_department) && (
                    <MuiUploadField
                        // required
                        name="SCFHS"
                        add={addFile}
                        remove={removeFile}
                        label={t("applicant.uploadForm.inputs.labels.SCFHS")}
                        description={t("applicant.uploadForm.inputs.tooltips.SCFHS")}
                        file={registerValues?.SCFHS}
                    />
                )}
                <MuiUploadField
                    name="courses"
                    add={addFile}
                    remove={removeFile}
                    label={t("applicant.uploadForm.inputs.labels.courses")}
                    description={t("applicant.uploadForm.inputs.tooltips.courses")}
                    file={registerValues?.courses}
                />
                {registerValues?.employment_state === "work" && (
                    <MuiUploadField
                        // required
                        add={addFile}
                        remove={removeFile}
                        name="employee_card"
                        label={t("applicant.uploadForm.inputs.labels.employee_card")}
                        description={t("applicant.uploadForm.inputs.tooltips.employee_card")}
                        file={registerValues?.employee_card}
                    />
                )}
                <MuiUploadField
                    required
                    add={addFile}
                    remove={removeFile}
                    name="photo"
                    label={t("applicant.uploadForm.inputs.labels.photo")}
                    description={t("applicant.uploadForm.inputs.tooltips.photo")}
                    file={registerValues?.photo}
                />
                {(registerValues?.scoreType === 1) && (
                    <>
                        <MuiUploadField
                            add={addFile}
                            remove={removeFile}
                            name="quadrant_file"
                            label={"Quadrat File"}
                            description={t('applicant.uploadForm.inputs.tooltips.qudrantFileUpload')}
                            file={registerValues?.quadrant_file}
                        />
                        <MuiUploadField
                            add={addFile}
                            remove={removeFile}
                            // required
                            name="tahisli_file"
                            label={"Tahsili File"}
                            description={t('applicant.uploadForm.inputs.tooltips.tahsiliFileUpload')}
                            file={registerValues?.tahisli_file}
                        />
                    </>
                )}
                {registerValues?.scoreType === 2 && (
                    <MuiUploadField
                        add={addFile}
                        remove={removeFile}
                        name="SAT"
                        label="SAT"
                        // label={t("applicant.uploadForm.inputs.labels.score")}
                        description={t("applicant.uploadForm.inputs.tooltips.score")}
                        file={registerValues?.SAT}
                    />
                )}
                <EngDocumentState add={addFile} remove={removeFile} addScore={addScore} removeScore={removeScore} form={form} />
                <button type="submit" ref={submits} className={classes.sub}></button>
            </form>
        </div>
    );
};

const mapStateToProps = ({ form }) => ({ form });

export default reduxForm({
    form: "register",
    destroyOnUnmount: false,
})(connect(mapStateToProps)(TransferDocuments));
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { reduxForm, change as changeFieldValue } from "redux-form";
import { useStyles } from "../../../../app/applicant/components/uploadForm/uploadFormstyles";
import MuiUploadField from "../../../../common/components/muiUploadField/MuiUploadField";
import EngDocumentState from "./EngDocumentState";

const MastersDocuments = ({ submits, form, next, selectedPrios }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    // add ther status in formdata objet with files
    // after passing all rquired fields upload formdata file
    dispatch(changeFieldValue("register", "applicant_type", "FS"))
    next()
  };

  const addFile = (name, file) => {
    //   add to the form data object
    dispatch(changeFieldValue("register", name, file))
  };

  const removeFile = (name) => {
    dispatch(changeFieldValue("register", name, undefined))
  }

  const addScore = (e) => {
    dispatch(changeFieldValue("register", "english_certf_score", e.target.value))
  }

  const removeScore = () => {
    dispatch(changeFieldValue("register", "english_certf_score", undefined))
  }

  let registerValues = form.register?.values;
  let isFemale = registerValues?.gender === "F";

  return (
      <div className={classes.form}>
        <form onSubmit={onSubmit} className={classes.formInner}>
          {selectedPrios.first_periority === 13 && (
              <div style={{ color: 'red' }}>
                {isFemale ? t("applicant.uploadForm.inputs.labels.master_16_f") : t("applicant.uploadForm.inputs.labels.master_16_m")}
              </div>
          )}
          {selectedPrios.first_periority === 14 && (
              <div style={{ color: 'red' }}>
                {isFemale ? t("applicant.uploadForm.inputs.labels.master_17_f") : t("applicant.uploadForm.inputs.labels.master_17_m")}
              </div>
          )}
          {selectedPrios.first_periority === 15 && (
              <div style={{ color: 'red' }}>
                {t("applicant.uploadForm.inputs.labels.master_18")}
              </div>
          )}
          <MuiUploadField
              add={addFile}
              remove={removeFile}
              required
              name="national_file"
              label={t("applicant.uploadForm.inputs.labels.ssn")}
              description={t("applicant.uploadForm.inputs.tooltips.ssn")}
              file={registerValues?.national_file}
          />
          <MuiUploadField
              required
              name="bachelor_degree_certificate"
              add={addFile}
              remove={removeFile}
              label={t("applicant.uploadForm.inputs.labels.bachelor_degree_certificate")}
              description={t("applicant.uploadForm.inputs.tooltips.bachelor_degree_certificate")}
              file={registerValues?.bachelor_degree_certificate}
          />
          {selectedPrios.first_periority !== 15 && (<MuiUploadField
              // required
              name="internship_completion_certificate"
              add={addFile}
              remove={removeFile}
              label={t("applicant.uploadForm.inputs.labels.internship_completion_certificate")}
              description={t("applicant.uploadForm.inputs.tooltips.internship_completion_certificate")}
              file={registerValues?.internship_completion_certificate}
          />)}
          <MuiUploadField
              required
              name="transcript"
              add={addFile}
              remove={removeFile}
              label={t("applicant.uploadForm.inputs.labels.transcript")}
              description={t("applicant.uploadForm.inputs.tooltips.transcript")}
              file={registerValues?.transcript}
          />
          {selectedPrios.first_periority !== 15 && (
              <MuiUploadField
                  // required
                  name="SCFHS"
                  add={addFile}
                  remove={removeFile}
                  label={t("applicant.uploadForm.inputs.labels.SCFHS")}
                  description={t("applicant.uploadForm.inputs.tooltips.SCFHS")}
                  file={registerValues?.SCFHS}
              />
          )}
          <MuiUploadField
              // required
              name="courses"
              add={addFile}
              remove={removeFile}
              label={t("applicant.uploadForm.inputs.labels.courses")}
              description={t("applicant.uploadForm.inputs.tooltips.courses")}
              file={registerValues?.courses}
          />
          {registerValues?.employment_state === "work" && (
              <MuiUploadField
                  // required
                  add={addFile}
                  remove={removeFile}
                  name="employee_card"
                  label={t("applicant.uploadForm.inputs.labels.employee_card")}
                  description={t("applicant.uploadForm.inputs.tooltips.employee_card")}
                  file={registerValues?.employee_card}
              />
          )}
          <MuiUploadField
              // required
              add={addFile}
              remove={removeFile}
              name="photo"
              label={t("applicant.uploadForm.inputs.labels.photo")}
              description={t("applicant.uploadForm.inputs.tooltips.photo")}
              file={registerValues?.photo}
          />
          <MuiUploadField
              required
              add={addFile}
              remove={removeFile}
              name="first_recommendation"
              label={t("applicant.uploadForm.inputs.labels.first_recommendation")}
              description={t("applicant.uploadForm.inputs.tooltips.first_recommendation")}
              file={registerValues?.first_recommendation}
          />
          <MuiUploadField
              // required
              add={addFile}
              remove={removeFile}
              name="second_recommendation"
              label={t("applicant.uploadForm.inputs.labels.second_recommendation")}
              description={t("applicant.uploadForm.inputs.tooltips.second_recommendation")}
              file={registerValues?.first_recommendation}
          />
          <MuiUploadField
              add={addFile}
              remove={removeFile}
              name="cv"
              label={t("applicant.uploadForm.inputs.labels.cv")}
              description={t("applicant.uploadForm.inputs.tooltips.cv")}
              file={registerValues?.cv}
          />
          <EngDocumentState add={addFile} remove={removeFile} addScore={addScore} removeScore={removeScore} form={form} />
          <button type="submit" ref={submits} className={classes.sub}></button>
        </form>
      </div>
  );
};

const mapStateToProps = ({ form }) => ({ form });

export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
})(connect(mapStateToProps)(MastersDocuments));